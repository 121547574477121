import instance from "../interceptor/AxiosInstance";
import { headers } from "../utils/headers";

export const makePaymentRequest = async (body) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/payment-create`,
        {
            method: 'POST',
            headers: headers,
            data: JSON.stringify(body)
        }
    );
    return response;
}

export const makePaymentRequestForSeatsIO = async (body) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/payment-create/seatsio`,
        {
            method: 'POST',
            headers: headers,
            data: JSON.stringify(body)
        }
    );
    return response;
}

export const reportSuccessPayment = async (body) => {
    const response = await instance(`${process.env.REACT_APP_BACKEND_API}/auth/payment-success`, {
        method: 'POST',
        headers: headers,
        data: JSON.stringify(body)
    });
    return response;
}

export const createFreeOrder = async (body) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/payment-create/free`, {
        method: 'POST',
        headers: headers,
        data: JSON.stringify(body)
    });
    return response;
}


export const createFreeSeatsIOOrder = async (body) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/payment-create/seatsio/free`, {
        method: 'POST',
        headers: headers,
        data: JSON.stringify(body)
    });
    return response;
}