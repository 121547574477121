export const ADD_ITEMS = "ADD_ITEMS";
export const REDUCE_ITEMS = "REDUCE_ITEMS";
export const ADD_ITEMS_CATEGORY = "ADD_ITEMS_CATEGORY";
export const REDUCE_ITEMS_CATEGORY = "REDUCE_ITEMS_CATEGORY";
export const SHOWS_STORE = "SHOWS_STORE";
export const PUSH_SHOWS = "PUSH_SHOWS";
export const PUSH_SHOW_BY_ID = "PUSH_SHOWS_BY_ID";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const CHANGE_CATEGORY_SEATSIO = "CHANGE_CATEGORY_SEATSIO";
export const CHANGE_NAVLINKS = "CHANGE_NAVLINKS";
export const DESELECT_CATEGORY_SEATSIO = "DESELECT_CATEGORY_SEATSIO";
export const RESET_SEATSIO_TICKETS = "RESET_SEATSIO_TICKETS";
export const INSERT_SEATSIO_OBJECT = "INSERT_SEATSIO_OBJECT";