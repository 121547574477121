import React from 'react'
import "./BackToTop.css"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
export const BackToTop = (props) => {
    return (
        <div className="back_to_top_div" style={{top:props.top}} onClick={()=>{window.scrollTo({top:0,left:0, behavior :'smooth'})}}>
            <button className="back_to_top_btn"><ArrowUpwardIcon style={{color: "white"}}/></button>
        </div>
    )
}


