import { TAXES } from "../constants/Tax";


export const fixedTaxWithPriceCalculator = (show, ticketPrice, quantity) => {
    let totalTax = 0;
    let ticketTax = 0;
    let orderTax = 0;
    show.extra_fees.forEach((fee) => {
        if (fee.type === TAXES.fixed && fee.category === TAXES.product) {
            ticketTax += parseFloat(fee.fees * quantity);

        }
        if (fee.type === TAXES.fixed && fee.category === TAXES.order) {
            orderTax = parseFloat(fee.fees);

        }
    });
    totalTax = Number(ticketTax) + Number(orderTax);
    const propObj = taxCalculator(show, ((ticketPrice * quantity) + totalTax), quantity, orderTax, ticketTax);
    return {
        totalTax: propObj.totalTax,
        ticketTax: propObj.ticketTax - (ticketPrice * quantity),
        totalPrice: propObj.ticketTax,
        orderTax: propObj.orderTax
    }
}


export const taxCalculator = (show, ticketPrice, quantity, orderTax, bonusAmount) => {
    let totalTax = 0;
    let ticketTax = 0;
    show.extra_fees.forEach((fee) => {
        if (fee.type === TAXES.percentage && fee.category === TAXES.product) {
            ticketTax += parseFloat((fee.fees + 100) * ticketPrice / 100);
        }
        if (fee.type === TAXES.percentage && fee.category === TAXES.order) {
            ticketTax += parseFloat((fee.fees + 100) * ticketPrice / 100);
        }
    });
    totalTax = Number(ticketTax) + Number(orderTax);

    return { totalTax: (totalTax), ticketTax: (ticketTax), orderTax };
}


export const subtotalPrice = (show) => {
    let cost = 0;
    show.purchased_tickets?.forEach((ticket) => {
        cost += ticket?.ticket_price_web * ticket?.quantity;
    })
    return cost;
}

export const setSpecialCategoriesTickets = (tickets) => {
    let arr = [];
    tickets.forEach(item => {
        const obj = {
            id: item.category,
            quantity: item.quantity
        }
        arr.push(obj);
    });
    return arr;
}

export const transformAndMergeSeatsIO = (tickets) => {
    let arr = [];
    tickets.forEach(item => {
        const exists = arr.find((obj) => obj.category === item?.category?.key);
        if (!exists) {
            const obj = {
                quantity: 1,
                name: item?.category?.label,
                category: Number(item?.category?.key),
                label: [item.seatId]
            };
            if (item.category.pricing !== null)
                obj.ticket_price_web = item?.category?.pricing?.price

            arr.push(obj);
        }
        else {
            exists.label = [...exists?.label, item?.seatId];
        }
    });

    return arr;
}

export const mergeSpecialCategoriesTickets = (tickets) => {
    let arr = [];
    tickets.forEach(item => {
        const exists = arr.find((obj) => obj.category === item.category);
        if (!exists) {
            arr.push({ ...item, label: [item.seatId] });
        }
        else {
            exists.label = [...exists?.label, item.seatId];
        }
    });
    return arr;
}

export const printSeats = (seats) => {
    let strTicket = "";
    const length = seats.length - 1;
    seats?.slice(0, length).forEach((str) => { strTicket = strTicket + `${str}, ` });
    strTicket = strTicket + `${seats[length]}`;
    return strTicket;
}

export function removeDuplicates(arr) {
    return Array.from(new Set(arr));
}