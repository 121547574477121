import React from "react";
import { BUTTON } from "../../constants/Buttons";
import { SPECIAL } from "../../constants/Events";
import "./Button.css";

export const OutOfStock = (props) => {
  return (
    <>
      <div
        className="ptp_navlink"
        style={{ textDecoration: "none", color: "orangered", textAlign: "left" }}>
        <button className="proceed_to_payment_button" disabled>
          {props.type === SPECIAL.free ? BUTTON.PlaceOrder : BUTTON.ProceedToPayment}
        </button>
      </div>
    </>
  );
};
