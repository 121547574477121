import React from 'react';
import Slider from 'react-slick';
import '../Partners/Partners.css';

const   Partners = ({logo1, logo2, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17}) => {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
		autoplaySpeed: 0,
		speed: 4000,
		cssEase: 'linear',
    };
    return (
        <>  
            <section className="partner-wrp">
                <Slider {...settings}>
                    <div className="partner-item" style={{width:"300px", height:"300px"}}>
                        <img src={logo1} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo2} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo4} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo5} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo6} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo7} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo8} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo9} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo10} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo11} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo12} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo13} alt='' className='img-fluid'/>
                    </div>
                    <div className="partner-item">
                        <img src={logo14} alt='' className='img-fluid' />
                    </div>                    
                    <div className="partner-item">
                        <img src={logo15} alt='' className='img-fluid' />
                    </div>
                    <div className="partner-item">
                        <img src={logo16} alt='' className='img-fluid' />
                    </div>
                    <div className="partner-item">
                        <img src={logo17} alt='' className='img-fluid' />
                    </div>
                </Slider>

            </section>
        </>
    )
}

export default Partners;