import React from "react";
import inactiveCalender from "./events-inactive.png";
import activeCalender from "./discover-active.png";
import inactivesearchPNG from "./search.png";
import activesearchPNG from "./search-active.png";
import inactiveProfile from "./profile-inactive.png";
import activeProfile from "./profile-active.png";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import ticketsInactive from "./mytickets-inactive.png";
import ticketsActive from "./mytickets-active.png";
import "./AppBar.css";
import { NavLink } from "react-router-dom";
import { LoginRounded } from "@mui/icons-material";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";

export const AppBar = (props) => {
  const [activeLink] = React.useState({
    home: true,
    search: false,
    tickets: false,
    login: false,
    savedCards: false
  });
  return (
    <div className="app_navbar">
      <NavLink to="/" style={{ textDecoration: "none" }} onClick={()=> props?.changeLinks("home")} exact>
        <div className="app_nav_tab"> 

          <img alt="nav" src={ activeCalender } style={{width: '30px' , height : '30px' , margin : 'auto' , display : props?.navbar ? props.navbar.home ? 'block' : 'none' :null }}/>

          <img alt="nav" src={inactiveCalender} style={{width: '30px' , height : '30px' , margin : 'auto', display : props?.navbar ? props.navbar.home ? 'none' : 'block' : null}}/>
          
          <p style={{color: props?.navbar ? props.navbar.home ? 'yellow': 'white' : activeLink.home ? 'yellow': 'white'}}>DISCOVER</p>
        </div>
      </NavLink>
      <NavLink to="/shows" style={{ textDecoration: "none" }} onClick={()=> props?.changeLinks("search")} exact>
        <div className="app_nav_tab">


          <img alt="nav" src={ activesearchPNG } style={{maxWidth: '24px' , maxHeight : '24px' , padding : '3px', margin : 'auto' , display : props?.navbar ? props.navbar.search ? 'block' : 'none' :null }}/>

          <img alt="nav" src={inactivesearchPNG} style={{width: '30px' , height : '30px' , margin : 'auto', display : props?.navbar ? props.navbar.search ? 'none' : 'block' : null}}/>


          <p style={{ color: props?.navbar ? props.navbar.search ? '#4596cd' : 'white' : activeLink.search ? '#4596cd' : 'white'}}>SEARCH</p>
        </div>
      </NavLink>
      <NavLink to="/tickets" style={{ textDecoration: "none" }} onClick={()=> props?.changeLinks("tickets")} exact>
        <div className="app_nav_tab">
          

          <img alt="nav" src={ ticketsActive } style={{width: '30px' , height : '30px' , margin : 'auto' , display : props?.navbar ? props.navbar.tickets ? 'block' : 'none' :null }}/>

          <img alt="nav" src={ticketsInactive} style={{width: '30px' , height : '30px' , margin : 'auto', display : props?.navbar ? props.navbar.tickets ? 'none' : 'block' : null}}/>

          <p style={{ color: props?.navbar ? props.navbar.tickets ? '#9740c1' : 'white' : activeLink.tickets ? '#9740c1' : 'white'}}>MY TICKETS</p>
        </div>
      </NavLink>
      {!window.localStorage.getItem(LOCALSTORAGE_ENUMS.token) ? 
         <NavLink
          to="/login"
          className="navlinks"
          style={{ textDecoration: "none" }}
          onClick={()=> props.changeLinks("login")}
          exact
        >
          <div className="app_nav_tab">

          <img alt="nav" src={ activeProfile } style={{width: '30px' , height : '30px' , margin : 'auto' , display : props?.navbar ? props.navbar.login ? 'block' : 'none' :null }}/>

          <img alt="nav" src={inactiveProfile} style={{width: '30px' , height : '30px' , margin : 'auto', display : props?.navbar ? props.navbar.login ? 'none' : 'block' : null}}/>

            <p style={{ color: props?.navbar ? props.navbar.login ? '#f03f43' : 'white' : activeLink.login ? '#f03f43' : 'white'}}>PROFILE</p>
          </div>
        </NavLink> 
        :
         window.localStorage.getItem(LOCALSTORAGE_ENUMS.guestMode) === "true" ? 
          <NavLink
          to="/login"
          className="navlinks"
          style={{ textDecoration: "none" }}
          exact
        >
          <div className="app_nav_tab">
            <LoginRounded style={{ margin: "auto" }} />
            <p>Login</p>
          </div>
        </NavLink>  :
        <>
          <NavLink
            to="/myprofile" 
            className="navlinks"
            style={{ textDecoration: "none" }}
            onClick={()=> props.changeLinks("profile")}
            exact
          >
            <div className="app_nav_tab">

          <img alt="nav" src={ activeProfile } style={{width: '30px' , height : '30px' , margin : 'auto' , display : props?.navbar ? props.navbar.profile ? 'block' : 'none' :null }}/>

          <img alt="nav" src={inactiveProfile} style={{width: '30px' , height : '30px' , margin : 'auto', display : props?.navbar ? props.navbar.profile ? 'none' : 'block' : null}}/>
            <p style={{ color: props?.navbar ? props.navbar.profile ? '#f03f43' : 'white' : activeLink.login ? '#f03f43' : 'white'}}>PROFILE</p>
            </div>
          </NavLink>
          </>
      }
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    navbar: state.mobileNavbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink }),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(AppBar);