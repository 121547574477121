import React, { useEffect, useState } from "react";
import BuyControllers from "../../components/BuyController/BuyControllers";
import "./ShowPage.css";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { Redirect, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { Chip, CircularProgress } from "@mui/material";
import dummyImg from "../../mtt-icon.png"
import { SPECIAL } from "../../constants/Events";
import { fetchEventById } from "../../services/Events.services";

export const ShowPage = (props) => {
  const { id } = useParams();
  const history = useHistory()
  const [loader, setLoader] = useState(true);
  const reloadRedux = () => {
    <Redirect to="/shows" />;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showDescription, setShowDescription] = useState({
    event: true,
    artist: false,
  });
  let totalTax = 0;
  let taxinString = "";
  if (props?.show && props?.show?.extra_fees?.length > 0)
    props.show.extra_fees.forEach((fee, index) => {
      if (fee.type === "fixed" && fee.category === "product") {
        totalTax =
          totalTax + parseFloat(fee.fees * props.show.purchased_tickets);
        taxinString =
          taxinString +
          " + $" +
          fee.fees * props.show.purchased_tickets +
          " " +
          fee.name;
      }
      if (fee.type === "fixed" && fee.category === "order") {
        totalTax = totalTax + parseFloat(fee.fees);
        taxinString = taxinString + " + $" + fee.fees + " " + fee.name;
      }
      if (fee.type === "percentage" && fee.category === "product") {
        totalTax =
          totalTax +
          parseFloat(
            (fee.fees *
              props.show.purchased_tickets *
              props.show.ticket_price_web) /
            100
          );
        taxinString =
          taxinString +
          " + $" +
          (fee.fees *
            props.show.purchased_tickets *
            props.show.ticket_price_web) /
          100 +
          " " +
          fee.name;
      }
    });

    // useEffect(() => {
    //   const showsList = async () => {
    //     try {
    //       setLoader(true);
    //       const response = await fetchEventLists();
    //       const data = await response.data;
    //       const shows = data.data;
    //       // setTimeout(() => {
    //       //   setLoader(false);
    //       // }, 500);
    //       if (response.status === 200) {
    //         props.pushShows(shows);
    //         if(shows.find(show => show.product_id === Number(id)) === undefined)
    //           history.replace("/shows");
    //       }
    //     } catch (err) {
    //       console.log(err);
    //       history.replace("/shows");
    //       // setLoader(false);
    //     }
    //   };
    //   if(props.shows.find(show => show.product_id === Number(id)) === undefined)
    //   showsList();
    //   // else
    //   //   setLoader(false);
    // // eslint-disable-next-line
    // }, []);

  useEffect(() => {
    const showById = async () => {
      try {
        setLoader(true);
        const response = await fetchEventById(id);
        const data = await response.data;
        const shows = data.data;
        if (response.status === 200) {
          if (shows[0].status === "drafted") {
            history.replace("/event-not-found");
          }
          const postEndDateTime = new Date(`${shows[0].post_end_date}`);

         
          const currentDateTime = new Date();

          if (currentDateTime > postEndDateTime) {
            history.replace("/event-not-found");
          } 
          props.pushShowById([shows[0]]);
          // if (shows.find(show => show.product_id === Number(id)) === undefined)
          //   history.replace("/shows");
        }
        else
          history.replace("/event-not-found");
      } catch (err) {
        console.log(err);
        history.replace("/event-not-found");
        setLoader(false);
      }
    };
    if (id)
      showById();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    console.log(props.shows.length);
    console.log(props.loading);

    if (props.shows.length > 0 && props.shows.find(show => show.product_id === Number(id)).min_quantity !== undefined)
    setLoader(false);
    // eslint-disable-next-line
  }, [props.shows.length, props.loading]);

  return (
    <div style={{backgroundColor:"#f1f1f1"}}>
      <div className="backgroundDiv" >
        <div className="firstBackgroundDiv">
          <h1>MOON TOWER TICKETS</h1>
        </div>
        <div className="secondBackgroundDiv">


        </div>
      </div>
      <div className="show_page_div" >
        <div className="more_div">
          {!loader ?
            null : (
              <CircularProgress
                style={{ width: "120px", height: "120px" }}
                color="warning"
                disableShrink
              />
            )}
        </div>
        {(!loader && props.show === undefined) ? (
          <div className="more_div">
            <h1>No event found.</h1>
          </div>
        ) : null}
        {(props.show && !loader) ? (
          <div className="show_flex_div_1">
            <div className="item_card_img_div">
              <div style={{ 'backgroundImage': `url(${props.show.featured_image})`, backgroundSize: 'cover', width: '100%', borderRadius: '13px' }}>
                <img
                  alt="img"
                  src={props.show.featured_image ? props.show.featured_image : dummyImg}
                  className="item_card_img_show_card"
                />
              </div>
            </div>
            <div className="show_details_price_buy">
              <p className="show_name">
                {props.show.formatted_title}
              </p>
              <div className="heading_break"></div>
              <p className="show_date">
                {moment(props.show.post_start_date).format("LL")} -{" "}
                {moment(props.show.post_end_date).format("LL")}
                {"  "}
                {props?.show?.out_of_stock ? <Chip label={SPECIAL.soldOut} color="error" variant="outlined" style={{ backgroundColor: '#f5dfdf' }} /> : props?.show?.type === SPECIAL.free ? <Chip label={SPECIAL.inStock} color="success" variant="outlined" style={{ backgroundColor: '#effce3' }} /> : null}
                {" "}
              </p>
              <p className="show_time">
                {moment(props.show.post_start_time, ["HH.mm"]).format(
                  "hh:mm a"
                )}{" "}
                -{" "}
                {moment(props.show.post_end_time, ["HH.mm"]).format("hh:mm a")} {props?.show.timezone} {props?.show.age_group != null ? <span>• {props?.show.age_group}</span> : null}
              </p>
              <span className="show_venue">
                {props.show.mep_location_venue} {props.show.mep_street} {props.show.mep_city}
              </span>
              <div className="show_details_break"></div>
              <BuyControllers
                key={props.show?.product_id}
                id={props.show?.product_id}
                price={props.show?.ticket_price_web}
                qty={props.show?.purchased_tickets}
                cat={props?.show?.category_primary}
                type={props?.show?.type}
                categories={props?.show?.special_categories}
                outOfStock={props?.show?.out_of_stock}
                show={props.show}
              />
            </div>
          </div>
        ) : (
          reloadRedux()
        )}
        {(props.show && !loader) ?
          <div className="description_div">
            <div className="description_div_navbar">
              <div
                className="description_div_navbar_tab"
                onClick={() =>
                  setShowDescription({
                    ...showDescription,
                    event: true,
                    artist: false,
                  })
                }
                style={{
                  backgroundColor: showDescription.event ? "grey" : "#fff",
                  color: showDescription.event ? "#fff" : "#ccc",
                }}
              >
                Show Details
              </div>
              <div
                className="description_div_navbar_tab"
                onClick={() =>
                  setShowDescription({
                    ...showDescription,
                    event: false,
                    artist: true,
                  })
                }
                style={{
                  backgroundColor: showDescription.artist ? "grey" : "#fff",
                  color: showDescription.artist ? "#fff" : "#ccc",
                }}
              >
                Artist's Details
              </div>
            </div>
            <div
              className="description_div_Div"
              dangerouslySetInnerHTML={{
                __html: props.show
                  ? showDescription.event
                    ? props.show.formatted_content_web
                    : props.show.artist_detail
                  : null,
              }}
            ></div>
          </div>
          : null}
      </div>
    </div>
  );
};


const mapStoreToProps = (state) => {
  return {
    shows: state.shows,
    loading: state.loading
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    pushShows: (shows) =>
      dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    pushShowById: (show) =>
      dispatch({ type: actionTypes.PUSH_SHOW_BY_ID, item: show }),
  }
};


export default connect(mapStoreToProps, mapDispatchToProps)(ShowPage);
