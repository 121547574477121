import * as React from 'react';
import "./WidgetVerticalCard.css"
import dummyImg from '../../mtt-icon.png';

export default function WidgetVerticalCard(props) {
    return (
        <div className='widget_vertical_card_div'>
            <div className='img_div_vertical' style={{ 'backgroundImage': `url(${props.src ? props.src : dummyImg})`, backgroundSize: 'cover', height: '100%', borderRadius: '13px', minHeight: '100px', maxWidth: "200px" }}>
                {props.src ?
                    <img src={props.src} alt={props.title} className='widget_vertical_card_img' />
                    :
                    <img src={dummyImg} alt={props.title} className='widget_vertical_card_img_default' />
                }
            </div>
            <div className='widget_vertical_card_text_div'>
                <div> 
                <p className="widget_vertical_card_title" style={{ marginTop: "10px" }}>{props.title}</p>
                    <p className="widget_vertical_card_title" style={{ fontSize: "15px" }}>{props.date}</p>
                {/* <p className='widget_vertical_card_date' style={{ marginTop: "-5px" }}>{moment(props.date).format('LL')}</p> */}
                <p className='widget_vertical_card_price-range'>
                    {/* {props.type === SPECIAL.free ? "FREE" : formatRange(props?.categories, props?.price)} */}
                    {props.event_price_range}
                </p>
                </div>
                <div style={{ borderTop: "0.5px solid #ccc", paddingTop: "15px" }}> 
                    <button className='widget_vertical_card_btn' onClick={() => window.open(props?.link, "_blank")}>Buy Tickets</button>
                </div>
            </div>
        </div>
    )
}

