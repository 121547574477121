import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Logo from "../../../images/logo@2x.png";
import '../Header/Header.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TicketAccess from "../../../images/ticket-access.svg";
import PromoterLogin from "../../../images/promoter-login.svg";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { LOCALSTORAGE_ENUMS } from "../../../constants/localStorageEnums";

const Header = () => {
  const history = useHistory();
  const isAuthenticated = window.localStorage.getItem(LOCALSTORAGE_ENUMS.token);

  const handleClickSection = (e, value) => {
    e.preventDefault();
    const element = document.getElementById(value);

    if (window.location.pathname === "/") {
      if (element) {
        const scrollOffset = 90;

        setTimeout(() => {
          window.scrollTo({
            top: element.offsetTop - scrollOffset,
            behavior: "smooth",
          });
        }, 100);
      }
    } else {
      history.push(`/?id=${value}`);
    }
  };

  const logoutHandler = async () => {
    if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.remember)) {
      window.localStorage.removeItem(LOCALSTORAGE_ENUMS.token);
      window.localStorage.removeItem(LOCALSTORAGE_ENUMS.userData);
      window.localStorage.removeItem(LOCALSTORAGE_ENUMS.userID);
      // handleClose();
     window.location.reload();
    } else {
      window.localStorage.clear();
      // handleClose();
      window.location.reload();
    }
  };

  return (
    <>
      <header className="header" style={{ width: "100%"}}>
        <Navbar expand="md" >
          <Container> 
            <Navbar.Brand href="/">
              <img src={Logo} alt="" className="imgfluid" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"  />
            <Navbar.Collapse id="basic-navbar-nav" >
              <div className="nav-row" >   
                {!isAuthenticated ? (
                  <div className="enter-box">
                   <a href={process.env.REACT_APP_ADMIN_PANEL} style={{ textDecoration: "none" }}>
                     <img src={PromoterLogin} alt='promoter-login' className='img-fluid' /> Promoter Login
                   </a>
                  <NavLink to="/login" style={{ textDecoration: "none" }} exact>
                    <img src={TicketAccess} alt='ticket-access' className='img-fluid' /> My Tickets
                  </NavLink>
                  </div>
                ) : window.localStorage.getItem(
                    LOCALSTORAGE_ENUMS.guestMode
                  ) === "true" ? (
                  <NavLink to="/login" style={{ textDecoration: "none" }} exact>
                    <div className="navlinks"> My Tickets</div>
                    </NavLink>) : 
                    (<div className="enter-box">
                      <a href={process.env.REACT_APP_ADMIN_PANEL} style={{ textDecoration: "none" }}>
                        <img src={PromoterLogin} alt='promoter-login' className='img-fluid' /> Promoter Login
                      </a>
                     
                      <NavLink to="/tickets" style={{ textDecoration: "none" }} exact>
                        <img src={TicketAccess} alt='ticket-access' className='img-fluid' />My Tickets
                        </NavLink>
  
                  
                      <NavLink to="/myprofile" style={{ textDecoration: "none" }} exact>
                        <img src={TicketAccess} alt='ticket-access' className='img-fluid' /> My Profile
                        </NavLink>
                  
         
                      <NavLink to="#" onClick={logoutHandler} style={{ textDecoration: "none" }} exact>
                          Logout
                        </NavLink>
                     
                    {/* <li className="navlink_dropdown_menu">
                    <Button
                      id="demo-positioned-button"
                      aria-controls="demo-positioned-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <AccountCircleIcon
                        className="navlink_dropdown_menu_icon"
                        style={{ fontSize: "2rem" }}
                      />
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <NavLink
                        to="/myprofile"
                        style={{ textDecoration: "none", color: "black" }}
                        exact
                      >
                        <MenuItem
                          onClick={handleClose}
                          style={{ fontSize: "14px" }}
                          className="navlinks_menu"
                        >
                          My Profile
                        </MenuItem>
                      </NavLink>
                      <NavLink
                        to="/tickets"
                        style={{ textDecoration: "none", color: "black" }}
                        exact
                      >
                        <MenuItem
                          onClick={handleClose}
                          style={{ fontSize: "14px" }}
                          className="navlinks_menu"
                        >
                          My Tickets
                        </MenuItem>
                      </NavLink>
                      {isAuthenticated ? (
                        <MenuItem
                          onClick={logoutHandler}
                          style={{ fontSize: "14px" }}
                          className="navlinks_menu"
                        >
                          Logout
                        </MenuItem>
                      ) : null}
                    </Menu>
                  </li> */}
                  </div>
                )}
                <Nav className="ms-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="#section1" onClick={(e) => handleClickSection(e, "section1")} >About Us</Nav.Link>
                  <Nav.Link href="/shows">Upcoming Events</Nav.Link>
                  <Nav.Link
                    href={process.env.REACT_APP_ADMIN_PANEL}
                    className="btn-nav"
                  >
                    Create Event <FontAwesomeIcon icon={faArrowRight} />{" "}
                  </Nav.Link>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
