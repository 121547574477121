import React from 'react'
import { NavLink } from 'react-router-dom'
import "./TicketNavbar.css"
export const TicketNavbar = () => {
    return (
        <div className="ticket_navbar">
            <NavLink to="/tickets" className="ticket_navlinks" exact>
            <div className="ticket_navbar_links">
                Upcoming Tickets
            </div>
            </NavLink>
            <NavLink to="/tickets/expiredtickets" className="ticket_navlinks" exact>  
            <div className="ticket_navbar_links">
                Expired Tickets
            </div>
            </NavLink>
        </div>
    )
}
