import React from "react";


export const NotFound = (props) => {
    return(
        <div className="pageDiv">
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv2">
          <h1>PAGE NOT FOUND</h1>
        </div>
      </div>

      </div>
    )
}
