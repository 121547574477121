import detailImage from '../../images/detail-img.jpg';
// import bannerVideo from '../../images/video-banner.mp4';
import logo1 from '../../images/part-1.png';
import logo2 from '../../images/part-2.png';
// import logo3 from '../../images/part-3.png';
import logo4 from '../../images/part-4.png';
import logo5 from '../../images/part-5.png';
import logo6 from '../../images/part-6.png';
import logo7 from '../../images/part-7.png';
import logo8 from '../../images/part-8.png';
import logo9 from '../../images/part-9.png';
import logo10 from '../../images/part-10.png';
import logo11 from "../../images/m1.png"
import logo12 from "../../images/m2.png"
import logo13 from "../../images/m3.png"
import logo14 from "../../images/m4.png"
import logo15 from "../../images/m5.png"
import logo16 from "../../images/m6.png"
import logo17 from "../../images/m7.png"
import logo18 from "../../images/m8.png"

// import logo11 from '../../images/slider-partners-11.png';
// import logo12 from '../../images/slider-partners-12.png';
// import logo13 from '../../images/slider-partners-13.png';
import moonTowerImage from '../../images/moon-tower-img-2.jpg';


export const homeBanner = {
    title: 'Event Ticketing Solutions',
    detail: 'Take your events to the next level with our FREE online platform and tools. Managing ticket sales, marketing your events, and boosting your sales just got easier with our hassle-free platform.',
    btn: 'Create Event',
    bannerVideo: process.env.REACT_APP_VIDEO_BANNER,
}

export const partners = {
    logo1: logo1,
    logo2: logo2,
    // logo3: logo3,
    logo4: logo4,
    logo5: logo5,
    logo6: logo6,
    logo7: logo7,
    logo8: logo8,
    logo9: logo9,
    logo10: logo10,
    logo11: logo11,
    logo12: logo12,
    logo13: logo13,
    logo14: logo14,
    logo15: logo15,
    logo16: logo16,
    logo17: logo17,
    logo18: logo18,


}

export const ticketText = {
    title1: "You Get",
    title2: "100% of The",
    title3: "Ticket Sales"
}

export const stepProcess = {
    steptitle: 'Easy 3-Step Process',
    stepdetail: 'Launching an event and setting up ticket sales has never been easier! Get started now with your event account. You’ll be setup and ready to go in minutes with our easy-to-use platform.',
    btn: 'Create Event',
    img1: require('../../images/1.svg').default,
    alt1: 'Step 1',
    img2: require("../../images/2.svg").default,
    alt2: 'Step 2',
    img3: require("../../images/3.svg").default,
    alt3: 'Step 3',
    stepdata1: 'Set up an account with your email and password.',
    stepdata2: 'Upload your event information and customized artwork.',
    stepdata3: 'Publish, promote and start selling tickets.',
    smalltitle1: 'Register',
    smalltitle2: 'Create',
    smalltitle3: 'Launch',
}   

export const faqData = {
    faqleftimg: moonTowerImage,
    faqmaintitle: 'Moon Tower Tickets',
    faqtext: 'The solution to all your event ticketing needs.',
    faqBtn: 'Create Event',
}

export const eventData = {
    eventTitle: 'Current Events',
    eventText: 'Lorem ipsum dolor sit amet consectetur. Diam tellus luctus aliquam faucibus erat. Amet ornare nulla auctor duis sed condimentum consequat. Et ullamcorper aliquam porta sed id tristique ornare.',
}

export const FooterData = {
    marqueecontent: 'moon tower tickets',
    link1: 'Home',
    link2: 'About Us',
    link3: 'Upcoming Events',
    link4: 'Promoter Login',
    link5: 'Create Event',
    link6: 'Policy',
    link7: 'Terms and Conditions',
    link8: 'Refund Policy',
    link9: 'Contact Us',
    copyright: '©2024. All rights reserved by Moon Tower Tickets',
}

export const Details = {
    detail: "For years, we've been deeply entrenched in various events, not just music. As insiders, we've seen talented individuals often not getting their fair share while customers face high costs. This bothered us deeply.",
    detail2: "So, we took action. Through innovation, we've ensured fair compensation for all involved while making events more affordable for everyone. It's been a journey driven by our personal commitment to fairness and making events accessible to all.",
    img: detailImage,
    alt: "details img",
} 