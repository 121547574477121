import accordionimg1 from '../../../images/moon-ic1.svg';
import accordionimg2 from '../../../images/moon-ic2.svg';
import accordionimg3 from '../../../images/moon-ic3.svg';
import accordionimg4 from '../../../images/moon-ic4.svg';
import accordionimg5 from '../../../images/moon-ic5.svg';
import accordionimg6 from '../../../images/moon-ic6.svg';

export const AccordionObjOne = {
    eventKey: '0',
    accordionHeaderImg: accordionimg1,
    accordionButtonText: 'ONLINE DASHBOARD',
    accordionBodyText: 'Simple and easy to create. Track your sales and easily share your progress with others.',
}

export const AccordionObjTwo = {
    eventKey: '1',
    accordionHeaderImg: accordionimg6,
    accordionButtonText: 'FAST SECURE PAYOUT',
    accordionBodyText: 'Flexible Payout options directly deposited into your account.',
}

export const AccordionObjThree = {
    eventKey: '2',
    accordionHeaderImg: accordionimg3,
    accordionButtonText: 'MARKETING TOOLS',
    accordionBodyText: 'Lead generation allows you to stay in contact with your customers via email and social media.',
}

export const AccordionObjFour = {
    eventKey: '3',
    accordionHeaderImg: accordionimg4,
    accordionButtonText: 'NO CONTRACTS',
    accordionBodyText: 'Create and sell tickets to unlimited events with no obligations.',
}

export const AccordionObjFive = {
    eventKey: '4',
    accordionHeaderImg: accordionimg5,
    accordionButtonText: 'Moon Tower Tickets',
    accordionBodyText: 'The solution to all your event ticketing needs..',
}

export const AccordionObjSix = {
    eventKey: '5',
    accordionHeaderImg: accordionimg2,
    accordionButtonText: 'PHONE APP',
    accordionBodyText: 'Scan tickets at the door and manage event sales on-the-go with our free app.',
}

export const AccordionObjSeven = {
    eventKey: '6',
    accordionHeaderImg: accordionimg3,
    accordionButtonText: 'BUSINESS SOLUTIONS',
    accordionBodyText: 'Handling all state filings including non- profit organizations.',
}
