import * as React from 'react';
import "./WidgetHorizontalCard.css"
import dummyImg from '../../mtt-icon.png';

export default function WidgetHorizontalCard(props) {
    return (
        <div className='widget_horizontal_card_div'>
            <div style={{ 'backgroundImage': `url(${props.src ? props.src : dummyImg})`, backgroundSize: 'cover', height: '100%', borderRadius: '13px', minHeight: '100px' }}>
                {props.src ?
                    <img src={props.src} alt={props.title} className='widget_horizontal_card_img' />
                    :
                    <img src={dummyImg} alt={props.title} className='widget_horizontal_card_img_default' />
                }
            </div>
            <div className='widget_horizontal_card_text_div' style={{ minHeight: '61px' }}>
                <p className="widget_horizontal_card_title" style={{ marginTop: "10px" }}>{props.title.length > 39 ? props.title.slice(0, 40) + "..." : props.title}</p>
                <p className="widget_horizontal_card_title">{props.date}</p>
                {/* <p className='widget_horizontal_card_date' style={{ marginTop: "-5px" }}>{moment(props.date).format('LL')}</p> */}
                <p className='widget_horizontal_card_price-range'>
                    {/* {props.type === SPECIAL.free ? "FREE" : formatRange(props?.categories, props?.price)} */}
                    {props.event_price_range}
                </p>
                <button className='widget_horizontal_card_btn' onClick={() => window.open(props?.link, "_blank")}>Buy Tickets</button>
            </div>
        </div>
    )
}

