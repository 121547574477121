import axios from 'axios';

// Axios Instance
const instance = axios.create({
  // baseURL: 'https://3lc1l8xpxb.execute-api.us-east-1.amazonaws.com/dev/api/',
  headers: {
    name: 'testing'
  }
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => error.response
);

export default instance;
