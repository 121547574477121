import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./SuccessPage.css";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { splitter } from "../../utils/splitter";
import swal from "sweetalert";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";
import { reportSuccessPayment } from "../../services/Payment.services";

export const SuccessPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loader, setLoader] = useState(false);
  let history = useHistory();
  let url = useLocation();
  let productURL = url.search;
  const method = (splitter(productURL, '&', '=', 0, 1));

  const createOrder = async () => {
    try {
      const body = {
        "paymentMethod": method,
      }
      if (method === "Stripe") {
        const customerId = (splitter(productURL, '&', '=', 1, 1));
        const token = (splitter(productURL, '&', '=', 2, 1));
        const paymentId = (splitter(productURL, '&', '=', 3, 1));
        body.token = token;
        body.customer = customerId;
        body.paymentId = paymentId;
      }
      else {
        const paymentId = (splitter(productURL, '&', '=', 1, 1));
        const token = (splitter(productURL, '&', '=', 2, 1));
        const customerId = (splitter(productURL, '&', '=', 3, 1));
        body.token = token;
        body.customer = customerId;
        body.paymentId = paymentId;
      }
      const response = await reportSuccessPayment(body);
      const data = await response.data;
      if (response.status === 200) {
        swal("Success", data.message, "success");
        setLoader(false);
        if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.guestMode) === "true") {
          history.replace("/");
          window.localStorage.clear();
        }
        else
          history.replace("/tickets");
      }
      if (response.status === 403) {
        swal("Error", data.message, "error").then(() => {
          if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.guestMode) === "true") {
            history.replace("/");
            window.localStorage.clear();
          }
          else {
            history.replace("/tickets");
          }
        })
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoader(true);
    if (!productURL) {
      return history.replace("/shows");
    }
    createOrder();
    // eslint-disable-next-line
  }, [url]);

  return (
    <div className="pageDiv">
      <div className="backgroundDiv">
        <div className="firstBackgroundDiv">
          <h1>PAYMENT SUCCESSFUL</h1>
        </div>
      </div>
      <div className="info_div">
        {loader ? (
          <div className="pageHeader">
            <div style={{ widtth: '100%', paddingTop: '15px', justifyContent: 'center' }}>
              <CircularProgress color="secondary" />
              <p style={{ color: 'purple', fontWeight: 'bold' }}>Your payment is being verified....<br />Please don't quit this page.</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};


const mapStoreToProps = (state) => {
  return {
    navbar: state.mobileNavbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink }),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(SuccessPage);