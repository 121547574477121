import React, { useState } from "react";
import "./LoginForm.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { isEmailValid } from "../../utils/validators";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";
import { loginRequest, reportForgetPassword } from "../../services/Auth.services";
import { failedMsgGenerator, invalidMsgGenerator, requiredMsgGenerator } from "../../constants/Messages";

export const LoginForm = (props) => {

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const redirectToProfile = (searchParams.get("account-delete"));
  const [errMsg, setErrMsg] = useState("");
  const [values, setValues] = useState({
    email: window.localStorage.getItem("email") ? window.localStorage.getItem("email") : "",
    password: window.localStorage.getItem("password") ? window.localStorage.getItem("password") : "",
    showPassword: false,
    remember: false,
  });

  const [forgetPassword, setForgetPassword] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [error2, setError2] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState({
    forLogin: false,
    forForget: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "email")
      setErrors({
        ...errors,
        email:
          !isEmailValid(event.target.value
          ),
      });
    if (prop === "password")
      setErrors({
        ...errors,
        password: !event.target.value,
      });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgetSubmit = async (event) => {
    event.preventDefault();
    if (
      isEmailValid(forgetEmail
      )
    ) {
      setLoading({ ...loading, forForget: true, forLogin: false });

      const response = await reportForgetPassword(forgetEmail);
      const data = await response.data;

      setLoading({ ...loading, forForget: false, forLogin: false });
      if (response.status === 200) {
        setForgetEmail("");
        swal({
          title: "Link sent !",
          text: data.message,
          icon: "success",
        });
      }
      if (response.status === 403) {
        swal({
          title: failedMsgGenerator("Request"),
          text: data.message,
          icon: "error",
        });
      }
    } else {
      setError2(
        !isEmailValid(forgetEmail
        )
      );
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      delete values.showPassword;
      const { email, password } = values;
      if (
        isEmailValid(email
        ) &&
        password
      ) {
        setLoading({ ...loading, forForget: false, forLogin: true });
        if (values.remember) {
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.email, values.email)
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.password, values.password)
        }
        if (values.remember === false) {
          window.localStorage.removeItem(LOCALSTORAGE_ENUMS.email);
          window.localStorage.removeItem(LOCALSTORAGE_ENUMS.password);
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.remember, false)
        }

        const response = await loginRequest(email, password);
        const data = await response.data;
        setLoading({ ...loading, forForget: false, forLogin: false });
        setLoading(false);
        if (response.status === 200) {
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.remember, values.remember);
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.token, data.data.accessToken);
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.userData, JSON.stringify(data.data.user));
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.userID, data.data.user.id);
          window.localStorage.setItem(LOCALSTORAGE_ENUMS.guestMode, "false");
          window.sessionStorage.clear();
        }
        if (response.status === 403) {
          setErrMsg(data.message);
        }
        if (response.status === 200) {
          if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.checkout) === "true") {
            history.goBack();
            props.changeLinks("home");
          }
          else {
            if (redirectToProfile === "true") {
              window.location.href = ("myprofile");
            }
            else {
            history.push("/");
            props.changeLinks("home");
            }
          }
        }

      } else {
        setErrors({
          ...errors,
          email:
            !isEmailValid(values.email
            ),
          password: !values.password,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="login_container">
      <h1 className="login_heading">Login</h1>
      <Box
        onSubmit={handleSubmit}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div className="form_input_div">
          <TextField
            id="outlined-adornment-email-login"
            type="email"
            color="warning"
            style={{ width: "100%" }}
            value={values.email}
            onChange={handleChange("email")}
            label="Email * "
            autoComplete="off"
            error={errors.email || errMsg.length !== 0}
            helperText={
              errMsg ? <span style={{ color: "#d32f2f" }}> {errMsg}</span> :
                errors.email
                  ? values.email.length === 0
                    ? requiredMsgGenerator("Email")
                    : invalidMsgGenerator("Email")
                  : null
            }
          />
        </div>
        <div className="form_input_div">
          <FormControl
            sx={{ width: "100%" }}
            color="warning"
            variant="outlined"
          >
            <InputLabel
              style={{ color: errors.password ? "#D10000" : "grey" }}
              htmlFor="outlined-adornment-password-login"
            >
              Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-login"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              style={{ width: "100%" }}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password * "
              autoComplete="off"
              error={errors.password || errMsg.length !== 0}
            />
            {errMsg ?
              <FormHelperText error id="accountId-error">
                {errMsg}
              </FormHelperText> :
              errors.password && (
                <FormHelperText error id="accountId-error">
                  {values.password.length === 0
                    ? requiredMsgGenerator("Password")
                    : null}
                </FormHelperText>
              )}
          </FormControl>
        </div>
        <div className="form_input_submit_div">
          {loading.forLogin ? (
            <CircularProgress color="warning" disableShrink />
          ) : (
            <button type="submit" className="form_input_btn">
              Login
            </button>
          )}
          <div className="form_input_checkbox_div">
            <FormControlLabel
              style={{ marginRight: '2px' }}
              control={
                <Checkbox
                  onChange={() => {
                    setValues({ ...values, remember: !values.remember });
                  }}
                  style={{ padding: '3px' }}
                  color="warning"
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                />
              }
              label={
                <>
                  <span className="checkbox_msg">Remember me</span>
                </>
              }
            />
          </div>
        </div>
      </Box>
      <p
        onClick={() => setForgetPassword(!forgetPassword)}
        className="forget_password_link"
      >
        Forgot password ?
      </p>
      {forgetPassword ? (
        <Box
          onSubmit={handleForgetSubmit}
          component="form"
          noValidate
          autoComplete="off"
        >
          <div className="form_input_div">
            <TextField
              id="outlined-adornment-email-forgotPassword"
              type="email"
              color="warning"
              style={{ width: "100%" }}
              value={forgetEmail}
              onChange={(e) => {
                setForgetEmail(e.target.value);
                setError2(
                  !isEmailValid(e.target.value
                  )
                );
              }}
              label="Email"
              autoComplete="off"
              error={error2}
              helperText={
                error2
                  ? forgetEmail.length === 0
                    ? requiredMsgGenerator("Email")
                    : invalidMsgGenerator("Email")
                  : null
              }
            />
          </div>
          <div className="form_input_submit_div">
            {loading.forForget ? (
              <CircularProgress color="warning" disableShrink />
            ) : (
              <button type="submit" className="form_input_btn">
                Request a Link
              </button>
            )}
          </div>
        </Box>
      ) : null}
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    navbar: state.mobileNavbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink }),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(LoginForm);