import React, { useState } from "react";
import "../LoginForm/LoginForm.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { isEmailValid } from "../../utils/validators";
import { loginRequest } from "../../services/Auth.services";
import { invalidMsgGenerator, requiredMsgGenerator } from "../../constants/Messages";
import swal from "sweetalert";

export const ScannerLoginForm = (props) => {
    const [errMsg, setErrMsg] = useState("");
    const [values, setValues] = useState({
        email: window.localStorage.getItem("email") ? window.localStorage.getItem("email") : "",
        password: window.localStorage.getItem("password") ? window.localStorage.getItem("password") : "",
        showPassword: false,
        remember: false,
    });

    const [errors, setErrors] = useState({
        email: false,
        password: false,
    });
    const [loading, setLoading] = useState({
        forLogin: false,
        forForget: false,
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === "email")
            setErrors({
                ...errors,
                email:
                    !isEmailValid(event.target.value
                    ),
            });
        if (prop === "password")
            setErrors({
                ...errors,
                password: !event.target.value,
            });
    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            delete values.showPassword;
            const { email, password } = values;
            if (
                isEmailValid(email
                ) &&
                password
            ) {
                setLoading({ ...loading, forForget: false, forLogin: true });
          

                const response = await loginRequest(email, password, "worker");
                const data = await response.data;
                setLoading({ ...loading, forForget: false, forLogin: false });
                setLoading(false);
                if (response.status === 200) {

                    const deleteResponse = await fetch(`${process.env.REACT_APP_BACKEND_API}/delete-my-account`, {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${data.data.accessToken}`
                        }
                    });
                    setLoading(false);
                    const deleteData = await deleteResponse.data;

                    if (deleteResponse.status === 200) {
                        window.localStorage.clear();
                        swal("Your account has been deleted.", "", "success").then(() => {
                                       window.location.reload();
                        })
                    }
                    if (deleteResponse.status === 403) {
                        swal(deleteData.message, "", "error");
                    }
                }
                if (response.status === 403) {
                    setErrMsg(data.message);
                }
                if (response.status === 200) {
                    
                }

            } else {
                setErrors({
                    ...errors,
                    email:
                        !isEmailValid(values.email
                        ),
                    password: !values.password,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div className="login_container" style={{ width: "100%", maxWidth: "500px" }}>
            <h1 className="login_heading">Login to delete your account</h1>
            <Box
                onSubmit={handleSubmit}
                component="form"
                noValidate
                autoComplete="off"
            >
                <div className="form_input_div">
                    <TextField
                        id="outlined-adornment-email-login"
                        type="email"
                        color="warning"
                        style={{ width: "100%" }}
                        value={values.email}
                        onChange={handleChange("email")}
                        label="Email * "
                        autoComplete="off"
                        error={errors.email || errMsg.length !== 0}
                        helperText={
                            errMsg ? <span style={{ color: "#d32f2f" }}> {errMsg}</span> :
                                errors.email
                                    ? values.email.length === 0
                                        ? requiredMsgGenerator("Email")
                                        : invalidMsgGenerator("Email")
                                    : null
                        }
                    />
                </div>
                <div className="form_input_div">
                    <FormControl
                        sx={{ width: "100%" }}
                        color="warning"
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: errors.password ? "#D10000" : "grey" }}
                            htmlFor="outlined-adornment-password-login"
                        >
                            Password *
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password-login"
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            style={{ width: "100%" }}
                            onChange={handleChange("password")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password * "
                            autoComplete="off"
                            error={errors.password || errMsg.length !== 0}
                        />
                        {errMsg ?
                            <FormHelperText error id="accountId-error">
                                {errMsg}
                            </FormHelperText> :
                            errors.password && (
                                <FormHelperText error id="accountId-error">
                                    {values.password.length === 0
                                        ? requiredMsgGenerator("Password")
                                        : null}
                                </FormHelperText>
                            )}
                    </FormControl>
                </div>
                <div className="form_input_submit_div" style={{ justifyContent: "center" }}>
                    {loading.forLogin ? (
                        <CircularProgress color="warning" disableShrink />
                    ) : (
                        <button type="submit" className="form_input_btn">
                            Delete account
                        </button>
                    )}
                </div>
            </Box>
        </div>
    );
};