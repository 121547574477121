import {
  Redirect
} from 'react-router-dom';
const ProtectedRoutes = ({children , isAuthenticated}) => (
       isAuthenticated
            ? (
              children
            ) : (
              <Redirect
                to='/login'
              />
            )
);

export default ProtectedRoutes;