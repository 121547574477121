import React from 'react';
import { Link } from 'react-router-dom';
import failed from "./failed.png";
import "./PaymentFailed.css";

export const PaymentFailed = () => {
  return (
      <div className="pageDiv">
          <div className="backgroundDiv">
              <div className="firstBackgroundDiv">
                  <h1>PAYMENT FAILED</h1>
              </div>
          </div>
          <div className="payment_failed_div">
              <div className='payment_failed_details'>
                  <p className='payment_failed_heading'>Your Payment was cancelled or declined!</p>
                  <br/>
                  <Link to='/shows' className='payment_failed_link' replace="true">
                    Try again
                  </Link>
              </div>
             <img src={failed} alt="failed" className='broken_failed' />
          </div>
      </div>
  )
}
