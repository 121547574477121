import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "./DeleteUserModal.css";
import { CircularProgress, TextField } from '@mui/material';
import swal from 'sweetalert';
import { DeleteUserAction } from '../../services/Auth.services';
import { requiredMsgGenerator } from '../../constants/Messages';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    borderRadius: "13px",
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    p: 6,
};

export default function DeleteUser({ open, setOpen }) {
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setValue('');
        setError(false);
    }, [open])

    const handleClose = () => setOpen(false);

    const handleChange = (v) => {
        setValue(v);
        setError(!("DELETE").includes(v));
    }

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            if (value === "DELETE") {
                setLoading(true);
                const response = await DeleteUserAction();
                setLoading(false);
                const data = await response.data;
                
                if (response.status === 200) {
                    window.localStorage.clear();
                    window.location.reload();
                }
                if (response.status === 403) {
                    swal(data.message, "", "error");
                }
            }
            else {
                setError(value !== "DELETE");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!loading ? <div className="login_contaier">
                        <h2 className="login_heading">Are you absolutely sure?</h2>
                        <p className='delete_modal_p'>
                            This action cannot be undone. Your account
                            will be deleted permanently.
                        </p>
                        <p className='delete_modal_confirm_p'>
                            Please type <span className='orange_span'>DELETE</span> to confirm.
                        </p>
                        <TextField
                            id="outlined-adornment-name-registration"
                            type="text"
                            style={{ width: "100%", marginTop: '15px' }}
                            value={value}
                            onChange={(e) => handleChange(e.target.value.toUpperCase())}
                            label=""
                            placeholder='Type here...'
                            color="warning"
                            autoComplete="off"
                            error={error}
                            helperText={
                                value.length < 1 && error
                                    ? requiredMsgGenerator("Text")
                                    : value && error
                                        ? "Enter correct text."
                                        : null
                            }
                        />
                        <div className='delete_modal_btn_flex'>
                            <button onClick={() => setOpen(false)} className="cancel_btn">
                                Cancel
                            </button>
                            <button onClick={handleSubmit} disabled={value !== "DELETE"} className={value !== "DELETE" ? "disabled_delete_btn" : "delete_btn"}>
                                Delete
                            </button>
                        </div>
                    </div> :
                        <div className='circular_spinner_div'>
                            <CircularProgress color='warning' />
                        </div>
                    }
                </Box>
            </Modal>
        </div>
    );
}
