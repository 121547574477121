import React, { useEffect } from 'react'
import LoginForm from '../../components/LoginForm/LoginForm'
import { RegisterForm } from '../../components/RegistrationForm/RegisterForm'
import "./LoginPage.css"
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
export const LoginPage = (props) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
    props.changeLinks("login");
    // eslint-disable-next-line
  }, [])
  return (
    <div>
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv2">
          <h1>MY ACCOUNT</h1>
        </div>
        <div className="secondBackgroundDiv2">

        </div>
      </div>
      <div className="create_event_div">
        <span className="register_btn_helper_text_pd2">*Register here</span>
        <a href={process.env.REACT_APP_ADMIN_PANEL} className='create_event_btn'>Create an event</a>
      </div>
      <div className="my_account_forms_div">
        <LoginForm />
        <RegisterForm />
      </div>
    </div>
  )
}

const mapStoreToProps = (state) => {
  return {
    shows: state.shows
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushShows: (shows) =>
      dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink })

  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(LoginPage);