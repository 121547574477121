import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import '../EasySteps/Steps.css';

const Steps = ({steptitle, stepdetail, btn, img1, img2, img3, stepdata1, stepdata2, stepdata3, smalltitle1, smalltitle2, smalltitle3, alt1, alt2, alt3 }) => {;
    return (
        <>
            <section className="easy-step-wrp">
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={6} >
                            <div className="easy-title-box">
                                <h2>{steptitle}</h2>
                                <p>{stepdetail}</p>
                                <a href={process.env.REACT_APP_ADMIN_PANEL} className="btn-main"><span>{btn}</span> <FontAwesomeIcon icon={faArrowRight} /></a>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={6} >
                            <div className="offset-md-1">
                                <div className="steps-list">
                                    <ul>
                                        <li>
                                            <div className="step-num">
                                                <img src={img1} alt={alt1} className='img-fluid' />
                                            </div>
                                            <div className="step-data">
                                                <h3>{smalltitle1}</h3>
                                                <p>{stepdata1}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="step-num">
                                                <img src={img2} alt={alt2} className='img-fluid' />
                                            </div>
                                            <div className="step-data">
                                                <h3>{smalltitle2}</h3>
                                                <p>{stepdata2}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="step-num">
                                                <img src={img3} alt={alt3} className='img-fluid' />
                                            </div>
                                            <div className="step-data">
                                                <h3>{smalltitle3}</h3>
                                                <p>{stepdata3}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Steps;