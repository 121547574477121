import React, { useState } from "react";
import moment from "moment";
import QRCode from "react-qr-code";
import { TicketPopUp } from "../TicketPopUp/TicketPopUp";
import "./Tickets.css";
import dummyImg from '../../mtt-icon.png';
import TransitionsModal  from "../Modal/index";
export const Tickets = (props) => {
  
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [toggleSeatModal, setToggleSeatModal] = useState(false);
  
  const toggle = () => {
    setTogglePopUp(!togglePopUp);
  };
  
  return (
    <>
      <TicketPopUp show={togglePopUp} ticket={props.ticket} setShow={setTogglePopUp} handleOpen={() => setToggleSeatModal(true)} handleClose={() => setToggleSeatModal(false)} />
      <div className="tickets_card">
        <div className="tickets_img_div" style={{'backgroundImage': `url(${props.ticket.featured_image})`}}>
        <img
          alt="ticket_thumbnail"
          src={props.ticket.featured_image ? props.ticket.featured_image : dummyImg}
          className="ticket_thumbnail"
          />
          </div>
        <div className="ticket_details">
          <p className="ticket_show_title">{props.ticket.title}</p>
          <p className="tickets_show_details" onClick={() => toggle()}>
            Show details
          </p>
          <table className="tickets_table">
            <tbody>
              <tr>
                <td className="table_column">Name</td>
                <td className="table_colon">:</td>
                <td className="table_info">{props.ticket.name}</td>
              </tr>
              <tr>
                <td className="table_column">Date</td>
                <td className="table_colon">:</td>
                <td className="table_info">{moment(props.ticket.post_start_date).format("MMM Do YY")} - {moment(props.ticket.post_end_date).format("MMM Do YY")}</td>
              </tr>
              <tr>
                <td className="table_column">Time</td>
                <td className="table_colon">:</td>
                <td className="table_info">{moment(props.ticket.post_start_time, ["HH.mm"]).format("hh:mm a")}-{moment(props.ticket.post_end_time, ["HH.mm"]).format("hh:mm a")} {props?.ticket.timezone}</td>
              </tr>
              <tr>  
                <td className="table_column">Venue</td>
                <td className="table_colon">:</td>
                <td className="table_info">{props.ticket.location_venue}</td>
              </tr>
             
              <tr>
                <td className="table_column">Quantity</td>
                <td className="table_colon">:</td>
                <td className="table_info">{props.ticket.quantity} {props.ticket?.seats != null && <button type="button" className="view_seats" onClick={() => setToggleSeatModal(true)}>View Seats</button>}</td>
              </tr>
              {props.ticket?.seats != null && <TransitionsModal open={toggleSeatModal} handleClose={() => setToggleSeatModal(false)}>
                <table className="ticket_quantity_popup_table" style={{ width: '90%', margin: 'auto', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ fontSize: '18px', textAlign: 'left' }}>Seat</th>
                      <th style={{ fontSize: '18px', textAlign: 'right' }}>Quantity</th>
                    </tr>
                    <br/>
                  </thead>
                  <tbody>
                    {Object.keys(props?.ticket?.seats).map((ticket, index) => <tr className={Object.keys(props?.ticket?.seats)?.length - 1 === index ? "seats_table_row_undotted" : "seats_table_row" }>
                      <td>{ticket}</td>
                      <td style={{ textAlign: 'right' }}>{props?.ticket?.seats[ticket]}</td>
                    </tr>)}
                  </tbody>
                  </table>
              </TransitionsModal>}
              {props.ticket?.special_categories != null &&
           <tr>
                <td className="table_column">Ticket Type</td>
                <td className="table_colon">:</td>
                <td className="table_info">{props.ticket?.special_categories?.name}</td>
              </tr>}
            </tbody>
          </table>
        </div>
        <QRCode value={props.ticket.barcode}className="ticket_qr_code"  size={ window.innerWidth<=1000 ? .10*window.innerWidth : 120}/>
      </div>
    </>
  );
};
