import React, { useRef, useEffect, useState } from 'react';
import './TicketSale.css';
import { Container } from 'react-bootstrap';

const TicketSale = ({title1, title2, title3}) => {

      const sectionRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      // eslint-disable-next-line
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        // eslint-disable-next-line
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      // Start animation
      sectionRef.current.classList.add('animate');
    } else {
      // Reset animation
      sectionRef.current.classList.remove('animate');
    }
  }, [isIntersecting]);

    return (
        <>
            <section className={`get-wrp highlight ${isIntersecting ? 'animated' : ''}`} id="getkey" ref={sectionRef}>
                <Container>
                {/* {isIntersecting ? ( */}
                    <h1><span>{title1}</span> <span>{title2}</span> <span>{title3}</span></h1>
                    {/* ) : null} */}
                </Container>
            </section>  
        </>
    )
}

export default TicketSale;