import React, { useEffect } from "react";
import "./HomePage.css";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { fetchEventLists } from "../../services/Events.services";
import Banner from "../../components/Comp/Banner/Banner";
import Partners from "../../components/Comp/Partners/Partners";
import TicketSale from "../../components/Comp/TicketSale/TicketSale";
import Steps from "../../components/Comp/EasySteps/Steps";
import Faq from "../../components/Comp/Faq/Faq";
import Event from "../../components/Comp/Event/Event";
import DetailsSection from "../../components/Comp/DetailsSection/DetailsSection";
import {
  Details,
  eventData,
  faqData,
  homeBanner,
  partners,
  stepProcess,
  ticketText,
} from "./Data";

export const HomePage = (props) => {

  useEffect(() => {
    const showsList = async () => {
      try {
       
        const response = await fetchEventLists();
        const data = await response.data;
        if (response.status === 200) {
          const shows = data.data;
          props.pushShows(shows);
          props.changeLinks("home");
        }
       
      } catch (err) {
        console.log(err);
      }
    };
    showsList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const id = window.location.search.split("id=")[1];
    const element = document.getElementById(id);
    if (element) {
      const scrollOffset = 90;

      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - scrollOffset,
          behavior: "smooth",
        });
      }, 100);
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <>
      <Banner {...homeBanner} />
      <Partners {...partners} />
      <TicketSale {...ticketText} />
      <Steps {...stepProcess} />
      <Faq {...faqData} />
      <Event {...eventData} shows={props.shows}/>
      <DetailsSection {...Details} />
    </>
  );
};

const mapStoreToProps = (state) => {
  return {
    shows: state.shows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushShows: (shows) =>
      dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink }),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(HomePage);
