import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "./MyProfile.css";
import DeleteUser from "../../components/DeleteUserModal/DeleteUserModal";
import { isPasswordStrong } from "../../utils/validators";
import { changePasswordRequest, newPasswordRequest } from "../../services/Auth.services";
import { MESSAGES, requiredMsgGenerator, successfulMsgGenerator } from "../../constants/Messages";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";
export const MyProfile = (props) => {
  const history = useHistory();

  const userDetails = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_ENUMS.userData));

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [values, setValues] = useState({
    cPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
  });

  const [passwordVerified, setPasswordVerified] = useState(false);

  React.useEffect(() => {
    props.changeLinks("profile");
    // eslint-disable-next-line
  },[]);

  const logoutHandler = async () => {
      if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.remember)) {
        window.localStorage.removeItem(LOCALSTORAGE_ENUMS.token);
        window.localStorage.removeItem(LOCALSTORAGE_ENUMS.userData);
        window.localStorage.removeItem(LOCALSTORAGE_ENUMS.userID);
        history.push("/");

      } else {
        window.localStorage.clear();
        history.push("/");
      }
  };
  const [loading, setLoading] = useState({
    forPasswordVerification: false,
    forPasswordUpdate: false,
  });

  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };
  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2,
    });
  };
  const handleClickShowPassword3 = () => {
    setValues({
      ...values,
      showPassword3: !values.showPassword3,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "cPassword") {
      setErrors({
        ...errors,
        cPassword: !event.target.value,
      });
    }
    if (prop === "newPassword") {
      setErrors({
        ...errors,
        newPassword:
          !isPasswordStrong(event.target.value),
      });
    }
  };

  const [errors, setErrors] = useState({
    cPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!passwordVerified && values.cPassword) {
      setLoading({ ...loading, forPasswordVerification: true });
      const response = await changePasswordRequest(values);

      setLoading({ ...loading, forPasswordVerification: false });

      const data = await response.data;
      if (response.status === 200) {
        setPasswordVerified(true);
      }
      if (response.status === 403) {
        swal(data.message, "", "error");
      }
    }
    if (
      passwordVerified &&
      isPasswordStrong(values.newPassword) &&
      values.confirmPassword === values.newPassword
    ) {
      setErrors({
        ...errors,
        confirmPassword: false,
        newPassword: false,
        cPassword: false,
      });
      setLoading({ ...loading, forPasswordUpdate: true });
      const newPasswordResponse = await newPasswordRequest(values);
      setLoading({ ...loading, forPasswordUpdate: false });
      const newPasswordData = await newPasswordResponse.data;
      if (newPasswordResponse.status === 200) {
        setErrors({
          ...errors,
          confirmPassword: false,
          newPassword: false,
          cPassword: false,
        });

        setValues({
          ...values,
          cPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setPasswordVerified(false);
        swal(successfulMsgGenerator("Password updated"), "", "success");
      }
      if (newPasswordResponse.status === 403) {
        swal(newPasswordData.message, "", "error");
      }
      if (newPasswordResponse.status === 401) {
        swal(newPasswordData.message, "Please login again", "error");
        setTimeout(() => {
          window.localStorage.clear();
        }, 3000)
      }
    } else if (values.confirmPassword !== values.newPassword) {
      setErrors({ ...errors, confirmPassword: true });
    }
  };

  return (
    <div className="pageDiv">
      <div className="backgroundDiv">
        <div className="firstBackgroundDiv">
          <h1>YOUR PROFILE</h1>
        </div>
      </div>
      <div className="my_profile_div">
        <div className="my_profile_div_sm">
          <p className="profileDetailTag_heading">Name</p>
          <div className="profileDetailTag">
            <p>{userDetails.name}</p>
          </div>
          <p className="profileDetailTag_heading">Email Address</p>
          <div className="profileDetailTag">
            <p>{userDetails.email}</p>
          </div>
          <span className="register_btn_helper_text">*Register here</span>
          <button id="logoutBTN" className="form_input_btn_same_size">
            <a
              href={process.env.REACT_APP_ADMIN_PANEL}
              id="logoutBTN"
              className="saved_cards_btn"
              style={{ textDecoration: "none" }}
              exact
            >
              Create an event
            </a>
          </button>
          <button onClick={logoutHandler} id="logoutBTN" className="form_input_btn_same_size">
            Logout
          </button>
          <div className="change_password_div">
            <span>Password Change</span>
            <div className="change_password_bottom_stroke"></div>
            <Box
              onSubmit={handleSubmit}
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form_input_div">
                <FormControl
                  sx={{ width: "100%" }}
                  color="warning"
                  variant="outlined"
                >
                  <InputLabel
                    style={{ color: errors.cPassword ? "#D10000" : "grey" }}
                    htmlFor="outlined-adornment-cpassword"
                  >
                    Current Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-cpassword"
                    type={values.showPassword1 ? "text" : "password"}
                    value={values.cPassword}
                    style={{ width: "100%" }}
                    onChange={handleChange("cPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword1 ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="cPassword"
                    autoComplete="off"
                    error={errors.cPassword}
                  />
                  {errors.cPassword && (
                    <FormHelperText error id="accountId-error">
                      {values.cPassword.length === 0
                        ? requiredMsgGenerator("Current Password")
                        : MESSAGES.strongPasswordGuidane}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              {passwordVerified ? (
                <>
                  <div className="form_input_div">
                    <FormControl
                      sx={{ width: "100%" }}
                      color="warning"
                      variant="outlined"
                    >
                      <InputLabel
                        style={{
                          color: errors.newPassword ? "#D10000" : "grey",
                        }}
                        htmlFor="outlined-adornment-newpassword"
                      >
                        New Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-newpassword"
                        type={values.showPassword2 ? "text" : "password"}
                        value={values.newPassword}
                        style={{ width: "100%" }}
                        onChange={handleChange("newPassword")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="npasswo"
                        autoComplete="off"
                        error={errors.newPassword}
                      />
                      {errors.newPassword && (
                        <FormHelperText error id="accountId-error">
                          {values.newPassword.length === 0
                            ? requiredMsgGenerator("New Password")
                            : MESSAGES.strongPasswordGuidane}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="form_input_div">
                    <FormControl
                      sx={{ width: "100%" }}
                      color="warning"
                      variant="outlined"
                    >
                      <InputLabel
                        style={{
                          color: errors.confirmPassword ? "#D10000" : "grey",
                        }}
                        htmlFor="outlined-adornment-confirm-password"
                      >
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-confirm-password"
                        type={values.showPassword3 ? "text" : "password"}
                        value={values.confirmPassword}
                        style={{ width: "100%" }}
                        onChange={handleChange("confirmPassword")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword3}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword3 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="cbPassword"
                        autoComplete="off"
                        error={errors.confirmPassword}
                      />
                      {errors.confirmPassword && (
                        <FormHelperText error id="accountId-error">
                          {values.confirmPassword.length === 0
                            ? requiredMsgGenerator("Confirm Password")
                            : "Confirm password does not match with new password."}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </>
              ) : null}
              <div className="form_input_submit_div">
                {loading.forPasswordVerification ||
                  loading.forPasswordUpdate ? (
                  <CircularProgress color="warning" disableShrink />
                ) : !passwordVerified ? (
                  <button type="submit" className="form_input_btn_same_size">
                    Next
                  </button>
                ) : (
                  <button type="submit" className="form_input_btn_same_size">
                    Save changes
                  </button>
                )}
              </div>
            </Box>
          </div>
          <div className="change_password_div">
            <span>Delete Account</span>
            <div className="change_password_bottom_stroke"></div>
            <button onClick={() => setOpenDeleteModal(true)} className="delete_input_btn">
              Delete Account
            </button>
            <DeleteUser open={openDeleteModal} setOpen={setOpenDeleteModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    shows: state.shows
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushShows: (shows) =>
      dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink })

  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(MyProfile);