import React, { useState, useEffect } from "react";
import "../Shows/show.css";
import "../WidgetPage/WidgetPage.css";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { CircularProgress } from "@mui/material";
import { fetchPromotersEventLists } from "../../services/Events.services";
import WidgetVerticalCard from "../../components/WidgetVerticalCard/WidgetVerticalCard";

export const VerticalViewPage = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const promoterId = searchParams.get("promoterId") || undefined;

    useEffect(() => {
        window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
        // eslint-disable-next-line
    }, []);
    const [showsArray, setShowsArray] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        const showsList = async () => {
            try {
                setLoader(true);
                setShowsArray([]);
                const response = await fetchPromotersEventLists(promoterId);
                const data = await response.data;
                const shows = data.data;
                setLoader(false);
                if (response.status === 200) {
                    // props.pushShows(shows);
                    setShowsArray(shows);
                }
            } catch (err) {
                console.log(err);
            }
        };
        if (promoterId)
            showsList();
        // eslint-disable-next-line
    }, [promoterId]);


    return (
        <div className="pageDiv">
            <div className="widget_page_main_div" style={{ marginBottom: "50px" }}>
               
                <div style={{ width: "100%" }}>
                    {loader && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </div>
                    )}

                    {!loader && showsArray.length === 0 && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3>No events found.</h3>
                        </div>
                    )}

                    {showsArray.length > 0 && (
                        <div>
                           
                                        <div className="widget-page-vertical-view" style={{ maxHeight: "100vh" }}>
                                            {showsArray.map((show) => (
                                                <div
                                                    key={show.product_id}
                                                    style={{
                                                        textDecoration: "none",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <WidgetVerticalCard
                                                        key={show.product_id}
                                                        src={show.featured_image}
                                                        link={show?.web_url}
                                                        title={show.formatted_title}
                                                        event_price_range={show?.event_price_range}
                                                        // date={show.post_start_date}
                                                        cat={show?.category_primary}
                                                        categories={show?.special_categories}
                                                        date={show?.event_start_date}
                                                        type={show?.type}
                                                        price={
                                                            show.is_sale
                                                                ? show.ticket_sale_price
                                                                : show.ticket_price_web
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );

};

const mapStoreToProps = (state) => {
    return {
        shows: state.shows,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushShows: (shows) =>
            dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
        changeLinks: (navlink) =>
            dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink })
    };
};

export default connect(mapStoreToProps, mapDispatchToProps)(VerticalViewPage);
