import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../DetailsSection/DetailsSection.css';

const DetailsSection = ({detail, detail2, img, alt }) => {
    return (
        <>
            <div className='details-wrp'  id="section1">
                <Container>
                    <div className='details-main'>
                        <Row className='m-0'>
                            <Col md={5} lg={5} xl={5} xxl={5} className='p-0'>
                                <div className='details-block' style={{marginTop:"50px"}}>
                                    <p style={{textAlign:"center"}}>{detail}</p>
                                    <p style={{textAlign:"center"}}>{detail2}</p>
                                </div>
                            </Col>
                            <Col md={7} lg={7} xl={7} xxl={7} className='p-0'>
                                <div className='details-img'>
                                    <img src={img} alt={alt} className='img-fluid' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default DetailsSection;