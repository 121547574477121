import React from 'react';
import '../Faq/Faq.css';
import Accordion from 'react-bootstrap/Accordion';

const AccordionFAQ = ({ eventKey, accordionHeaderImg, accordionButtonText, accordionBodyText }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
            <span><img src={accordionHeaderImg} alt="img" /></span> {accordionButtonText}
        </Accordion.Header>
        <Accordion.Body>
            <p>{accordionBodyText}</p>
        </Accordion.Body>
    </Accordion.Item>
  )
}

export default AccordionFAQ
