import React, { useEffect, useState } from "react";
import "./TicketPopUp.css";
import QRCode from "react-qr-code";
import moment from "moment";
import dummyImg from '../../mtt-icon.png'
import logo from "./logo2.png"
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

export const TicketPopUp = (props) => {
  const [popupStyle, setPopUpStyle] = useState(props.show);
  useEffect(() => {
    setPopUpStyle(props.show);
  }, [props.show]);
  return (
    <div
      className="ticket_pop_up_backdrop"
      style={popupStyle ? { bottom: "0vh" } : { bottom: "-200vh" }}
    >
      <div className="ticket_popup">
        <div onClick={() => props.setShow(false)}>
        <div className="ticket_popup_img_div" style={{'backgroundImage': `url(${props.ticket.featured_image})`}}>
        <img
          alt="imgALt"
          src={props.ticket.featured_image ? props.ticket.featured_image : dummyImg}
          className="ticket_pop_up_backgroundimg"
          />
          <div className="close_fullscreen" onClick={() => props.setShow(false)}>
          <CloseFullscreenIcon/>
        </div>
          </div>
        <div className="ticket_popup_qr" > 
        <QRCode size={80} value={props.ticket.barcode} />
        </div>
        </div>
        <div className="grid_ticket_popup_mobile">
          <div>
            <p className="grid_ticket_popup_mobile_title">Name</p>
            <p className="grid_ticket_popup_mobile_desc">{props.ticket.name}</p>
          </div>
          <div>
            <p className="grid_ticket_popup_mobile_title">Date</p>
            <p className="grid_ticket_popup_mobile_desc">{moment(props.ticket.post_start_date).format("MMM Do YY")} - {moment(props.ticket.post_end_date).format("MMM Do YY")}</p>
          </div>
          <div>
            <p className="grid_ticket_popup_mobile_title">Time</p>
            <p className="grid_ticket_popup_mobile_desc">{moment(props.ticket.post_start_time, ["HH.mm"]).format("hh:mm a")}-{moment(props.ticket.post_end_time, ["HH.mm"]).format("hh:mm a")} {props?.ticket.timezone}</p>
          </div>
          <div>
            <p className="grid_ticket_popup_mobile_title">Venue</p>
            <p className="grid_ticket_popup_mobile_desc">{props.ticket.location_venue}</p>
          </div>
          <div>
            <p className="grid_ticket_popup_mobile_title">Quantity </p>
            <div style={{ display: 'flex', justifyItems: 'space-between', width: '100%' }}>
            <p className="grid_ticket_popup_mobile_desc" style={{ marginRight: '15px' }} >{props.ticket.quantity}</p>
              {props.ticket?.seats != null && <button type="button" className="view_seats" onClick={props.handleOpen}>View Seats</button>}
            </div>
          </div>
          {props.ticket?.special_categories != null &&
          <div>
            <p className="grid_ticket_popup_mobile_title">Ticket Type </p>
            <p className="grid_ticket_popup_mobile_desc">{props.ticket?.special_categories?.name}</p>
          </div>}
        </div>
        <div className="logo_ticket_popup_div">
          <img src={logo} alt="hi" className="logo_ticket_popup"/>
        </div>
      </div>
    </div>
  );
};
