import React, { useEffect } from 'react'
import { ScannerLoginForm } from '../../components/ScannerAppLogin';

export const DeleteAccount = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <div className="backgroundDiv2">
                <div className="firstBackgroundDiv2">
                    <h1>DELETE YOUR ACCOUNT</h1>
                </div>
                <div className="secondBackgroundDiv2">

                </div>
            </div>
            <div className="my_account_forms_div">
                <ScannerLoginForm />
            </div>
        </div>
    )
}