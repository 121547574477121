import React, { useState } from "react";
import { Box } from "@mui/system";
import "./GuestModeForm.css"
import {
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { isEmailValid, removeExtraSpaces } from "../../utils/validators";
import { invalidMsgGenerator, requiredMsgGenerator } from "../../constants/Messages";
export const GuestModeForm = (props) => {
  const [values, setValues] = useState({
    email: "",
    fname: "",
    lname : "",
    showPassword: false,
  });

  const [errors, setErrors] = useState({
    email: false,
    fname: false,
    lname  : false
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    props.setGuestDetails({...props.guestDetails , [prop] :event.target.value});
    if (prop === "email") {
      setErrors({
        ...errors,
        email:
          !isEmailValid(event.target.value
          ),
      });
      props.setErrors({
        ...props.errors,
        email:
          !isEmailValid(event.target.value
          ),
      });
    }
    if (prop === "fname") {
      setErrors({
        ...errors,
        fname: !removeExtraSpaces(event.target.value)
      });
      props.setErrors({
        ...props.errors,
        fname: !removeExtraSpaces(event.target.value)
      });
    }
    if (prop === "lname") {
      setErrors({
        ...errors,
        lname: !removeExtraSpaces(event.target.value)
      });
      props.setErrors({
        ...props.errors,
        lname: !removeExtraSpaces(event.target.value)
      });
    }
  };

const errorHandler = (e)=>{
  e.preventDefault();
   setErrors({
        ...errors,
        email: !isEmailValid(values.email),
        fname : removeExtraSpaces(values.fname) === "",
        lname : removeExtraSpaces(values.lname) === ""
      });
}


  return (
    <div className="login_contaier">
      <h1 className="login_heading">Continue As Guest</h1>
      <Box
        onSubmit={removeExtraSpaces(values.fname) === "" || removeExtraSpaces(values.lname) === "" || !isEmailValid(values.email) ?  errorHandler : props.guestUserHandler}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div className="form_input_div">
          <TextField
            id="outlined-adornment-name-registration"
            type="text"
            style={{ width: "45%" }}
            value={values.fname}
            onChange={handleChange("fname")}
            label="First Name * "
            color="warning"
            autoComplete="off"
            error={errors.fname}
            helperText={
              values.fname.length < 1 && errors.fname
                ? requiredMsgGenerator("First Name")
                : values.fname && errors.fname
                ? invalidMsgGenerator("First Name")
                : null
            }
          />
          <TextField
            id="outlined-adornment-name-registration"
            type="text"
            style={{ width: "45%" }}
            value={values.lname}
            onChange={handleChange("lname")}
            label="Last Name * "
            color="warning"
            autoComplete="off"
            error={errors.lname}
            helperText={
              values.lname.length < 1 && errors.lname
                ? requiredMsgGenerator("Last Name")
                : values.lname && errors.lname
                  ? invalidMsgGenerator("Last Name")
                : null
            }
          />
        </div>
        <div className="form_input_div">
          <TextField
            id="outlined-adornment-email-registration"
            type="email"
            style={{ width: "100%" }}
            color="warning"
            value={values.email}
            onChange={handleChange("email")}
            label="Email * "
            autoComplete="off"
            error={errors.email}
            helperText={ props.errorMsg ? <p style={{color: "red"}}>{props.errorMsg}</p> :
              errors.email
                ? values.email.length === 0
                  ? requiredMsgGenerator("Email")
                  : invalidMsgGenerator("Email")
                : null
            }
          />
        </div>
        <p className="disabled_info_p">Sign up to delete your guest account</p>
        <div className="form_input_submit_div">
          {props.loading ? (
            <CircularProgress color="warning" />
          ) : (
            <button type="submit" className="swal-button swal-button--guest">
              Proceed to Checkout
            </button>
          )}
        </div>
      </Box>
    </div>
  );
};
