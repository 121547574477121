import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from '@sweetalert/with-react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "./Button.css";
import { GuestModeForm } from "../GuestModeForm/GuestModeForm";
import { SPECIAL } from "../../constants/Events";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { BUTTON } from "../../constants/Buttons";
import { setSpecialCategoriesTickets, transformAndMergeSeatsIO } from "../../utils/helpers";
import { isEmailValid, removeExtraSpaces } from "../../utils/validators";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";
import { createFreeOrder, createFreeSeatsIOOrder } from "../../services/Payment.services";
import { guestLoginRequest } from "../../services/Auth.services";

export const ProceedToPayment = (props) => {

  const history = useHistory();

  const isAuthorized = window.localStorage.getItem(LOCALSTORAGE_ENUMS.token);
  const localStorageUserId = window.localStorage.getItem(LOCALSTORAGE_ENUMS.userID);
  const sessionStorageUserId = window.sessionStorage.getItem(LOCALSTORAGE_ENUMS.userID);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSn, setOpenSn] = useState(false);
  const [guestDetails, setGuestDetails] = useState({
    fname: "",
    lname: "",
    email: ""
  });
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    fname: false,
    lname: false
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    borderRadius: "13px",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 6,
  };

  const isAnyTicketFree = typeof (props.show.purchased_tickets) !== "number" ? props.show.purchased_tickets.filter((ticket) => ticket?.ticket_price_web === 0) : props.show.purchased_tickets;

  const isAnyTicketPaid = typeof (props.show.purchased_tickets) !== "number" ? props.show.purchased_tickets.filter((ticket) => ticket?.ticket_price_web !== 0) : props.show.purchased_tickets;


  const guestUserHandler = async (e) => {
    try {
      e.preventDefault();
      if (isEmailValid(guestDetails.email) && removeExtraSpaces(guestDetails.fname) !== "" && removeExtraSpaces(guestDetails.lname) !== "") {
        setLoading(true);

        const response = await guestLoginRequest(guestDetails);
        const data = await response.data;

        setLoading(false);
        if (response.status === 200) {
          window.sessionStorage.setItem(LOCALSTORAGE_ENUMS.userData, JSON.stringify(data.data.user));
          window.sessionStorage.setItem(LOCALSTORAGE_ENUMS.userID, data.data.user.id);
          window.sessionStorage.setItem(LOCALSTORAGE_ENUMS.guestMode, true);
          window.sessionStorage.setItem(LOCALSTORAGE_ENUMS.strictGuestMode, true);
          if (isAnyTicketFree.length > 0 && isAnyTicketPaid.length === 0){
              setOpen(false);
          }
          else {
          props.type === SPECIAL.free ?
            setOpen(false) :
            history.push(props.checklink)
          }
        }
        else if (response.status === 403) {
          setErrorMsg(data.message);
        }
        else {
          swal("Error", data.message, "error");
        }
      }
      else {
        setErrors({
          ...errors,
          email: !isEmailValid(guestDetails.email),
          fname: removeExtraSpaces(guestDetails.fname) === "",
          lname: removeExtraSpaces(guestDetails.lname) === ""
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const placeOrderHandler = async () => {
    try {
      if (props.show.purchased_tickets.length === 0){
        setOpenSn(true);
        return;
      }
      if (Number(localStorageUserId) || Number(sessionStorageUserId)) {
        setLoader(true);
        let body = {
          "userId": Number(localStorageUserId) || Number(sessionStorageUserId),
          "productId": props.show.product_id
        }
        if(props.holdToken) {
          body.holdToken = props.holdToken?.token;
        }
        if (typeof (props.show.purchased_tickets) === "number") {
          body.quantity = props.show.purchased_tickets;
        }
        else if(props.show.category_primary === SPECIAL.seatsio) {
          const object = {};
          const obj = await props.chartRef?.current?.listSelectedObjects();
          transformAndMergeSeatsIO(obj).forEach((ticket) => {
            const ticketId = ticket.category;
            Object.assign(object, { [ticketId]: ticket.label });
          });
          body.seats = object;
        }
        else {
          body.specialCategories = setSpecialCategoriesTickets(props.show.purchased_tickets.filter((ticket) => ticket.ticket_price_web === 0));
        }

        const response = props.show.category_primary === SPECIAL.seatsio ? await createFreeSeatsIOOrder(body) :  await createFreeOrder(body);

        const data = await response.data;

        setLoader(false);

        if (response.status === 200) {
          setLoader(false);
          swal("Your Ticket was purchased successfully.", data.message, "success");

          if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.guestMode) === "true") {
          
            setTimeout(() => {
              history.replace("/");
              window.localStorage.clear();
            }, 2000)
          
          }
          else {
         
            setTimeout(() => {
              window.location.href="/tickets"
            }, 2000)
          }
        }
        if (response.status === 403) {
          swal("Error", data.message, "error");
          if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.guestMode) === "true") {
            setTimeout(() => {
              history.replace("/");
              window.localStorage.clear();
            }, 2000)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  const proceedToCheckout = async () => {
    if(props.holdToken)
      await props.getSeatsIOObject();
   
    if(isAnyTicketFree.length > 0 && isAnyTicketPaid.length === 0) {
      if (isAuthorized || localStorageUserId || sessionStorageUserId) {
        placeOrderHandler();
      }
      else
        setOpen(true);
        return;
      }
    if (props.type === SPECIAL.free) {
      if (isAuthorized || localStorageUserId || sessionStorageUserId){
        placeOrderHandler(); 
      }
        else
        setOpen(true);
    }
    else if (props.cat === SPECIAL.special && props.show.purchased_tickets.length === 0) {
      setOpenSn(true);
      return;
    }
    else if (props.cat === SPECIAL.seatsio && props.show.purchased_tickets.length === 0) {
      setOpenSn(true);
      return;
    }
    else {
      if(isAuthorized || localStorageUserId || sessionStorageUserId){
        history.push(props.checklink) 
      }
        else
        setOpen(true);
    }
  }
  return (
    <>
      <div
        className="ptp_navlink"
        style={{ textDecoration: "none", color: "orangered", textAlign: "left" }}
        onClick={proceedToCheckout}
      >
        {loader ?
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="warning" /> 
          </div>
          :
          <button className="proceed_to_payment_button">
            {props.type === SPECIAL.free ? BUTTON.PlaceOrder : BUTTON.ProceedToPayment}
          </button>
        }
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <GuestModeForm setErrors={setErrors} errors={errors} errorMsg={errorMsg} setGuestDetails={setGuestDetails} guestDetails={guestDetails} guestUserHandler={guestUserHandler} loading={loading} />
        </Box>
      </Modal>
      <Snackbar
        open={openSn}
        onClose={() => setOpenSn(false)}
        TransitionComponent={'Fade'}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSn(false)} severity="warning" sx={{ width: '100%' }}>
          Please select the ticket type.
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStoreToProps = (state) => {
  return {
    shows: state.shows,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: (id) => dispatch({ type: actionTypes.ADD_ITEMS, item: id }),
    onRemove: (id) => dispatch({ type: actionTypes.REDUCE_ITEMS, item: id }),
    onChangeSpc: (id, spcId) => dispatch({ type: actionTypes.CHANGE_CATEGORY, item: id, spcId: spcId }),
    insertSeatsObject: (id, spcId, seatObj) => dispatch({ type: actionTypes.INSERT_SEATSIO_OBJECT, item: id, spcId, seatObj }),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(ProceedToPayment);