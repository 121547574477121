import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import "./BuyControllers.css";
import ProceedToPayment from "../Button/ProceedToPayment";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { SPECIAL } from "../../constants/Events"
import { OutOfStock } from "../Button/OutOfStock";
import { subtotalPrice, transformAndMergeSeatsIO } from "../../utils/helpers";
export const BuyControllers = (props) => {
  const chart = useRef(null);
  const chartRef = useRef(null);
  const { id, categories, qty } = props;
  const [spcId, setSpcId] = useState([]);
  const [holdToken, setHoldToken] = useState('');
  const [pricing, setPricing] = useState([]);
  const [maxSelectedObjects, setMaxSelectedObjects] = useState([]);

  useEffect(() => {
    if (props.show) {
      if (typeof (props.show.purchased_tickets) === "number")
        setSpcId([])
      else {
        setSpcId(props.show.purchased_tickets?.map(item => item.category))
      }
    }
    // eslint-disable-next-line
  }, [])


  const handleChange = (event) => {
    let events = spcId;
    events.push(event);
    setSpcId([...events]);
    props.onChangeSpc(id, event);
  };

  const handleIncrement = (id, categoryId) => {
    props.onAddCategoryTicket(id, categoryId);
  }

  const handleDecrement = (id, categoryId) => {
    let events = spcId;
    if (props.show.purchased_tickets.find(item => item.category === categoryId).quantity === 1) {
      const index = events.indexOf(categoryId);
      if (index === -1) {
        return;
      }
      events.splice(index, 1);
    }
    setSpcId([...events]);
    props.onRemoveCategoryTicket(id, categoryId);
  }

  const handleSeatsChartSelect = (object) => {
    props.onSeatsChartCategoryChange(id, object.category.key, object.seatId);
  }

  const deselectSeats = (object) => {
    props.onSeatsDeselectChartCategoryChange(id, object.category.key, object.seatId);
  }

  const [link, setLink] = useState(`/checkout/${id}`);

  useEffect(() => {
    if (holdToken) {
      setLink(`/checkout/${id}/${holdToken?.token}`);
    }
    // eslint-disable-next-line 
  }, [holdToken]);

  useEffect(() => {
    if(props.show.category_primary === SPECIAL.seatsio)
    props.resetSeats(id, spcId);
    getPricingAndMaxSelectedObjects();
    // eslint-disable-next-line
  }, []);

  const getPricingAndMaxSelectedObjects = () => {
    let tempPricing = [];
    let tempMaxSelectedObjects = [];
    props.show.special_categories?.forEach(item => {
      if (props?.show?.type !== SPECIAL.free) {
        tempPricing.push({
          category: item.category_key,
          price: item.regular_price,
        });
      }
      tempMaxSelectedObjects.push({
        category: item.category_key,
        quantity: item.max_quantity,
      });
    });
    setPricing(tempPricing);
    setMaxSelectedObjects(tempMaxSelectedObjects);
  };

  const getSeatsObj = async () => {
    const obj = await chart.current?.listSelectedObjects();
    await props.insertSeatsObject(id, spcId, transformAndMergeSeatsIO(obj));
  }

  const isAnyTicketFree = typeof (props.show?.purchased_tickets) !== "number" ? props.show?.purchased_tickets?.filter((ticket) => ticket?.ticket_price_web === 0) : props.show?.purchased_tickets;

  const isAnyTicketPaid = typeof (props.show?.purchased_tickets) !== "number" ? props.show?.purchased_tickets?.filter((ticket) => ticket?.ticket_price_web !== 0) : props.show?.purchased_tickets;

  return (
    <>
      {(props?.show?.type === SPECIAL.free || (props?.show?.category_primary === SPECIAL.special && !spcId)) ? null : <>
        {
          (isAnyTicketFree?.length > 0 && isAnyTicketPaid?.length === 0) ? <span className="show_ticket_price"> Free </span>
        :
        <span className="show_ticket_price">
          ${
            typeof (props.show.purchased_tickets) === "number" ?
              parseFloat(
                props?.show?.ticket_price_web * props?.show?.purchased_tickets
              ).toFixed(2) :
              parseFloat(subtotalPrice(props.show)).toFixed(2)
          }
        </span>}
      </>
      }
      {props.show.category_primary === "seatsio" && <p className="show_ticket_price_detail">Please select the ticket.</p>}
      <div className="show_buy_control_div">
        <div className="show_buy_control">
          {props.cat === SPECIAL.special ?
            <div className="price_level_outer_div">
              {categories.map((category, index) => (
                <div className="category_ticket_div" key={index}>
                  <div className="category_ticket_title_div">
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {category.name}
                      {" "}
                      {category.stock_quantity === 0 && <span style={{ color: 'red' }}> (Sold Out)</span>}
                    </span>
                    {props.show.type === SPECIAL.free ? null :
                      <span style={{ color: '#ff7530', marginLeft: '5px', fontSize: '16px' }}>
                        ${(category.regular_price).toFixed(2)}
                      </span>
                    }
                  </div>
                  {spcId.indexOf(category.id) !== -1 ? <div className="increment_decrement_button_category">
                    <AddIcon
                      className="inc_dec_btn_category"
                      onClick={() => handleIncrement(id, category.id)}
                    />
                    <p className="show_ticket_qty_category">{props.show.purchased_tickets.find(item => item.category === category.id)?.quantity}</p>
                    <RemoveIcon
                      className="inc_dec_btn_category"
                      onClick={() => {
                        handleDecrement(id, category.id);
                      }}
                    />
                  </div> : <div className="increment_decrement_button_category">
                    {category.stock_quantity === 0 ? <div className="sold_btn">
                      <p className="sold_btn_text">
                        Sold Out
                      </p>
                    </div> : <div className="select_btn" onClick={() => handleChange(category.id)}>
                      <p className="select_btn_text">
                        Select
                      </p>
                    </div>}
                  </div>}
                </div>))}
            </div> :
            props.show.category_primary === "seatsio" ?
              null
              : <div className="increment_decrement_button">
                <AddIcon
                  className="inc_dec_btn"
                  onClick={() => props.onAdd(id)}
                />
                <p className="show_ticket_qty">{qty}</p>
                <RemoveIcon
                  className="inc_dec_btn"
                  onClick={() => {
                    props.onRemove(id);
                  }}
                />
              </div>
          }
        </div>
        {props.show.category_primary === "seatsio" &&
          <SeatsioSeatingChart
          workspaceKey={process.env.REACT_APP_SEATS_IO_WORKSPACE}
            event={props?.show?.event_key}
            region="na"
            onChartRendered={newChart => {
              chart.current = newChart;
            }}
            onObjectSelected={handleSeatsChartSelect}
            onObjectDeselected={deselectSeats}
            isObjectVisible={(object, extraConfig) => {
           

              if (Object.keys(extraConfig.seats).includes(object.label)) {
                return true;
              }
             
            return false;
            }
          }
            maxSelectedObjects={maxSelectedObjects}
            canGASelectionBeIncreased={(gaArea, defaultValue, extraConfig) => {
              if (Object.keys(extraConfig.seats).includes(gaArea.label)) {
                return (
                  gaArea.numBooked + gaArea.numSelected <
                  extraConfig.seats[gaArea.label].capacity
                ); //gaArea.capacity;
              }
              return defaultValue;
            }}
            session={'start'}
            pricing={pricing}
            priceFormatter={price => {
              return '$' + price;
            }}
            onSessionInitialized={setHoldToken}
            extraConfig={{ seats: props?.show?.seats }}
            ref={chartRef}
          />
        }
        {
          !props.outOfStock ?
            <ProceedToPayment checklink={link} cat={props.cat} type={props.type} show={props.show} spcId={spcId} holdToken={holdToken} getSeatsIOObject={getSeatsObj} chartRef={chart} />
            : <OutOfStock type={props.type} />
        }
      </div>
    </>
  );
};

const mapStoreToProps = (state) => {
  return {
    shows: state.shows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: (id) => dispatch({ type: actionTypes.ADD_ITEMS, item: id }),
    onRemove: (id) => dispatch({ type: actionTypes.REDUCE_ITEMS, item: id }),
    onAddCategoryTicket: (id, cid) => dispatch({ type: actionTypes.ADD_ITEMS_CATEGORY, item: id, categoryId: cid }),
    onRemoveCategoryTicket: (id, cid) => dispatch({ type: actionTypes.REDUCE_ITEMS_CATEGORY, item: id, categoryId: cid }),
    onChangeSpc: (id, spcId) => dispatch({ type: actionTypes.CHANGE_CATEGORY, item: id, spcId: spcId }),
    onSeatsChartCategoryChange: (id, spcId, seatId) => dispatch({ type: actionTypes.CHANGE_CATEGORY_SEATSIO, item: id, spcId, seatId }),
    onSeatsDeselectChartCategoryChange: (id, spcId, seatId) => dispatch({ type: actionTypes.DESELECT_CATEGORY_SEATSIO, item: id, spcId, seatId }),
    resetSeats: (id, spcId) => dispatch({ type: actionTypes.RESET_SEATSIO_TICKETS, item: id, spcId: spcId }),
    insertSeatsObject: (id, spcId, seatObj) => dispatch({ type: actionTypes.INSERT_SEATSIO_OBJECT, item: id, spcId, seatObj }),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(BuyControllers);