import React, { useState } from "react";
import "./PaymentMethodController.css";
import paypalLogo from "./paypallogo.png";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import stripeLogo from "./stripelogo.png";
import swal from "sweetalert";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { StripeCheckout } from "../StripeForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { setSpecialCategoriesTickets } from "../../utils/helpers";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";
import { createFreeOrder, createFreeSeatsIOOrder, makePaymentRequest, makePaymentRequestForSeatsIO } from "../../services/Payment.services";
import TransitionsModal from "../Modal";
import { SPECIAL } from "../../constants/Events";
import { useHistory } from "react-router-dom";

export const PaymentMethodController = (props) => {

  const localStorageUserId = window.localStorage.getItem(LOCALSTORAGE_ENUMS.userID);
  const sessionStorageUserId = window.sessionStorage.getItem(LOCALSTORAGE_ENUMS.userID);
  const history = useHistory();
  const [optionsStripe, setOptionsStripe] = useState(undefined);
  const [stripePromise, setStripePromise] = useState(null);
  const [empheralKey, setEmpheralKey] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [freeTicketsPurchase, setFreeTicketsPurchase] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({
    paypal: true,
    stripe: false,
  });
  const [loader, setLoader] = useState(false);
  const isAnyTicketFree = typeof (props.show.purchased_tickets) !== "number" ? props.show.category_primary === SPECIAL.seatsio ? props.show.seatsio_tickets.filter((ticket) => ticket?.ticket_price_web === 0) : props.show.purchased_tickets.filter((ticket) => ticket?.ticket_price_web === 0) : props.show.purchased_tickets;

  const handleChange = (event) => {
    if (event.target.name === "stripe") {
      setPaymentMethod({
        ...paymentMethod,
        stripe: true,
        paypal: false,
      });
    }
    if (event.target.name === "paypal") {
      setPaymentMethod({
        ...paymentMethod,
        stripe: false,
        paypal: true,
      });
    }
  };

  const placeFreeOrderHandler = async (moveFurther = false) => {
    try {
      if (Number(localStorageUserId) || Number(sessionStorageUserId)) {
        setFreeTicketsPurchase(true);
        let body = {
          "userId": Number(localStorageUserId) || Number(sessionStorageUserId),
          "productId": props.show.product_id
        }
        if (props.holdToken) {
          body.holdToken = props.holdToken?.token;
        }
        if (typeof (props.show.purchased_tickets) === "number") {
          body.quantity = props.show.purchased_tickets;
        }
        else if (props.show.category_primary === SPECIAL.seatsio) {
          body.holdToken = props.holdToken;
          const object = {};
          (isAnyTicketFree).forEach((ticket) => {
            const ticketId = ticket.category;
            Object.assign(object, { [ticketId]: ticket.label });
          })
          body.seats = object;
        }
        else {
          body.specialCategories = setSpecialCategoriesTickets(props.show.purchased_tickets.filter((ticket) => ticket.ticket_price_web === 0));
        }

        const response = props.show.category_primary === SPECIAL.seatsio ? await createFreeSeatsIOOrder(body) : await createFreeOrder(body);

        const data = await response.data;

        setFreeTicketsPurchase(false);

        if (response.status === 200) {
          setFreeTicketsPurchase(false);
          if(props.show.purchased_tickets.filter((ticket) => ticket.ticket_price_web !== 0).length > 0)
          placeOrderHandler();
          else{
            swal("Your Ticket was purchased successfully.", data.message, "success").then(() => {
              if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.guestMode) === "true") {
                if (!moveFurther) {
                  setTimeout(() => {
                    history.replace("/");
                    window.localStorage.clear();
                  }, 2000)
                }
              }
              else {
                if (!moveFurther) {
                  setTimeout(() => {
                    history.replace("/tickets");
                  }, 2000)
                }
              }
            });
          }
        }
        if (response.status === 403) {
          swal("Error", data.message, "error");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  const placeOrderHandler = async () => {
    if (paymentMethod.paypal) {
      try {
        setLoader(true);
        const body = {
          "userId": localStorageUserId || sessionStorageUserId,
          "paymentMethod": "PayPal",
          "productId": props.ticket.product_id,
          "returnUrl": `${window.location.origin}/successpayment/details?method=PayPal`,
          "cancelUrl": `${window.location.origin}/payment-failed`
        };
        if (typeof (props.totalTickets) === "number") {
          body.quantity = props.totalTickets;
        }
        else if (props.holdToken) {
          body.holdToken = props.holdToken;
          const object = {};
          (props.show.seatsio_tickets.filter((ticket) => ticket?.ticket_price_web > 0)).forEach((ticket) => {
            const ticketId = ticket.category;
            Object.assign(object, { [ticketId]: ticket.label });
          })
          body.seats = object;
        }
        else if (typeof (props.totalTickets) !== "number") {
          body.specialCategories = setSpecialCategoriesTickets(props.totalTickets);
        }
        const response = props.holdToken ? await makePaymentRequestForSeatsIO(body) : await makePaymentRequest(body);
        const data = await response.data;
        if (response.status === 200) {
          const { link } = data.intent.payload
          window.open(link, "_self");
        }
        setLoader(false);
        if (response.status === 401) {
          swal(data.message, "Please login again", "error");
          setTimeout(() => {
            window.localStorage.clear();
          }, 3000)
        }
        if (response.status === 403) {
          swal("Error", data.message, "error");
        }
      } catch (err) {
        console.log(err);
        swal("Error", err?.response?.data?.message, "error");
      }
    }else{
      fetchStripeKey();
    }
  };

  const fetchStripeKey = async () => {
    try {
      setLoader(true);
      const body = {
        "userId": localStorageUserId || sessionStorageUserId,
        "paymentMethod": "Stripe",
        "productId": props.ticket.product_id,
      };
      if (typeof (props.totalTickets) === "number") {
        body.quantity = props.totalTickets;
      }
      if (props.holdToken) {
        body.holdToken = props.holdToken;
        const object = {};
        (props.ticket.seatsio_tickets).forEach((ticket) => {
          const ticketId = ticket.category;
          Object.assign(object, { [ticketId]: ticket.label });
        })
        body.seats = object;
      }
      else if (typeof (props.totalTickets) !== "number") {
        body.specialCategories = setSpecialCategoriesTickets(props.totalTickets);
      }
      const response = props.holdToken ? await makePaymentRequestForSeatsIO(body) : await makePaymentRequest(body);
      const data = await response.data;
      setLoader(false);
      if (response.status === 200) {
        const { paymentIntent, publishableKey, ephemeralKey, customer } = data.intent.payload
        setOptionsStripe(paymentIntent);
        setStripePromise(loadStripe(publishableKey));
        setEmpheralKey(ephemeralKey);
        setCustomerId(customer);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="payment_method_div">
      <Box sx={{ display: "flex" }}>
        <FormControl
          sx={{ m: 3 }}
          component="fieldset"
          color="warning"
          variant="standard"
        >
          <FormLabel component="legend" color="warning">
            Choose Payment Methods
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Radio
                  checked={paymentMethod.paypal}
                  onChange={handleChange}
                  name="paypal"
                  color="warning"
                />
              }
              label={
                <>
                  <span className="payment_method_radio">
                    PayPal -
                    <img width="80px" alt="stripe - logo" src={paypalLogo} />
                  </span>
                </>
              }
            />
            <FormControlLabel
              control={
                <Radio
                  checked={paymentMethod.stripe}
                  onChange={handleChange}
                  name="stripe"
                  color="warning"
                />
              }
              label={
                <>
                  <span className="payment_method_radio">
                    Credit Card -
                    <img width="80px" alt="stripe - logo" src={stripeLogo} />
                  </span>
                </>
              }
            />
          </FormGroup>
        </FormControl>
      </Box>
      <TransitionsModal open={paymentMethod.stripe === true && optionsStripe !== undefined} handleClose={() => setOptionsStripe(undefined)}>
        {optionsStripe === undefined ?
          <>
            <div>
              <CircularProgress color="warning" />
              <p style={{ fontWeight: "bold", color: '#ed6c02', paddingBottom: '15px' }}>Stripe is loading...</p>
            </div>
            <div className="payment_placeorder_btn_div" />
          </>
          : <>
            <div style={{ width: '50%', margin: 'auto', minWidth: '300px' }}>
              <Elements options={{ clientSecret: optionsStripe }} stripe={stripePromise}>
                <StripeCheckout clientSecret={optionsStripe} returnUrl={`method=Stripe&customer=${customerId}&token=${empheralKey}`} />
              </Elements>
            </div>
          </>}
      </TransitionsModal>
        <div className="payment_placeorder_btn_div">
          {(loader || freeTicketsPurchase) ? (
            <CircularProgress color="warning" />
          ) : (
            <button onClick={() => {isAnyTicketFree.length > 0 ? placeFreeOrderHandler() : placeOrderHandler()}} className="placeorder_btn">
              Place Order
            </button>
          )}
        </div>
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    navbar: state.mobileNavbar,
    shows: state.shows
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink }),
  };
};


export default connect(mapStoreToProps, mapDispatchToProps)(PaymentMethodController);