import React, { useState, useEffect, useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import "../Shows/show.css";
import "./WidgetPage.css";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import {  fetchPromotersAPI, fetchPromotersEventLists } from "../../services/Events.services";
import WidgetHorizontalCard from "../../components/WidgetHorizontalCard/WidgetHorizontalCard";
import WidgetVerticalCard from "../../components/WidgetVerticalCard/WidgetVerticalCard";
import { CalendarView } from "../../components/CalendarView/CalendarView";

export const WidgetPage = (props) => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const promoterId = searchParams.get("promoterId") || undefined;
    const [isExpanded, setIsExpanded] = useState(false);
    const [promotersList, setPromotersList] = useState([]);

    useEffect(() => {
        const fetchOperators = async () => {
            try {
                const response = await fetchPromotersAPI();
                const data = await response.data;
                setPromotersList(data.data);
            } catch (err) {
                console.log(err)
            }
        };
        fetchOperators();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
        // eslint-disable-next-line
    }, []);
    const [showsArray, setShowsArray] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        const showsList = async () => {
            try {
                setLoader(true);
                setShowsArray([]);
                const response =   await fetchPromotersEventLists(promoterId);
                const data = await response.data;
                const shows = data.data;
                setLoader(false);
                if (response.status === 200) {
                    // props.pushShows(shows);
                    setShowsArray(shows);
                }
            } catch (err) {
                console.log(err);
            }
        };
        if(promoterId)
        showsList();
        // eslint-disable-next-line
    }, [promoterId]);

    const handleChangePromoter = (e) => {
        if (e)
            history.replace(`/widget?promoterId=${e || ""}`);
        else
            history.replace(`/widget`);
    };

    const [horizontalView, setHorizontalView] = React.useState(false);
    const [verticalView, setVerticalView] = React.useState(false);
    const [calendarView, setCalendarView] = React.useState(false);


    const displayedPromoters = isExpanded ? promotersList : promotersList.slice(0, 5);

    const sortedPromoters = useMemo(() => {
        return [...displayedPromoters].sort((a, b) => {
            if (a.id === Number(promoterId)) return -1;
            if (b.id === Number(promoterId)) return 1;
            return 0;
        });
    }, [displayedPromoters, promoterId]);

    return (
        <div className="pageDiv">
            <div className="backgroundDiv2">
                <div className="firstBackgroundDiv2">
                    <h1>WIDGETS</h1>
                </div>
            </div>
            <div className="widget_page_main_div">
                <h2>Widget Preview</h2>
            </div>
            <div className="widget_page_main_div" style={{ marginBottom: "50px" }}>
                <div style={{ width: "25%", maxWidth: "300px" }}>
                    <div className="widget-filter-card-div">
                        <h4>View</h4>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={horizontalView}
                                    color="warning"
                                    onChange={(e) => setHorizontalView(!horizontalView)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label="Horizontal View"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    color="warning"
                                    checked={verticalView}
                                    onChange={(e) => setVerticalView(!verticalView)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label="Vertical View"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={calendarView}
                                    color="warning"
                                    onChange={(e) => setCalendarView(!calendarView)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label="Calendar View"
                            />
                        </FormGroup>
                    </div>
                    <br />
                    <div className="widget-filter-card-div">
                        <FormControl>
                            <h4>Promoters</h4>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={Number(promoterId)}
                                name="radio-buttons-group"
                                onChange={(e) => handleChangePromoter(e.target.value)}
                            >
                                {sortedPromoters?.map((promoter) => (
                                    <FormControlLabel color="warning" key={promoter.id} value={promoter.id} control={<Radio color="warning" />} label={promoter.name} />
                                ))}
                                {!isExpanded && promotersList.length > 5 && (
                                    <div onClick={() => setIsExpanded(true)} style={{ color: "#ff7530", fontWeight: "600", cursor: "pointer" }}>
                                        + {promotersList.length - 5} more
                                    </div>
                                )}
                                {isExpanded && (
                                    <div onClick={() => setIsExpanded(false)} style={{ color: "#ff7530", fontWeight: "600", cursor: "pointer" }}>
                                        Show less
                                    </div>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div style={{ width: "75%" }}>
                    {loader && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </div>
                    )}

                    {!loader && showsArray.length === 0 && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3>{promoterId ? "No events found." : "Please select a promoter"}</h3>
                        </div>
                    )}

                    {horizontalView && showsArray.length > 0 && (
                        <div>
                            <h2 style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginTop: "25px" }}>Horizontal View</h2>

                        <div className="widget-page-horizontal-view">
                            {showsArray.map((show) => (
                                <div
                                    key={show.product_id}
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        justifyContent: "center",
                                        height: "100%"
                                    }}
                                >
                                    <WidgetHorizontalCard
                                        key={show.product_id}
                                        src={show.featured_image}
                                        title={show.formatted_title}
                                        link={show?.web_url}
                                        event_price_range={show?.event_price_range}
                                        // date={show.post_start_date}
                                        cat={show?.category_primary}
                                        categories={show?.special_categories}
                                        date={show?.event_start_date}
                                        type={show?.type}
                                        price={
                                            show.is_sale
                                                ? show.ticket_sale_price
                                                : show.ticket_price_web
                                        }
                                    />
                                </div>
                            ))}
                            </div>
                        </div>
                    )}

                    {verticalView && showsArray.length > 0 && (
                        <div>
                            <h2 style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginTop: "25px" }}>Vertical View</h2>
                        <div className="widget-page-vertical-view">
                            {showsArray.map((show) => (
                                <div
                                    key={show.product_id}
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <WidgetVerticalCard
                                        key={show.product_id}
                                        src={show.featured_image}
                                        link={show?.web_url}
                                        title={show.formatted_title}
                                        event_price_range={show?.event_price_range}
                                        // date={show.post_start_date}
                                        cat={show?.category_primary}
                                        categories={show?.special_categories}
                                        date={show?.event_start_date}
                                        type={show?.type}
                                        price={
                                            show.is_sale
                                                ? show.ticket_sale_price
                                                : show.ticket_price_web
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                        </div>
                    )}

                    {calendarView && showsArray.length > 0 && (
                        <div>
                            <h2 style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginTop: "25px" }}>Calendar View</h2>

                        <CalendarView promoterId={promoterId} events={showsArray || []} />
                        </div>
                    )}

                    {promoterId && !horizontalView && !verticalView && !calendarView && showsArray.length > 0 && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h3>Please select any view.</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

};

const mapStoreToProps = (state) => {
    return {
        shows: state.shows,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushShows: (shows) =>
            dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
        changeLinks: (navlink) =>
            dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink })
    };
};

export default connect(mapStoreToProps, mapDispatchToProps)(WidgetPage);
