export const MESSAGES = {
    strongPasswordGuidane : "Password must be at least 8 characters long and should contain uppercase and lowercase letters, special characters and numbers.",
    TermsAndCondition: "Please accept our Terms of Services and Privacy Policy."
}

export const requiredMsgGenerator = (value) => `${value} field is required.`;
export const invalidMsgGenerator = (value) => `${value} field is invalid.`;

export const successfulMsgGenerator = (value) => `${value} is successful !`;
export const failedMsgGenerator = (value) => `${value} failed !`;
