import instance from "../interceptor/AxiosInstance";

export const fetchEventLists = async () => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/products-web/list?upcoming=true&filter=published&latitude=&longitude=`,
        {
            method: 'GET'
        }
    );
    return response;
}

export const fetchPromotersEventLists = async (id) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/products/widgets-list?promoter_id=${id}`,
        {
            method: 'GET'
        }
    );
    return response;
}


export const fetchEventById = async (id) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/product-web/${id}`,
        {
            method: 'GET'
        }
    );
    return response;
}

export const fetchCities = async () => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/cities`,
        {
            method: 'GET'
        }
    );
    return response;
}

export const fetchPromotersAPI = async () => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/operators`,
        {
            method: 'GET'
        }
    );
    return response;
}