import React from 'react';
import '../Banner/Banner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';

const Banner = ({ title, detail, btn , bannerVideo}) => {
    return (
        <>
            <section className="hero-banner">
                <div className="videofrem">
                    <video width="100%" playsInline muted autoPlay loop>
                        <source src={`${bannerVideo}`} type="video/mp4" />
                    </video>
                </div>

                <div className="banner-center">
                    <Container>
                        <div className="banner-data">
                            <h1>{title}</h1>
                            <p>{detail}</p>
                            <a href={process.env.REACT_APP_ADMIN_PANEL} className="btn-main"><span>{btn}</span> <FontAwesomeIcon icon={faArrowRight} /></a>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Banner;