import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Container, Row , Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import '../Faq/Faq.css';
import { AccordionObjOne, AccordionObjTwo, AccordionObjThree, AccordionObjFour, AccordionObjFive, AccordionObjSix, AccordionObjSeven } from './Data';
import AccordionFAQ from './AccordionFAQ';

const Faq = ({ faqleftimg, faqmaintitle, faqtext, faqBtn }) => {
    return (
        <>
            <section className="moon-tower-wrp">
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="moon-tower-img">
                                <img src={faqleftimg} alt="img" />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="moon-tower-data">
                                <h2 >{faqmaintitle}</h2>
                                <h6>{faqtext}</h6>

                                <div className="moon-tower-accordian">
                                    <Accordion defaultActiveKey="0">
                                        <AccordionFAQ {...AccordionObjOne} />
                                        <AccordionFAQ {...AccordionObjTwo} />
                                        <AccordionFAQ {...AccordionObjThree} />
                                        <AccordionFAQ {...AccordionObjFour} />
                                        <AccordionFAQ {...AccordionObjFive} />
                                        <AccordionFAQ {...AccordionObjSix} />
                                        <AccordionFAQ {...AccordionObjSeven} />
                                    </Accordion>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <a href={process.env.REACT_APP_ADMIN_PANEL} className="btn-main"><span>{faqBtn}</span> <FontAwesomeIcon icon={faArrowRight}/></a>
                </Container>
            </section>
        </>
    )
}

export default Faq;