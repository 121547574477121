import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../Event/Event.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Eventblock from "./Eventblock";
// import {
//   EventSlideOne,
//   EventSlideTwo,
//   EventSlideThree,
//   EventSlideFour,
//   EventSlideFive,
//   EventSlideSix,
// } from "./Data";
import { Container } from "react-bootstrap";

const PrevArrow = ({ onClick }) => (
  <div className="slick-prev slick-arrow" onClick={onClick}>
    <FontAwesomeIcon icon={faArrowLeft} />
  </div>
);

const NextArrow = ({ onClick }) => (
  <div className="slick-next slick-arrow" onClick={onClick}>
    <FontAwesomeIcon icon={faArrowRight} />
  </div>
);

const Event = ({ eventTitle, eventText, shows }) => {

  var settings = {
    arrows: true,
    loop: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 1500,
    speed: 1000,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: "event-slider",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 447,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="current-event-wrp">
      <div className="container">
        <div className="current-title">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h2>{eventTitle}</h2>
            </div>
            {/* <div className="col-md-6 col-sm-4">
              <p>{eventText}</p>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="event-slider-row">
                <Container fluid>
                    <Slider {...settings}>
                        <Eventblock {...EventSlideOne} />
                        <Eventblock {...EventSlideTwo} />
                        <Eventblock {...EventSlideThree} />
                        <Eventblock {...EventSlideFour} />
                        <Eventblock {...EventSlideFive} />
                        <Eventblock {...EventSlideSix} />
                    </Slider>
                </Container>
            </div> */}

      {/* create map function to display the events */}

      <div className="event-slider-row">
        <Container fluid>
          <Slider {...settings}>
            {shows?.slice(0,5)?.map((show) => (
              <Eventblock
                key={show.product_id}
                eventDate={show.post_start_date}
                eventTitle={show.formatted_title}
                eventText={show.formatted_content_web}
                cat={show?.category_primary}
                categories={show?.special_categories}
                type={show?.type}
                price={
                  show.is_sale
                    ? show.ticket_sale_price
                    : show.ticket_price_web
                }
                eventImg={show.featured_image}
                eventLink={`/showspage/${show.product_id}`}
                eventBtnText="View Event"
                shows={shows}

            
              />
            ))}
          </Slider>
        </Container>
      </div>
    </section>
  );
};

export default Event;
