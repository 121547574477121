import React, { useState } from "react";
import "./RegisterForm.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { deepOrange } from '@mui/material/colors';
import { Box } from "@mui/system";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { NavLink } from "react-router-dom";
import { isEmailValid, isPasswordStrong, removeExtraSpaces } from "../../utils/validators";
import { LOCALSTORAGE_ENUMS } from "../../constants/localStorageEnums";
import { registerUser } from "../../services/Auth.services";
import { failedMsgGenerator, invalidMsgGenerator, MESSAGES, requiredMsgGenerator, successfulMsgGenerator } from "../../constants/Messages";

export const RegisterForm = () => {

  const history = useHistory();
  const [accept, setAccept] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    fname: "",
    lname: "",
    showPassword: false,
  });
  const [tcError, setTcError] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    fname: false,
    lname: false
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "email") {
      setErrors({
        ...errors,
        email:
          !isEmailValid(event.target.value),
      });
    }
    if (prop === "password") {
      setErrors({
        ...errors,
        password: !isPasswordStrong(event.target.value),
      });
    }
    if (prop === "fname") {
      setErrors({
        ...errors,
        fname: !event.target.value
      });
    }
    if (prop === "lname") {
      setErrors({
        ...errors,
        lname: !event.target.value
      });
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (accept === true) {
        delete values.showPassword;
        const { email, password, fname, lname } = values;
        if (
          isEmailValid(email) &&
          isPasswordStrong(password) &&
          removeExtraSpaces(fname) && removeExtraSpaces(lname)
        ) {
          setLoading(true);

          const response = await registerUser(email, password, fname, lname);
          setLoading(false)
          const data = await response.data;

          if (response.status === 200) {
            window.sessionStorage.clear();
            window.localStorage.setItem(LOCALSTORAGE_ENUMS.userData, JSON.stringify(data.data.user));
            window.localStorage.setItem(LOCALSTORAGE_ENUMS.userID, data.data.user.id);
            window.localStorage.setItem(LOCALSTORAGE_ENUMS.guestMode, "true");
            window.localStorage.setItem(LOCALSTORAGE_ENUMS.strictGuestMode, "false");
            if (window.localStorage.getItem(LOCALSTORAGE_ENUMS.checkout) === "true")
              history.goBack();
            else
              history.push("/");
            swal({
              title: successfulMsgGenerator("Registration"),
              text: data.message,
              icon: "success",
            });
            setValues({ fname: "", lname: "", email: "", password: "" });
            setErrors({ email: false, password: false, name: false });
          }
          if (response.status === 403) {
            swal({
              title: failedMsgGenerator("Registration"),
              text: data.message,
              icon: "error",
            });
          }
        } else {
          setErrors({
            ...errors,
            email: !isEmailValid(values.email),
            fname: !removeExtraSpaces(values.fname),
            lname: !removeExtraSpaces(values.lname),
            password: !isPasswordStrong(values.password),
          });
        }
      }
      else {
        setErrors({
          ...errors,
          email:
            !isEmailValid(values.email),
          fname: !removeExtraSpaces(values.fname),
          lname: !removeExtraSpaces(values.lname),
          password: !isPasswordStrong(values.password),
        });
        setTcError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="login_container">
      <h1 className="login_heading">Register</h1>
      <Box
        onSubmit={handleSubmit}
        component="form"
        noValidate
        autoComplete="off"
      >
        <div className="form_input_div">
          <TextField
            id="outlined-adornment-name-registration"
            type="text"
            style={{ width: "45%" }}
            value={values.fname}
            onChange={handleChange("fname")}
            label="First Name * "
            color="warning"
            autoComplete="off"
            error={errors.fname}
            helperText={
              values.fname.length < 1 && errors.fname
                ? requiredMsgGenerator("First Name")
                : values.fname && errors.fname
                  ? invalidMsgGenerator("First Name")
                  : null
            }
          />
          <TextField
            id="outlined-adornment-name-registration"
            type="text"
            style={{ width: "45%" }}
            value={values.lname}
            onChange={handleChange("lname")}
            label="Last Name * "
            color="warning"
            autoComplete="off"
            error={errors.lname}
            helperText={
              values.lname.length < 1 && errors.lname
                ? requiredMsgGenerator("Last Name")
                : values.lname && errors.lname
                  ? invalidMsgGenerator("Last Name")
                  : null
            }
          />
        </div>
        <div className="form_input_div">
          <TextField
            id="outlined-adornment-email-registration"
            type="email"
            style={{ width: "100%" }}
            color="warning"
            value={values.email}
            onChange={handleChange("email")}
            label="Email * "
            autoComplete="off"
            error={errors.email}
            helperText={
              errors.email
                ? values.email.length === 0
                  ? requiredMsgGenerator("Email")
                  : invalidMsgGenerator("Email")
                : null
            }
          />
        </div>
        <div className="form_input_div">
          <FormControl
            sx={{ width: "100%" }}
            color="warning"
            variant="outlined"
          >
            <InputLabel
              style={{ color: errors.password ? "#D10000" : "grey" }}
              htmlFor="outlined-adornment-password-registration"
            >
              Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-registration"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              style={{ width: "100%" }}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors.password}
              label="Password * "
              autoComplete="off"
            />
            {errors.password && (
              <FormHelperText error id="accountId-error">
                {values.password.length === 0
                  ? requiredMsgGenerator("Password")
                  : MESSAGES.strongPasswordGuidane}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <p className="register_policy">
          <Checkbox
            style={{ padding: '4px', paddingLeft: '0px' }}
            checked={accept}
            onClick={() => setAccept(!accept)}
            sx={{
              color: deepOrange[800],
              '&.Mui-checked': {
                color: deepOrange[600],
              },
            }}
          />
          I agree to the
          <NavLink to="/terms-and-conditions" className="forget_password_link">
            Terms and Services
          </NavLink> and <NavLink to="/privacy-policy" className="forget_password_link">
            Privacy Policy
          </NavLink>
          .
        </p>
        {tcError && !accept ? <p style={{ color: '#d22f2e', fontSize: '14px', marginTop: '5px', textAlign: 'left' }}>{MESSAGES.TermsAndCondition}</p> : null}
        <div className="form_input_submit_div">
          {loading ? (
            <CircularProgress color="warning" />
          ) : (
            <button type="submit" className="form_input_btn">
              Register
            </button>
          )}
        </div>
      </Box>
    </div>
  );
};
