import { Calendar } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './CalendarView.css'; // Import your custom CSS
import eventImg from '../../mtt-icon.png'; // Replace with the actual path to your event image
import moment from 'moment';
import { Popper } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

let useClickOutside = (handler) => {
    let domNode = useRef();

    useEffect(() => {
        let maybeHandler = (event) => {
            if (!domNode.current?.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });

    return domNode;
};

function getEventList(date, events, promoterId) {
    const formattedDate = moment(date).format('MM/DD/YYYY'); // Format the current cell date

    if (!promoterId) {
        return events.filter(event => {
            const eventDate = moment(event.post_start_date).format('MM/DD/YYYY');
            return eventDate === formattedDate;
        });
    }

    return events.filter(event => {
        const startDate = moment(event.start_date_time, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY');
        return startDate === formattedDate;
    });
}

export const CalendarView = ({ events, promoterId = null }) => {
    let searchRefNode = useClickOutside(() => {
        setAnchorEl(null);
        setSelectedItem(null);
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState({});

    const handleClick = (event, item) => {
        if (!item) return;
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setSelectedItem(item?.formatted_title === selectedItem?.formatted_title ? null : item);
    };

    useEffect(() => {
        // Hide the row with aria-rowindex="6"
        const rowToHide = document.querySelector('[aria-rowindex="6"]');
        if (rowToHide) {
            rowToHide.style.display = 'none';
        }
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function renderCell(date) {
        const list = getEventList(date, events, promoterId);
        const isMultipleEvents = list.length > 1;

        if (list.length) {
            return (
                <div className="custom-calendar-cell" ref={searchRefNode}>
                    {list.map((item, index) => (
                        <div key={index}>
                            {!isMultipleEvents && (
                                <img
                                    src={item?.featured_image || eventImg}
                                    alt={item.formatted_title}
                                    style={{ width: "100%", height: "60px", borderRadius: "5px", objectFit: "cover" }}
                                />
                            )}
                            <div
                                onClick={(e) => handleClick(e, item)}
                                style={{
                                    borderLeft: `2px solid ${!isMultipleEvents ? "transparent" : "#F54C16"}`,
                                    marginBottom: "10px"
                                }}
                            >
                                <span>
                                    {moment(item?.post_start_date || item.start_date_time, "MM/DD/YYYY HH:mm").format("hh:mm a")} -
                                    {moment(item?.post_end_date || item.end_date_time, "MM/DD/YYYY HH:mm").format("hh:mm a")}
                                </span>
                                <strong>
                                    <p style={{ color: "#7F7F7F", borderLeft: `2px solid ${isMultipleEvents ? "transparent" : "#F54C16"}` }}>
                                        {item.formatted_title}
                                    </p>
                                </strong>
                            </div>
                        </div>
                    ))}
                    <Popper
                        ref={searchRefNode}
                        id={id}
                        open={open}
                        placement="top"
                        anchorEl={anchorEl}
                        style={{ backgroundColor: "#fff", zIndex: "999", boxShadow: "0 0 0 5px #ccc", borderRadius: "5px", padding: "10px", maxWidth: "200px", minWidth: "200px" }}
                    >
                        <img
                            src={selectedItem?.featured_image || eventImg}
                            alt={selectedItem?.formatted_title}
                            style={{ width: "100%", height: "60px", borderRadius: "5px", objectFit: "cover", marginBottom: "10px" }}
                        />
                        <div style={{ borderLeft: `2px solid ${!isMultipleEvents ? "transparent" : "#F54C16"}`, marginBottom: "10px" }}>
                            <span style={{ paddingLeft: "5px", fontSize: "10px", color: "#A4A4A4" }}>
                                {moment(selectedItem?.post_start_date || selectedItem?.start_date_time, "MM/DD/YYYY HH:mm").format("hh:mm a")} -
                                {moment(selectedItem?.post_end_date || selectedItem?.end_date_time, "MM/DD/YYYY HH:mm").format("hh:mm a")}

                            </span>
                            <strong>
                                <p style={{ borderLeft: `2px solid ${isMultipleEvents ? "transparent" : "#F54C16"}`, paddingLeft: "5px", paddingBottom: "0", marginBottom: "0" }}>
                                    {selectedItem?.formatted_title}
                                </p>
                            </strong>
                            <strong>
                                <p style={{ borderLeft: `2px solid ${isMultipleEvents ? "transparent" : "#F54C16"}`, paddingLeft: "5px", color: "#ff7530", margin: "0" }}>
                                    {selectedItem?.event_price_range}
                                </p>
                            </strong>
                        </div>
                        <button
                            style={{ backgroundColor: "#ff7530", borderRadius: "5px", height: "30px", width: "100%", fontSize: "12px", color: "#fff" }}
                            onClick={() => window.open(selectedItem?.web_url, "_blank")}
                        >
                            Buy tickets
                        </button>
                    </Popper>
                </div>
            );
        }
        return null;
    }

    return <Calendar bordered renderCell={renderCell} />;
};
