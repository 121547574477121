import React from 'react';
import "./Verification.css"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { MobileView } from 'react-device-detect';
import { fetchVerificationStatus } from '../../services/Auth.services';

export const Verification = () => {
  let url = useLocation();
  const [status, setStatus] = useState("loading");
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const verifier = async () => {
      try {
        const response = await fetchVerificationStatus(url);
        const data = await response.data;
        setStatus(data.status);
      } catch (err) {
        console.log(err)
      }
    }
    verifier();
    // eslint-disable-next-line
  }, [url])


  return <div className="pageDiv">
    <div className="backgroundDiv2">
      <div className="firstBackgroundDiv2">
        <h1>VERIFY</h1>
      </div>
    </div>
    <div className="info_div">
      <div style={{ paddingTop: "30px" }}>
        {status !== 'error' ?
          status === "loading" ?
            <div className='verification_box_loading'>
              <CircularProgress color='secondary' />
            </div> :
            <div className='verification_box_success'>
              <CheckCircleOutlineIcon style={{ marginRight: "5px" }} /><p>Your account has been verified.</p>
            </div>
          : <div className='verification_box_error'>
            <CheckCircleOutlineIcon style={{ marginRight: "5px" }} /><p>Your account has already been verified.</p>
          </div>}
      </div>
      <div>
        <MobileView>
          <div className='verification_to_login_btn' onClick={() => setCounter(counter + 1)}>
            <a href="moontowertickets://Home" style={{ textDecoration: 'none', color: '#fff' }} >
              Go to App
            </a>
          </div>
          {counter > 1 ? <p style={{ textAlign: 'center', color: 'red' }}>If you don't have the app installed please download it or try <NavLink to="/login" style={{ textDecoration: 'none', color: "purple" }}>login</NavLink> through website.</p> : null}
        </MobileView>
      </div>
    </div>
  </div>;
};
