import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import ShowCard from "../../components/ShowCard/ShowCard";
import "./show.css";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { fetchCities, fetchEventLists } from "../../services/Events.services";

export const ShowsPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const promoterId = searchParams.get("promoterId") || undefined;
  const city = searchParams.get("city") || "";
  const [isOpen2, setIsOpen2] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const cityList = async () => {
      try {
        const response = await fetchCities();
        const data = await response.data;
        setCities(data.data);
      } catch (err) {
        console.log(err)
      }
    };
    cityList();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
    props.changeLinks("search");
    // eslint-disable-next-line
  }, []);
  const [showsArray, setShowsArray] = useState(props.shows);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState(showsArray);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const showsList = async () => {
      try {
        setLoader(true);
        const response = await fetchEventLists();
        const data = await response.data;
        const shows = data.data;
        setLoader(false);
        if (response.status === 200) {
          props.pushShows(shows);
          setShowsArray(shows);
        }
      } catch (err) {
        console.log(err);
      }
    };
    showsList();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    if (event)
      history.replace(`/shows?city=${event}${promoterId ? "&promoterId=" + promoterId : ""}`);
    else if (promoterId)
      history.replace(`/shows?promoterId=${promoterId}`);
    else
      history.replace(`/shows`);
  };


  const handleChangePromoter = (e) => {
    if (e)
      history.replace(`/shows?promoterId=${e || ""}${city ? "&city=" + city : ""}`);
    else if (city)
      history.replace(`/shows?city=${city}`);
    else
      history.replace(`/shows`);
  };

  useEffect(() => {
    if (showsArray?.length > 0) {
      setFiltered(
        showsArray.filter((show) => {
          return (( promoterId ?  Number(show?.operator?.id) === Number(promoterId): true) && show.mep_city?.toLowerCase().includes(city?.toLowerCase()));
        })
      );
    } else {
      setFiltered(
        showsArray.filter((show) => {
          return show.mep_city?.toLowerCase().includes("");
        })
      );
    }

  }, [promoterId, showsArray, city, searchText]);

  const uniqueIds = new Set();
  const promotersList = showsArray?.map(show => {
    return {
      id: Number(show?.operator?.id),
      name: show?.operator?.name,
      promoter_slug: show?.operator?.promoter_slug
    };
  }).filter(obj => {
    if ((obj.id !== undefined && !uniqueIds.has(obj.id) && obj.promoter_slug !== null)) { 
      uniqueIds.add(obj.id);
      return true;
    }
    return false;
  });

  return (
    <div className="pageDiv">
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv2">
          <h1>UPCOMING SHOWS</h1>
        </div>
      </div>
      <div className="info_div" style={{background:"#f1f1f1"}}>
        <div className="pageHeader"></div>
        <div className="shows_div">
          <div className="shows_sorting_controller">
            <div className="filter_search">
              <h1>Upcoming Shows</h1>
              <p>Showing all {filtered.filter(show => (show.formatted_title?.toLowerCase().includes(searchText?.toLowerCase()) || show.formatted_content?.toLowerCase().includes(searchText?.toLowerCase()))).length} results</p>
            </div>
            <div className="searching_div_flex">
              <Box className="sortingMenu1">
                <FormControl color="warning" fullWidth>
                  <TextField
                    id="outlined-adornment-email-login"
                    type="text"
                    color="warning"
                    style={{ width: "100%" }}
                    value={searchText || ""}
                    onChange={(e) => setSearchText(e.target.value)}
                    label="Search for events"
                    autoComplete="off"
                  />
                </FormControl>
              </Box>
              <Box className="sortingMenu2">
                <FormControl color="warning" fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    noOptionsText="No results found."
                    open={isOpen2}
                    defaultValue={city ? city : null}
                    onKeyPress={() => setIsOpen2(true)}
                    onKeyDown={() => setIsOpen2(true)}
                    onClose={() => setIsOpen2(false)}
                    options={cities}
                    onChange={(event, value) => handleChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="warning"
                        label="Filter by city"
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box className="sortingMenu2">
                <FormControl color="warning" fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    noOptionsText="No results found."
                    getOptionLabel={(option) => option?.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    value={{
                      id: promotersList.find((option) => option.id === Number(promoterId))?.id || "",
                      name: promotersList.find((option) => option.id === Number(promoterId))?.name || ""
                    }} 
                    options={promotersList}
                    onChange={(event, value) => handleChangePromoter(value?.id || "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="warning"
                        label="Filter by promoter"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </div>
          </div>
          {loader ? (
            <div className="more_div">
              <CircularProgress
                style={{ width: "120px", height: "120px" }}
                color="warning"
                disableShrink
              />
            </div>
          ) : null}
          {!loader && filtered.filter(show => (show.formatted_title?.toLowerCase().includes(searchText?.toLowerCase()) || show.formatted_content?.toLowerCase().includes(searchText?.toLowerCase()))).length === 0 ? (
            <div className="more_div">
              <h1>No events available</h1>
            </div>
          ) : null}

          <div className="shows_div_grid">
            {!loader && filtered.filter(show => (show.formatted_title.toLowerCase().includes(searchText.toLowerCase()) || show.formatted_content.toLowerCase().includes(searchText.toLowerCase()))).map((show) => {
              let link = `showspage/${show.product_id}`;
              return (
                <NavLink
                  to={link}
                  key={show.product_id}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    height: "100%"
                  }}
                >
                  <ShowCard
                    key={show.product_id}
                    src={show.featured_image}
                    title={show.formatted_title}
                    event_price_range={show?.event_price_range}
                    date={show.post_start_date}
                    cat={show?.category_primary}
                    categories={show?.special_categories}
                    type={show?.type}
                    price={
                      show.is_sale
                        ? show.ticket_sale_price
                        : show.ticket_price_web
                    }
                  />
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    shows: state.shows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushShows: (shows) =>
      dispatch({ type: actionTypes.PUSH_SHOWS, item: shows }),
    changeLinks: (navlink) =>
      dispatch({ type: actionTypes.CHANGE_NAVLINKS, payload: navlink })
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(ShowsPage);
