import { CircularProgress } from '@mui/material';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js'
import React from 'react'
import swal from 'sweetalert';
import "./stripe_checkout.css";
import stripeLogo from "../TicketPopUp/logo2.png";

export const StripeCheckout = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loader, setLoader] = React.useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setLoader(true);
        
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setLoader(false);
            return;
        }
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/successpayment/details?${props.returnUrl}`,
            },
        });
        setLoader(false);

        if (result.error) {
            swal("Error", result?.error?.message, "error");
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
  return (
      <form onSubmit={handleSubmit}>
          <img src={stripeLogo} className="stripe_logo" alt='stripe' />
          <PaymentElement />
          <br/>
         { loader ?  
         <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <CircularProgress style={{ 'color': '#635BFF' }} /> 
         </div>
         : <button className="stripe_btn" disabled={!stripe}>Pay using Stripe</button>}
      </form>
  )
}
