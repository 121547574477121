import React from "react";
import { useEffect } from "react";

export const TermAndCondition = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="pageDiv">
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv2">
          <h1>TERMS AND CONDITIONS</h1>
        </div>
      </div>
      <div className="privacy_policy_div">
        <p className="privacy_heading">
          Your use of this website is governed by these Terms and Conditions of
          Use.
        </p>
        <p className="privacy_details">LAST UPDATED: June 29, 2021</p>
        <p className="privacy_details">
          These Terms and Conditions of Use (“Terms”) apply to the
          <a href="https://www.moontowertickets.com" style={{color : "#ff7530" , paddingLeft : "5px" , paddingRight : "5px"}}>www.moontowertickets.com</a> website and all other sites, mobile sites,
          services, applications, platforms, and tools where the Terms appear or
          are linked (collectively, the “Site”). As used in these Terms, “MTT”,
          “us”, or “we” refers to Moon Tower Tickets and its subsidiaries and
          affiliates. As used in these Terms, “you”, “your”, or “yourself”
          refers to an individual who interacts with the Site, whether in a
          personal capacity or on behalf of any entity.These Terms and
          Conditions of Use (“Terms”) apply to the <a href="https://www.moontowertickets.com" style={{color : "#ff7530" , paddingLeft : "5px" , paddingRight : "5px"}}>www.moontowertickets.com</a>
          website and all other sites, mobile sites, services, applications,
          platforms, and tools where the Terms appear or are linked
          (collectively, the “Site”). As used in these Terms, “MTT”, “us”, or
          “we” refers to Moon Tower Tickets and its subsidiaries and affiliates.
          As used in these Terms, “you”, “your”, or “yourself” refers to an
          individual who interacts with the Site, whether in a personal capacity
          or on behalf of any entity.
        </p>
        <p className="privacy_heading">Agreement to the Terms</p>
        <p className="privacy_details">
          BY ACCESSING OR OTHERWISE USING THE SITE YOU AGREE TO THESE TERMS. Any
          person or entity who interacts with the Site through the use of
          crawlers, robots, browsers, data mining or extraction tools, or other
          functionality, whether such functionality is installed or placed by
          such person or entity or a third party, is considered to be using the
          Site. If at any time you do not accept all of these Terms, you must
          immediately stop using the Site.
        </p>
        <p className="privacy_heading">Ownership of the Site and Content</p>
        <p className="privacy_details">
          MTT is the sole and exclusive owner or licensee of all right, title,
          and interest in and to all content included on the Site, such as text,
          graphics, logos, images, audio clips, video, data, music, software,
          and other material (collectively “Content”). Graphics, logos, page
          headers, button icons, scripts, and service names included in or made
          available through the Site are trademarks or trade dress of MTT or its
          licensors. MTT’s trademarks and trade dress may not be used in
          connection with any product or service that is not MTT’s or in any
          manner that is likely to cause confusion among customers. All other
          trademarks not owned by MTT that appear on any Site are the property
          of their respective owners, who may or may not be affiliated with,
          connected to, or sponsored by MTT. All Site content, design, text,
          graphics, images, logos, buttons, icons, interfaces, audio and video
          clips, and the selection and arrangements thereof are the exclusive
          property of MTT, or its respective content providers, and are
          protected by U.S. and international copyright laws. All software used
          on the Site is the property of MTT or its respective software
          suppliers, and such software is protected by U.S. and international
          copyright laws and other laws and treaties. The collection,
          arrangement, and assembly of all content on the Site is the exclusive
          property of MTT and is protected by U.S. and international copyright
          laws. MTT reserves all intellectual property rights in all Content.
        </p>
        <p className="privacy_heading">License and Access</p>
        <p className="privacy_details">
          MTT grants you a limited license to access and make personal use of
          the Site and the Content for NONCOMMERCIAL PURPOSES ONLY and only to
          the extent such use does not violate these Terms. Accessing,
          downloading, printing, posting, storing, or otherwise using the Site
          or any of the Content for any commercial purpose, whether on behalf of
          yourself or any third party, constitutes a material breach of these
          Terms. The Site is intended for use by individuals 13 years of age or
          older. If you are under 18, you may use this Site only with
          involvement of a parent or guardian.
        </p>
        <p className="privacy_heading">Prohibited Uses of the Site</p>
        <p className="privacy_details">
          The Site may only be used for lawful purposes in accordance with the
          terms of the license granted in these Terms. As a condition of your
          use of this Site, you warrant to MTT that you will not use the Site
          for any purpose that is unlawful or prohibited by these Terms.
        </p>
        <p className="privacy_heading">
          Whether on behalf of yourself or any third party, YOU MAY NOT:
        </p>
        <div className="privacy_details">
          <ul>
            <li>
              make any commercial use of the Site or its Content, other than
              purchasing products;
            </li>
            <li>
              download, copy or transmit any Content for the benefit of any
              third party;
            </li>
            <li>
              use or attempt to use any engine, software, tool, agent, data, or
              other mechanism, including browsers, spiders, robots, avatars, or
              intelligent agents, to navigate or search the Site other than the
              search engine and search agents provided by generally publicly
              available browsers;
            </li>
            <li>
              frame, mirror, or use framing techniques on any part of the Site
              without MTT’s express prior written consent;
            </li>
            <li>
              make any use of data extraction, scraping, mining, or other data
              gathering tools, or create a database by systematically
              downloading or storing Site content, except pursuant to the
              limited license granted by these Terms;
            </li>
            <li>
              use any meta tags or any other hidden text utilizing MTT’s name or
              marks;
            </li>
            <li>
              misrepresent the identity of a user, impersonate any person or
              entity, falsely state or otherwise misrepresent your affiliation
              with any person or entity in connection with the Site, or express
              or imply that MTT endorses any statement you make;
            </li>
            <li>conduct fraudulent activities on the Site;</li>
            <li>
              violate or attempt to violate the security of the Site, including,
              without limitation: (i) accessing data not intended for you or
              logging onto a server or an account that you are not authorized to
              access; (ii) trying to change the behavior of the Site; (iii)
              attempting to probe, scan, or test the vulnerability of a system
              or network, or to breach security or authentication measures; (iv)
              attempting to interfere with service to any user, host, or
              network, including, without limitation, via means of submitting
              malware to the Site, overloading, “flooding,” “spamming,”
              “mailbombing,” or “crashing”; (v) forging any header or any part
              of the header information in any email or posting; or (vi) forging
              communications on behalf of the Site (impersonating the MTT Site)
              or to the Site (impersonating another user);
            </li>
            <li>
              send unsolicited or unauthorized email on behalf of MTT, including
              promotions or advertising of products or services;
            </li>
            <li>
              tamper with the Site or use or attempt to use any device,
              software, routine, or data that interferes or attempts to
              interfere with the working or functionality of the Site or any
              activity being conducted on the Site;
            </li>
            <li>
              use the Site to defame, abuse, harass, stalk, threaten, or
              otherwise violate the legal rights of others, including others’
              privacy rights or rights of publicity;
            </li>
            <li>
              harvest or collect personally identifiable information about other
              users of the Site;
            </li>
            <li>
              estrict or inhibit any other person from using the Site,
              including, without limitation, by hacking or defacing any portion
              of the Site;
            </li>
            <li>
              use the Site to advertise or offer to sell or buy any goods or
              services without MTT’s express prior written consent;
            </li>
            <li>
              reproduce, duplicate, copy, sell, resell, or otherwise exploit for
              any commercial purposes any portion of, use of or access to the
              Site;
            </li>
            <li>
              modify, adapt, translate, reverse engineer, decompile, or
              disassemble any portion of the Site; or
            </li>
            <li>
              remove any copyright, trademark, trade dress, or other proprietary
              rights notice from the Site or materials originating from the
              Site.
            </li>
            MTT protects your information according to our Privacy Policy posted
            on the Site, and our Privacy Policy, as amended from time to time,
            is incorporated by this reference in its entirety into these Terms.
          </ul>
        </div>

        <p className="privacy_heading">Your Account</p>
        <p className="privacy_details">
          You may be required to register with MTT in order to access certain
          services or areas of the Site. With respect to any such registration,
          we may refuse to grant to you the username you request. Your username
          and password are for your personal use only. If you use the Site, you
          are responsible for maintaining the confidentiality of your account
          and password and for restricting access to your computer and you agree
          to accept responsibility for all activities that occur under your
          account or password. In addition to all other rights available to MTT,
          including those set forth in these Terms, MTT reserves the right, in
          its sole discretion, to terminate your account, refuse service to you,
          or cancel orders.
        </p>

        <p className="privacy_heading">Modification and Termination</p>
        <p className="privacy_details">
          MTT may at any time: (i) modify or discontinue any part of the Site;
          (ii) charge, modify, or waive fees required to use the Site; or (iii)
          offer opportunities to some or all Site users. MTT reserves the right
          to make changes to these Terms at any time, without prior notice to
          you, and such changes will be effective immediately upon being posted
          on the Site. Each time you use the Site, you should review the current
          Terms. You can determine when these Terms were last revised by
          referring to the “LAST UPDATED” legend at the top of these Terms. Your
          continued use of the Site will indicate your acceptance of the current
          Terms. Any change to these Terms after your last usage of the Site
          will not be applied retroactively. MTT reserves the right, without
          notice and at its sole discretion, to terminate your account or your
          use of the Site and to block or prevent future access to and use of
          the Site (i) if you violate any of these Terms, (ii) for any other
          reason, or (iii) for no reason. Upon any such termination, your right
          to use the Site will immediately cease.
        </p>

        <p className="privacy_details">
          You agree that we shall not be liable to you or any third party for
          any termination of your access to the Site. Upon termination, all
          provisions of these Terms which are by their nature intended to
          survive termination, all representations and warranties, all
          limitations of liability, and all indemnities shall survive such
          termination.
        </p>

        <p className="privacy_heading">
          User Reviews, Comments, and Submissions
        </p>
        <p className="privacy_details">
          MTT welcomes your reviews, comments, and other communications, photos,
          videos, or any other content that you submit through or to the Site,
          or any content or information you publish through any social media
          site and allow MTT to feature, such as your name, social media handle,
          accompanying text, and any images from your social media accounts
          (collectively, “User Content”) as long as the User Content submitted
          by you complies with these Terms. User Content will not include any
          photographs or images you submit as part of a Transaction, as defined
          below.
        </p>
        <p className="privacy_details">
          You agree that any User Content: (i) will be accurate; (ii) will not
          violate or facilitate the violation of any law or regulation; (iii)
          will not violate any right of a third party, including, without
          limitation, copyright, trademark, trade dress, privacy, or publicity
          rights; (iv) will not cause injury to any person or entity; and (v)
          will not contain, or provide links to, obscene, profane, or
          threatening language, malware, political campaigning, commercial
          solicitation, chain letters, mass mailings, any form of “spam,” or any
          material that could be considered harmful, sexually explicit,
          indecent, lewd, violent, abusive, or degrading.
        </p>
        <p className="privacy_details">
          You are solely responsible for the User Content you submit, and MTT
          assumes no liability for any User Content submitted by you. You
          acknowledge and agree that we reserve the right, but have no
          obligation, to do any or all of the following, in our sole discretion:
          (i) monitor User Content; (ii) alter, remove, or refuse to post or
          allow to be posted any User Content; or (iii) disclose any User
          Content, and the circumstances surrounding its transmission, to any
          third party. For any User Content you submit, you grant to MTT a
          non-exclusive, sub-licensable, fully paid-up, perpetual, irrevocable,
          royalty-free, worldwide, transferable right and license to use,
          display, perform, transmit, copy, modify, delete, adapt, publish,
          translate, create derivative works from, sell, and distribute such
          User Content and to incorporate the User Content into any form,
          medium, or technology, now known or hereafter developed, throughout
          the world, all without compensation to you. For this reason, do not
          post to the Site or send MTT any User Content that you do not wish to
          license to MTT, including, without limitation, any confidential
          information or any original creative materials such as stories,
          product ideas, computer code, or original artwork. In addition, you
          grant to MTT the right to include the name provided along with the
          User Content submitted by you, provided, however, that MTT shall have
          no obligation to include such name with such User Content. We are not
          responsible for the use or disclosure of any personal information that
          you voluntarily disclose in connection with any User Content you
          submit. You represent and warrant that you have all rights necessary
          for you to grant the licenses granted in this section. You further
          irrevocably waive any “moral rights” or other rights with respect to
          attribution of authorship or integrity of materials regarding User
          Content that you may have under any applicable law under any legal
          theory.
        </p>
        <p className="privacy_details">
          Content is also provided by third party visitors to the Site. Please
          note that Site visitors may post content that is inaccurate,
          misleading, or deceptive. MTT neither endorses nor is responsible for
          any opinion, advice, information, or statements made by third parties.
          The opinions expressed by third parties reflect solely the opinions of
          the individuals who submitted such opinions and may not reflect the
          opinions of MTT.
        </p>
        <p className="privacy_heading">Transactions</p>
        <p>
          Your order of products using the Site shall be considered purchase
          orders and offers that MTT may accept in its sole discretion. Such
          purchase orders are deemed to incorporate all of the terms of these
          Terms. No other additional or supplemental terms, even if stated in
          any communication, shall be binding on MTT. MTT objects to any attempt
          by you to include on any order additional or supplemental terms that
          are not contained in these Terms or on the Site and MTT’s acceptance
          of any purchase order shall not be a waiver of that objection. The
          prices at which you may purchase the products are as maintained by MTT
          on the Site, which may be changed, at MTT’s sole discretion, at any
          time without notice to you, but such price changes shall not be
          applicable to any purchase order that MTT has accepted.
        </p>
        <p className="privacy_details">
          If you wish to purchase any product or service made available through
          the Site (each such purchase, a “Transaction”), you may be asked to
          supply certain information relevant to your Transaction including,
          without limitation, your credit card number, the expiration date of
          your credit card, your billing address, and your shipping information.
          YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY
          CREDIT CARD(S) OR OTHER PAYMENT METHOD(S) UTILIZED IN CONNECTION WITH
          ANY TRANSACTION. By submitting such information, you grant to MTT the
          right to provide such information to third parties for purposes of
          facilitating the completion of Transactions initiated by you or on
          your behalf. Verification of information may be required prior to the
          acknowledgment or completion of any Transaction.
        </p>

        <p className="privacy_details">
          All descriptions, images, references, features, content,
          specifications, products, and prices of products and services
          described or depicted on the Site are subject to change at any time
          without notice. Certain weights, measures, and other descriptions are
          approximate and are provided for convenience purposes only. The
          inclusion of any products or services on the Site does not imply or
          warrant that these products or services will be available. It is your
          responsibility to ascertain and obey all applicable local, state,
          federal, and international laws, including minimum age requirements,
          in regard to the receipt, possession, use, and sale of any item
          purchased from this Site. By placing an order, you represent that the
          products ordered will be used only in a lawful manner. MTT reserves
          the right, with or without prior notice, to do any one or more of the
          following: (i) limit the available quantity of or discontinue any
          product or service; (ii) impose conditions on the honoring of any
          coupon, coupon code, promotional code, or other similar promotion;
          (iii) bar any user from making or completing any or all
          Transaction(s); and (iv) refuse to provide any user with any product
          or service. You agree to pay all charges that may be incurred by you
          or on your behalf through the Site, at the price(s) in effect when
          such charges are incurred including, without limitation, all shipping,
          freight, taxes, duties, and handling charges.
        </p>

        <p className="privacy_details">
          MTT or our agents may call or text by telephone regarding your account
          or your Transactions. You agree that we may place such calls or texts
          using an automatic dialing or announcing device. You agree that we may
          make such calls or texts to a mobile telephone or other similar
          device. You agree that we may, for training purposes or to evaluate
          the quality of our service, listen to and record phone conversations
          you have with us.
        </p>

        <p className="privacy_heading">Order Pickup</p>
        <p className="privacy_details">
          Some products offered on the Site are available for pickup at physical
          stores operated by MTT. Not all items are available for this service
          nor is the service available in all stores. If applicable, you will be
          notified when your order is ready for pickup. If someone else will be
          picking up your order, you must designate him or her as an alternate
          pickup person when ordering. When picking up your order, you or your
          alternate pickup person must show a valid government-issued ID. MTT
          will hold your items for three (3) days starting from the time that
          MTT sends you the notice that your items are ready for pickup. You
          will not be charged for your order until your order is picked up. MTT
          reserves the right to limit the quantity of items ordered for Order
          Pickup as well as the days of the year that this service is offered.
        </p>

        <p className="privacy_heading">Colors</p>
        <p className="privacy_details">
          MTT strives to display as accurately as possible the colors of the
          products shown on the Site. However, MTT cannot and does not guarantee
          that your monitor’s display of any color will be accurate.
        </p>

        <p className="privacy_heading">Limitations on Quantity</p>
        <p className="privacy_details">
          MTT does not offer additional discounts on large orders of a single
          item or on large orders of many individual items. In addition, we
          reserve the right to limit quantities on orders placed by the same
          account, on orders placed by the same credit card, on orders that use
          the same billing or shipping address, and on the amount of any product
          that will be made available for sale on the Site. We will notify you
          if such limits are applied. MTT reserves the right to prohibit
          purchases of any merchandise to resellers. Resellers are defined as a
          company or an individual that purchases goods with the intention of
          selling them rather than using them.
        </p>

        <p className="privacy_heading">
          Availability, Errors, and Inaccuracies
        </p>
        <p className="privacy_details">
          If an item is out of stock, MTT may, in its sole discretion and at no
          extra cost to you, substitute another item for the out-of-stock item
          if MTT, in its sole discretion, determines that the identical brand
          and product is available in a form that is of equal or greater value.
          MTT’s acknowledgement of an order means that your order request has
          been received; it does not mean that your order has been accepted or
          shipped or that the price or availability of an item has been
          confirmed. MTT makes a conscientious effort to describe and display
          its products and services accurately on the Site. Despite these
          efforts, a small number of items on the Site may be mispriced,
          described inaccurately, or unavailable, and we may experience delays
          in updating information on the Site and in our advertising on other
          sites. As a result, we cannot and do not guarantee the accuracy or
          completeness of any information, including prices, product images,
          specifications, availability, and services. MTT reserves the right to
          change or update information and to correct errors, inaccuracies, or
          omissions at any time without prior notice. We apologize for any
          inconvenience. As part of our shipping procedures, we verify the
          availability and the price before an item is shipped. If an item’s
          correct price is lower than our stated price, we will charge the lower
          amount and ship you the item. If an item’s correct price is higher
          than our stated price, if the item is no longer available, or if we
          determine that there were inaccuracies in our product information, we
          will cancel your order and notify you of such cancellation via email.
        </p>

        <p className="privacy_heading">Risk of Loss</p>
        <p className="privacy_details">
          Except as otherwise set forth herein, the risk of loss for and title
          to products purchased on the Site passes to the purchaser upon
          delivery to the carrier.
        </p>

        <p className="privacy_heading">Gift Cards and Certificates</p>
        <p className="privacy_details">
          All gift cards, e-gift cards, and other gift certificates are deemed
          purchased in and issued from the State of Texas. The risk of loss and
          title to such items passes to the purchaser upon our electronic
          transmission to the recipient or delivery to the carrier, whichever is
          applicable.
        </p>

        <p className="privacy_heading">Third-Party Financial Information</p>
        <p className="privacy_details">
          MTT may provide links or access to third-party websites or services
          that contain information that is provided as a service to those
          interested in the information. MTT neither regularly monitors nor
          assumes responsibility for the content of third parties’ statements or
          websites. Accordingly, MTT does not endorse or adopt these websites or
          any information contained therein. MTT makes no representations or
          warranties whatsoever regarding their accuracy or completeness.
        </p>

        <p className="privacy_heading">
          DMCA NOTICE—Notice and Procedure for Making Claims of Copyright
          Infringement
        </p>
        <p className="privacy_details">
          MTT’s policy is to respond to notices of alleged infringement that
          comply with the Digital Millennium Copyright Act (“DMCA”).
          Copyright-infringing materials found on the Site can be identified and
          removed via our process listed below, and you agree to comply with
          such process in the event you are involved in any claim of copyright
          infringement to which the DMCA may be applicable.
        </p>

        <p className="privacy_heading">Risk of Loss</p>
        <p className="privacy_details">
          Except as otherwise set forth herein, the risk of loss for and title
          to products purchased on the Site passes to the purchaser upon
          delivery to the carrier.
        </p>

        <p className="privacy_details">
          If you believe in good faith that your work has been copied in a way
          that constitutes copyright infringement, please provide MTT’s
          copyright agent the written information specified below. Please note
          that this procedure is exclusively for notifying MTT that your
          copyrighted material has been infringed. MTT does not and will not
          make any legal decisions about the validity of your claim of
          infringement or the possible defenses to a claim. When a clear and
          valid notice is received pursuant to the guidelines set forth below,
          MTT will respond by either taking down the allegedly infringing
          content or blocking access to it. MTT may contact the notice provider
          to request additional information. Under the DMCA, MTT is required to
          take reasonable steps to notify the user who posted the allegedly
          infringing content (“Alleged Infringer”). The Alleged Infringer is
          allowed under the law to send MTT a counter-notification. Notices and
          counter-notices are legal notices distinct from regular Site
          activities or communications. We may publish or share them with third
          parties in our sole discretion and produce them pursuant to a subpoena
          or other legal discovery request. Anyone making a false or fraudulent
          notice or counter-notice may be liable for damages under the DMCA,
          including costs and attorneys’ fees. Any person who is unsure of
          whether certain material infringes a copyright held by such person or
          a third party should contact an attorney.
        </p>

        <p className="privacy_details">
          To file a DMCA notice, the copyright owner must send in a written
          letter by regular mail or email only. We reserve the right to ignore a
          notice that is not in compliance with the DMCA, and we may, but are
          not obligated to, respond to a non-compliant notice.
        </p>

        <p className="privacy_heading">A DMCA notice must:</p>

        <ul className="privacy_details">
          <li>
            identify specifically the copyrighted work(s) believed to have been
            infringed (for example, “My copyrighted work is the picture that
            appears at [list location where material is located].”);
          </li>
          <li>
            identify the Content that a copyright owner claims is infringing
            upon copyrighted work;
          </li>
          <li>
            provide information reasonably sufficient to enable MTT to locate
            the item on the Site;
          </li>
          <li>
            provide clear screenshots of the allegedly infringing materials for
            identification purposes only;
          </li>
          <li>
            provide information sufficient to permit MTT to contact the
            copyright owner directly: name, street address, telephone number,
            and email (if available);
          </li>
          <li>
            if available, provide information sufficient to permit us to notify
            the Alleged Infringer (email address preferred);
          </li>
          <li>
            include the following statement: “I have a good faith belief that
            use of the material in the manner complained of is not authorized by
            the copyright owner, its agent, or the law.”;
          </li>
          <li>
            include the following statement: “I swear, under penalty of perjury,
            that the information in the notification is accurate and that I am
            the copyright owner or am authorized to act on behalf of the owner
            of an exclusive right that is allegedly infringed.”
          </li>
          <li>be signed; and</li>
          <li>
            be sent to our DMCA designated agent at the following email address:
          </li>
        </ul>
        <p className="privacy_heading">DMCA Designated Agent</p>
        <p className="privacy_heading">email: cory@moontowertickets.com</p>
        <p className="privacy_heading">Links to Third Party Websites</p>
        <p className="privacy_details">
          The Site may contain links and interactive functionality interacting
          with the websites of third parties, including social sites and product
          manufacturers’ sites. MTT is not responsible for and has no liability
          for the functionality, actions, inactions, privacy settings, privacy
          policies, terms, or content of any such website. Before enabling any
          sharing functions of the Site to communicate with any such website or
          otherwise visiting any such website, MTT strongly recommends that you
          review and understand the terms and conditions, privacy policies,
          settings, and information-sharing functions of each such third-party
          website. The links and interactive functionality for third-party sites
          on the Site do not constitute an endorsement by MTT of such
          third-party sites. Other sites may link to the Site with or without
          our authorization, and we may block any links to or from the Site.
          YOUR USE OF THIRD-PARTY WEBSITES AND RESOURCES IS AT YOUR OWN RISK.
        </p>
        <p className="privacy_heading">Disclaimers of Warranties</p>
        <p className="privacy_details">
          MTT cannot and does not represent or warrant that the Site or its
          server will be error-free, uninterrupted, free from unauthorized
          access (including third-party hackers or denial of service attacks),
          or otherwise meet your requirements.
        </p>
        <p className="privacy_details">
          THE SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS, SERVICES,
          AND USER CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
          THROUGH THE SITE (COLLECTIVELY, THE “SITE CONTENTS”) ARE PROVIDED BY
          MTT ON AN “AS-IS,” “AS-AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR
          WARRANTIES OF ANY KIND. MTT MAKES NO REPRESENTATIONS OR WARRANTIES OF
          ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE, THE
          ACCURACY OR COMPLETENESS OF THE SITE CONTENTS, OR THAT EMAILS SENT
          FROM MTT ARE FREE OF MALWARE OR OTHER HARMFUL COMPONENTS. YOU
          EXPRESSLY AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. TO THE
          FULL EXTENT PERMITTED BY LAW, MTT DISCLAIMS ANY AND ALL
          REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SITE AND THE SITE
          CONTENTS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
          WARRANTIES OF TITLE, MERCHANTABILITY, GOOD AND WORKMANLIKE
          PERFORMANCE, AND FITNESS FOR A PARTICULAR PURPOSE OR USE.
        </p>
        <p className="privacy_details">
          CERTAIN PROVIDERS OF PRODUCTS MAY SEPARATELY PROVIDE LIMITED
          REPRESENTATIONS OR WARRANTIES REGARDING THEIR PRODUCTS. THIS
          DISCLAIMER DOES NOT APPLY TO SUCH PRODUCT WARRANTIES.
        </p>

        <p className="privacy_details">
          MTT DOES NOT REPRESENT OR WARRANT THAT THE INGREDIENT, ALLERGEN, OR
          OTHER PRODUCT INFORMATION ON OUR SITE IS ACCURATE OR COMPLETE SINCE
          THIS INFORMATION MAY BE MODIFIED FROM TIME TO TIME. WE RECOMMEND THAT
          YOU DO NOT RELY SOLELY ON THE INFORMATION PRESENTED ON THE SITE AND
          THAT YOU CONSULT THE PRODUCT’S LABEL IF YOU HAVE A SPECIFIC CONCERN OR
          QUESTION ABOUT A PRODUCT.
        </p>

        <p className="privacy_details">
          On the Site, we may display names, marks, products, advertisements, or
          services of third parties, pop-up texts, or links to third-party
          sites. If you decide to link to any such third-party sites, you do so
          entirely at your own risk.
        </p>
        <p className="privacy_heading">Jurisdictional Issues</p>
        <p className="privacy_details">
          The Site is controlled and operated by MTT from the United States and
          is not intended to subject MTT to the laws or jurisdiction of any
          state, country, or territory other than that of the United States. MTT
          does not represent or warrant that the Site or any part thereof is
          appropriate or available for use in any particular jurisdiction other
          than the United States. In choosing to access the Site, you do so on
          your own initiative and at your own risk, and you are responsible for
          complying with all local laws, rules, and regulations. We may limit
          the Site’s availability, in whole or in part, to any person,
          geographic area, or jurisdiction we choose, at any time and in our
          sole discretion.
        </p>
        <p className="privacy_heading">Limitation of Liability</p>
        <p className="privacy_details">
          UNDER NO CIRCUMSTANCES SHALL MTT OR ITS SUBSIDIARIES, AFFILIATES,
          EMPLOYEES, DIRECTORS, OFFICERS, OR AGENTS BE LIABLE FOR ANY DIRECT OR
          INDIRECT LOSSES OR DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR
          USE OF OR INABILITY TO USE THE SITE. THIS IS A COMPREHENSIVE
          LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY
          KIND, WHETHER GENERAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, INCIDENTAL,
          EXEMPLARY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA,
          INCOME, OR PROFITS, WHETHER IN CONTRACT OR TORT, EVEN IF MTT HAS BEEN
          ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IF
          YOU ARE DISSATISFIED WITH THE SITE, ANY CONTENT ON THE SITE, OR THESE
          TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
          SITE. YOU ACKNOWLEDGE, BY YOUR USE OF THE SITE, THAT YOUR USE OF THE
          SITE IS AT YOUR SOLE RISK. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION
          OF LIABILITY SET FORTH ABOVE, SO THIS LIMITATION OF LIABILITY MAY NOT
          APPLY TO YOU, AND YOU MAY HAVE RIGHTS ADDITIONAL TO THOSE CONTAINED
          HEREIN. THE FOREGOING PARAGRAPH SHALL NOT APPLY TO RESIDENTS OF NEW
          JERSEY. WITH RESPECT TO RESIDENTS OF NEW JERSEY, MTT OR ITS
          SUBSIDIARIES, AFFILIATES, EMPLOYEES, DIRECTORS, OFFICERS, OR AGENTS
          SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SITE OR ANY
          MATERIALS THEREIN UNLESS SUCH DAMAGES OR INJURIES ARE THE RESULT OF
          MTT’S NEGLIGENT, FRAUDULENT, OR RECKLESS ACTS OR INTENTIONAL
          MISCONDUCT.
        </p>
        <p className="privacy_details">
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
          LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO
          ALLOCATE THE RISKS UNDER THE AGREEMENT BETWEEN YOU AND MTT. THIS
          ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN
          YOU AND MTT. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY
          LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE(S).
        </p>
        <p className="privacy_heading">Indemnification and Defense</p>
        <p className="privacy_details">
          As a condition of the use of the Site, you agree to defend, indemnify,
          and hold harmless MTT and its respective subsidiaries, affiliates,
          employees, directors, officers, agents, vendors, and suppliers from
          and against any liabilities, losses, investigations, inquiries,
          claims, suits, damages, costs, and expenses, including, without
          limitation, reasonable attorneys’ fees, expert fees, and expenses
          (each, a “Claim”) arising out of or otherwise relating to Claims
          alleging facts that if true would constitute a breach by you of these
          Terms, or any User Content submitted by you.
        </p>
        <p className="privacy_heading">
          Notices and Electronic Communications
        </p>
        <p className="privacy_details">
          Except as explicitly stated otherwise, any notices you send to MTT
          shall be sent by email to cory@moontowertickets.com. In the case of
          notices MTT sends to you, you consent to receive notices and other
          communications by MTT posting notices on the Site, sending you an
          email at the email address listed in your profile in your account, or
          mailing a notice to you at your billing address listed in your profile
          in your account. You agree that all agreements, notices, disclosures,
          and other communications that MTT provides to you in accordance with
          the prior sentence satisfy any legal requirement that such
          communications be in writing. Notice shall be deemed given (i) 24
          hours after the notice is posted on the Site or an electronic message
          is sent, unless the sending party is notified that the message did not
          reach the recipient, or (ii) in the case of mailing, three (3) days
          after the date of mailing. You agree that a printed version of these
          Terms and/or any notice given in electronic form shall be admissible
          in judicial or administrative proceedings based upon or relating to
          these Terms to the same extent and subject to the same conditions as
          other business documents and records originally generated and
          maintained in printed form.
        </p>

        <p className="privacy_heading">Rules for Contests or Promotions</p>
        <p className="privacy_details">
          Any sweepstakes, contests, raffles, or other promotions (collectively,
          “Promotions”) made available through the Site may be governed by rules
          that are separate from these Terms. If you participate in any
          Promotions, please review the applicable rules as well as our Privacy
          Policy. If the rules for a Promotion conflict with these Terms, the
          Promotion rules will apply.
        </p>

        <p className="privacy_heading">Notice for California Residents</p>
        <p className="privacy_details">
        Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: If you have a question or complaint regarding the Site, please contact Moon Tower Tickets at the email, telephone number, or address listed above.
        </p>

        <p className="privacy_heading">Applicable Law; Disputes</p>
        <p className="privacy_details">
        YOU AGREE THAT ALL MATTERS RELATING TO YOUR ACCESS TO OR USE OF THE SITE AND ALL MATTERS ARISING OUT OF OR RELATED TO THESE TERMS, INCLUDING ALL DISPUTES, WILL BE GOVERNED BY THE LAWS OF THE STATE OF TEXAS, UNITED STATES OF AMERICA, WITHOUT REGARD TO (A) CONFLICTS OF LAWS RULES, AND (B) THE UNITED NATIONS’ CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS. YOU FURTHERMORE AGREE THAT THE EXCLUSIVE FORUM AND VENUE FOR ANY LEGAL ACTION ARISING OUT OF OR RELATED TO THESE TERMS SHALL BE THE UNITED STATES DISTRICT COURT FOR THE EASTERN DISTRICT OF TEXAS, AND YOU SUBMIT TO THE PERSONAL JURISDICTION OF THAT COURT. IF SUBJECT MATTER JURISDICTION (INCLUDING DIVERSITY JURISDICTION) DOES NOT EXIST IN THE UNITED STATES DISTRICT COURT FOR THE EASTERN DISTRICT OF TEXAS, THEN THE EXCLUSIVE FORUM AND VENUE FOR ANY SUCH ACTION SHALL BE THE COURTS OF THE STATE OF TEXAS LOCATED IN HARRIS COUNTY, AND YOU SUBMIT TO THE PERSONAL JURISDICTION OF THAT COURT. THE MAKING OF CLAIMS OR RESOLUTION OF DISPUTES PURSUANT TO THIS AGREEMENT SHALL BE IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES, CLAIMS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE SITE AND/OR THESE TERMS WILL BE RESOLVED INDIVIDUALLY IN THE FORUM DESIGNATED IN THIS SECTION, WITHOUT RESORT TO ANY FORM OF CLASS ACTION; AND, EXCEPT FOR NEW JERSEY RESIDENTS AND WHERE OTHERWISE PROHIBITED BY LAW, (2) ANY AND ALL CLAIMS, JUDGMENTS, AND AWARDS WILL BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED (IF ANY), BUT IN NO EVENT WILL ATTORNEYS’ FEES BE AWARDED OR RECOVERABLE.
        </p>


        <p className="privacy_heading">Miscellaneous</p>
        <p className="privacy_details">
        These Terms, including policies and information linked from or incorporated herein, constitute the entire agreement between you and MTT with respect to the Site and your use of it and supersede all prior or contemporaneous communications, agreements, and proposals with respect to the Site or your use of it. No provision of these Terms shall be waived except pursuant to a writing executed by the party against whom the waiver is sought. No failure to exercise, partial exercise of or delay in exercising any right or remedy under these Terms shall operate as a waiver or estoppel of any right, remedy, or condition. If any provision of these Terms is held invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions will not be affected or impaired. You may not assign, transfer, or sublicense any of your rights or obligations under these Terms without MTT’s express prior written consent. MTT will not be responsible for failure to fulfill any obligation due to causes beyond its control.
        </p>
      </div>
    </div>
  );
};
