import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { PaymentMethodController } from "../../components/PaymentMethodController/PaymentMethodController";
import "./Checkout.css";
import "../../components/PaymentMethodController/PaymentMethodController.css";
import { printSeats, fixedTaxWithPriceCalculator } from "../../utils/helpers";
import { SPECIAL } from "../../constants/Events";

export const Checkout = (props) => {
  const { ht } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!props.show)
    return <Redirect to="/" />


  let totalTax = 0;
  let totalPrice = 0;
  let subtotal = 0;

  if (props.show) {
    if (typeof (props.show.purchased_tickets) !== "number") {
      let ticketTax = 0;
      let orderTax = 0;
      props.show.purchased_tickets.filter((ticket) => ticket.ticket_price_web !== 0).forEach((ticket) => {
        const taxObj = fixedTaxWithPriceCalculator(props.show, ticket.ticket_price_web, ticket.quantity);
        ticketTax += taxObj.ticketTax;
        orderTax = taxObj.orderTax;
        subtotal += (ticket.ticket_price_web * ticket.quantity);
      })
      totalTax = Number(ticketTax) + Number(orderTax);
      totalPrice = subtotal + Number(totalTax);
    } else {
      const taxObj = fixedTaxWithPriceCalculator(props.show, props.show.ticket_price_web, props.show.purchased_tickets);
      totalTax = taxObj.totalTax - (props.show.ticket_price_web * props.show.purchased_tickets).toFixed(2);
      subtotal += (props.show.ticket_price_web * props.show.purchased_tickets).toFixed(2);
      totalPrice = taxObj.totalPrice;
    }
  }



  return (
    <div >
      <div className="backgroundDiv2" >
        <div className="firstBackgroundDiv2">
          <h1>Checkout Page</h1>
        </div>
      </div>
      <div className="info_div" style={{backgroundColor:"#f1f1f1"}}>
        <div className="pageHeader">
          <h2 className="paymentDivH2">Your Order</h2>
        </div>
        <table className="product_table">
          <thead>
            <tr className="product">
              <td>Product</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            {typeof (props.show.purchased_tickets) === "number" ? <tr>
              <td>
                {props.show.formatted_title} * <strong>{props.show.purchased_tickets}</strong>
              </td>
              <td>
                ${(props.show.ticket_price_web * props.show.purchased_tickets).toFixed(2)}
              </td>
            </tr> :
              props.show.category_primary === SPECIAL.seatsio ? (props.show?.seatsio_tickets).map((ticket, index) => <tr key={index}>
                <td>
                  {props.show.formatted_title} * <strong>{ticket.label?.length}</strong> [<strong>{ticket.name}</strong>: <span>{printSeats(ticket?.label || [])}]</span>
                </td>
                <td>
                  ${(ticket.ticket_price_web * ticket.label?.length).toFixed(2)}
                </td>
              </tr>) :
                props.show.purchased_tickets.map((ticket, index) => <tr key={index}>
                  <td>
                    {props.show.formatted_title} * <strong>{ticket.quantity} ({ticket.name})</strong>
                  </td>
                  <td>
                    ${(ticket.ticket_price_web * ticket.quantity).toFixed(2)}
                  </td>
                </tr>)
            }
            <tr>
              <td>Subtotal</td>
              <td>
                ${Number(subtotal).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>Taxes and Processing fees</td>
              <td>${(Number(totalTax)).toFixed(2)}</td>
            </tr>
            <tr className="table_total">
              <td>Total</td>
              <td>
                $
                {(Number(totalPrice)).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="checkout_payment_div">
          <h1>Payment method</h1>
          <PaymentMethodController cid={props.show.category} ticket={props.show} totalTax={(totalTax).toFixed(2)} totalTickets={typeof (props.show.purchased_tickets) === "number" ? props.show.purchased_tickets : props.show.purchased_tickets?.filter((ticket) => ticket.ticket_price_web !== 0)} show={props.show} total={totalPrice} holdToken={ht} />
        </div>
      </div>
    </div>
  );
};
