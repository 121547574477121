import * as React from 'react';
import "./ShowCard.css"
import moment from "moment";
import dummyImg from '../../mtt-icon.png';

export default function ActionAreaCard(props) {


  return (
    <div className='show_card_div'>
      <div style={{'backgroundImage': `url(${props.src? props.src : dummyImg})`, backgroundSize: 'cover', height:'100%', borderRadius:'13px', minHeight : '100px'}}>
       { props.src ?
        <img src={props.src } alt={props.title} className='show_card_img'/>
        :
        <img src={dummyImg} alt={props.title} className='show_card_img_default'/>
        }
      </div>
        <div className='show_card_text_div' style={{minHeight : '61px'}}>
        <p className="show_card_title" style={{marginTop:"10px"}}>{props.title.length > 39 ? props.title.slice(0,40) + "..." : props.title}</p>
         <p className='show_card_date' style={{marginTop:"-5px"}}>{moment(props.date).format('LL')}</p>
        <p className='price-range'>
          {/* {props.type === SPECIAL.free ? "FREE" : formatRange(props?.categories, props?.price)} */}
          {props.event_price_range}
          </p>
        </div>
    </div>
  )
}

