import React from "react";
import { useEffect } from "react";
import "./Policy.css";
export const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pageDiv">
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv">
          <h1>PRIVACY POLICY</h1>
        </div>
      </div>
      <div className="privacy_policy_div">
        <p className="privacy_heading">1. Corporate Commitment to Privacy</p>
        <p className="privacy_details">
          Moon Tower Tickets and its subsidiaries and affiliates (collectively
          “MTT”, “our,” “we,” or “us”) are committed to respecting your privacy.
          This Privacy Policy describes how we collect, use, disclose, store,
          and otherwise process information when you use our websites and other
          online products and services, including pursuant to the European Union
          General Data Protection Regulation (“GDPR”). We urge you to read this
          Privacy Policy so that you understand our commitment to you and your
          privacy, and how you can participate in that commitment.
        </p>
        <p className="privacy_heading">2. Definition of Personal Information</p>
        <p className="privacy_subheading">Personal Information</p>
        <p className="privacy_details">
          “Personal Information” means any information relating to an identified
          or identifiable natural person. An identifiable person is one who can
          be identified, directly or indirectly, particularly by reference to an
          identification number or to one or more factors specific to his or her
          physical, physiological, mental, economic, cultural, or social
          identity.
        </p>
        <p className="privacy_subheading">Examples of Personal Information</p>
        <p className="privacy_details">
          “Personal Information” may include the following: name, address, date
          of birth, gender, and contact data (i.e., email address, telephone
          number, and employer name).
        </p>
        <p className="privacy_subheading">Sensitive Personal Information</p>
        <p className="privacy_details">
          “Sensitive Personal Information” means personal information specifying
          medical or health conditions, racial or ethnic origin, political
          opinions, religious or philosophical beliefs, trade union membership,
          or information pertaining to the sex life of the individual.
        </p>
        <p className="privacy_heading">
          3. Definition of Non-Personal Information
        </p>
        <p className="privacy_details">
          We may also collect information that is related to you, but that does
          not personally identify you (“Non-personal Information”). Non-personal
          Information also includes information that could personally identify
          you in its original form, but that we have modified (for instance, by
          aggregating, anonymizing, or de-identifying such information) to
          remove or hide any Personal Information.
        </p>
        <p className="privacy_heading">4. Scope and Consent</p>
        <p className="privacy_details">
          MTT has established and implemented this Privacy Policy to inform
          visitors to the website and users of our services how we use and
          protect the information we collect from our websites and services. By
          visiting our website or using any of our services, you consent to
          MTT’s collection, use, disclosure, transfer, and storage of your
          Personal Information as set forth in this Privacy Policy.
        </p>
        <p className="privacy_heading">5. Information Voluntarily Provided</p>
        <p className="privacy_details">
          MTT collects Personal Information about you in connection with many of
          our services. When working with or using this website, you may be
          prompted to make an account which holds personal information such as
          name, mailing address, email address, and credit card information.
          When contacting our company, you may be required to submit contact
          information. When communicating with MTT, all communications will be
          transmitted and stored by us.
        </p>

        <p className="privacy_heading">
          6. Information Collected Automatically
        </p>
        <p className="privacy_details">
          MTT may also collect Technical Information about you when you visit
          our websites, which your web browser automatically sends whenever you
          visit a website on the Internet. “Technical Information” is
          information that does not, by itself, identify a specific individual,
          but which could be used to indirectly identify you. Our servers
          automatically record this information, which may include your Internet
          Protocol (“IP”) address, browser type, browser language, and the date
          and time of your request. Gathering your information helps us ensure
          our websites and other services work correctly and support out
          customer analytic efforts.
        </p>
        <p className="privacy_heading">
          7. Information Collected Automatically
        </p>
        <p className="privacy_subheading">Email communication</p>
        <p className="privacy_details">
          We use pixel tags and cookies in our marketing emails so that we can
          track your interaction with those messages, such as when you open the
          email or click a URL link that is embedded within them. When
          recipients click on one of those URLs, they pass through a separate
          web server before arriving at the destination page on a company
          website. We use tools like pixel tags and cookies so that we can
          determine interest in particular topics and measure and improve the
          effectiveness of our communications.
        </p>
        <p className="privacy_subheading">Mobile communication</p>
        <p className="privacy_details">
          When you download or use our mobile-device applications, or access one
          of our mobile-optimized websites, we may receive information about
          your mobile device, including a unique identifier for your device.
        </p>
        <p className="privacy_subheading">Cookies and Similar Technologies</p>
        <p className="privacy_detials">
          We may collect information about your use of the websites through
          cookies and similar technology. A “cookie” is a unique numeric code
          that we transfer to your computer so that we can keep track of your
          interests and/or preferences and recognize you as a return visitor to
          the websites. For example, we may use these technologies to collect
          information about the ways visitors use our websites to support the
          features and functionality of our websites and to personalize your
          experience when you use our websites.
        </p>
        <p className="privacy_heading">
          8. Information Collected about Friends/Family
        </p>
        <p className="privacy_details">
          MTT may also collect information about your friends and family through
          your interactions on our services. Referring friends to our services
          requires you to send their personal information to MTT. Other
          instances, such as sharing content, may also require you to send their
          personal information to us.
        </p>
        <p className="privacy_heading">
          9. Information Collected from Social Media Applications/Networking
          Websites
        </p>
        <p className="privacy_details">
          Our websites include social media features such as the Facebook Like
          button and other widgets. These features may collect your IP address
          and which page you are visiting on our site and may set a cookie to
          enable the feature to function properly. Social media features and
          widgets are either hosted by a third party or hosted directly on our
          site. Your interactions with these features are governed by the
          privacy policy of the organization providing it. For example, if you
          create or log into your account through a third-party social
          networking site, we may have access to certain information from that
          site, such as your name, account information, and friends, in
          accordance with the authorization procedures determined by such
          third-party social networking site.
        </p>

        <p className="privacy_heading">
          10. Information Collected from Other Sources
        </p>
        <p className="privacy_details">
          MTT may also collect information about you from other sources to help
          us correct or supplement our records, improve the quality or
          personalization of our services to you, and prevent or detect fraud.
        </p>

        <p className="privacy_heading">
          11. Information Collected from Other Sources
        </p>
        <p className="privacy_subheading">Third-Party Vendors</p>
        <p className="privacy_details">
          To provide the services and improve MTT’s website, we may engage the
          services of third-party vendors. In the process of supplying services
          to MTT, these third-party vendors may need to collect Personal
          Information about you.
        </p>
        <p className="privacy_subheading"></p>
        <p className="privacy_details">
          Some company websites or services may be co-branded and offered in
          conjunction with another company. If you use such websites or
          services, both MTT and the other company may receive the Personal
          Information that is collected via that co-branded website or service.
        </p>
        <p className="privacy_subheading">
          Public Forums, Blogs, and the Customer Reference Program
        </p>
        <p className="privacy_details">
          MTT’s website may feature bulletin boards, blogs, and forums. Any
          Personal Information that you choose to submit via such a forum may be
          read, collected, or used by others who visit these forums, and may be
          used to send you unsolicited messages.
        </p>
        <p className="privacy_subheading">Flash LSOs</p>
        <p className="privacy_details">
          We use Local Storage Objects (LSOs) such as HTML5 and Flash to store
          content information and preferences. We partner with third parties to
          provide certain features on our site and to display advertising based
          upon your browsing activity. Various browsers may offer their own
          management tools for removing HTML5 LSOs.
        </p>
        <p className="privacy_subheading">Log Files</p>
        <p className="privacy_details">
          Log files record website activity on our services and enable us to
          gather statistics about our users’ browsing habits. These entries help
          MTT determine (among other things) how many and how often users access
          and use our services, which pages they visit, and other similar data.
        </p>
        <p className="privacy_heading">
          12. Why does the Company Process Personal Information?
        </p>
        <p className="privacy_details">
          MTT uses your personal information to provide you products and
          services such as fulfilling your requests for products and helping us
          personalize our offerings to you. We also use your personal
          information to support our business functions such as fraud
          prevention, marketing, and legal functions. MTT has a legitimate
          interest in processing Personal Information during the process and
          keeping records of the process. MTT may also need to process Personal
          Information to respond to and defend against legal claims. MTT also
          must maintain accurate and up-to-date records and contact details. To
          do this, we combine personal and non-personal information, collected
          online and offline, including information from third party sources.
          MTT also processes Personal Information for one or more of the
          following reasons:
        </p>

        <p className="privacy_subheading">Fulfill Requests</p>
        <p className="privacy_details">
          To fulfill your requests for products and services and communicate
          with you about those requests;
        </p>

        <p className="privacy_subheading">Understand Customer Behavior</p>
        <p className="privacy_details">
          To better understand customer behavior so that we may improve our
          marketing and advertising efforts and improve the distribution of our
          products and services
        </p>

        <p className="privacy_subheading">Personalize Offerings</p>
        <p className="privacy_details">
          To help us personalize our service offerings, websites, mobile
          services, and advertising;
        </p>

        <p className="privacy_subheading">Fraud Prevention</p>
        <p className="privacy_details">
          MTT may use personal information such as name, date of birth, mailing
          address, and a social security number to verify the identity of our
          customers and to prevent fraudulent activities such as the fraudulent
          purchase of products/services;
        </p>

        <p className="privacy_subheading">Credit Card Information</p>
        <p className="privacy_details">
          Credit card information may be used to check the financial
          qualifications or collect payment from customers regarding orders of
          products and services;
        </p>

        <p className="privacy_subheading">Protection</p>
        <p className="privacy_details">
          To protect the security and integrity of our websites, mobile
          services, and our business;
        </p>

        <p className="privacy_subheading">Legal</p>
        <p className="privacy_details">
          To comply with legal and regulatory requirements;
        </p>

        <p className="privacy_subheading">Responding to Customer</p>
        <p className="privacy_details">
          To respond to reviews, comments, or other feedback you provide us; and
        </p>

        <p className="privacy_subheading">Industry Benchmarking</p>
        <p className="privacy_details">
          For industry benchmarking and analysis consistent with our legitimate
          business purpose.
        </p>

        <p className="privacy_heading">
          13. Promotional Messaging or Advertising
        </p>
        <p className="privacy_details">
          MTT uses your contact information to recommend products and services
          that might be of interest to you, to send you marketing and
          advertising messages such as newsletters, announcements, and special
          offers or to notify you about our upcoming events.
        </p>

        <p className="privacy_heading">
          14. Disclosure of Personal Information to Third Parties
        </p>
        <p className="privacy_details">
          MTT will not rent or sell your Personal Information to others, but we
          may disclose personal information to third-party vendors and service
          providers that work with MTT. We will only share Personal Information
          to these vendors and service providers to help us provide a product or
          service to you.
        </p>

        <p className="privacy_heading">
          15. Disclosure of Personal Information for Legal and Safety Reasons
        </p>
        <p className="privacy_details">
          MTT may be required to disclose personal information to the
          authorities, law enforcement agencies, government agencies, or legal
          entities. We may disclose information by law, litigation, or as a
          matter of national security to comply with valid legal process,
          including subpoenas, court orders, and search warrants, and as
          otherwise authorized by law. We may also need to disclose personal
          information in the event of an emergency that threatens an
          individual’s life, health, or security.
        </p>

        <p className="privacy_heading">
          16. Disclosure of Personal Information Via Links to Third-Party
          Websites, Services, and Applications
        </p>
        <p className="privacy_details">
          Using our website or services may link to third-party websites,
          services, and applications. MTT is not responsible for any Personal
          Information collected through these means. Information collected is
          governed through the third party’s website’s Privacy Policy. Any
          interactions you have with these websites, services, and applications
          are beyond the control of MTT.
        </p>

        <p className="privacy_heading">
          17. Use of Cookies and Other Technologies (e.g., Web Beacons)
        </p>
        <p className="privacy_details">
          MTT uses cookies to operate and improve our website as well as to
          simplify the interaction with you. When you visit our websites, our
          servers send a cookie to your computer or mobile device to help
          personalize your experience and advertisements. Cookies help us better
          understand user behavior and facilitate effectiveness of
          advertisements. Cookies only stand to recognize your web browser and
          information collected from cookies is not personally identifiable.
          <br />
          MTT may also use web beacons or other technologies in conjunction with
          cookies to gather information about your visit to our websites. Web
          beacons may be embedded through our website or electronic
          communications and can allow servers to collect information related to
          your visit. Web beacons are primarily used to provide content and
          advertisements that are more relevant to you.
        </p>

        <p className="privacy_heading">18. Ability to Disable Technologies</p>
        <p className="privacy_details">
          Some web browsers (including some mobile web browsers) provide
          settings that allow you to control or reject cookies or alert you when
          a cookie is placed on your computer, tablet, or mobile device.
          Although you are not required to accept cookies, if you block or
          reject them, you may not have access to all features available through
          our services. For more information, visit the help page for your web
          browser or see
          <a
            href="https://www.allaboutcookies.org/verify"
            style={{ color: "#ff7530" }}
          >
            http://www.allaboutcookies.org.
          </a>
        </p>

        <p className="privacy_heading">
          19. Consequences of Disabling Technologies
        </p>
        <p className="privacy_details">
          Please note that if you disable your web browser’s cookies or other
          technologies, certain features of our website and services will be
          disabled and you will limit the functionality we can provide when you
          visit our site.
        </p>

        <p className="privacy_heading">
          20. Security Measures Taken to Protect Personal Information by Company
        </p>
        <p className="privacy_details">
          Security of all information is of the utmost importance to MTT. MTT
          uses technical and physical safeguards to protect the security of your
          personal information from unauthorized disclosure. We use encryption
          technology to keep all information secure. We also make all attempts
          to ensure that only necessary people and third parties have access to
          Personal Information.
        </p>

        <p className="privacy_heading">
          21. Security Measures to Protect Personal Information by Third Parties
        </p>
        <p className="privacy_details">
          We require that our third-party service providers and channel partners
          agree to keep all information that we share with them confidential and
          to use the information only to perform their obligations in the
          agreements we have in place with them. These third-party service
          providers and channel partners are expected to maintain privacy and
          security protections that are consistent with MTT’s privacy and
          information security policies.
          <br />
          While we provide these third parties with no more information than is
          necessary to perform the function for which we engage them, any
          information that you provide to these third parties independently is
          subject to their respective privacy policies and practices.
        </p>

        <p className="privacy_heading">22. Data Retention and Storage</p>
        <p className="privacy_details">
          MTT retains your information for business purposes, for as long as
          your account is active or as long as is reasonably necessary to
          provide you with our products and services. MTT will also retain your
          information as reasonably necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements. We may also
          retain cached or archived copies of your information for a reasonable
          period of time.
          <br />
          However, unfortunately, no system or online transmission of data is
          completely secure. We cannot guarantee the security of information
          transmitted to our services. To that end, MTT will notify users of a
          data breach when MTT determines that it is reasonably necessary in
          accordance with applicable law. Any transmission is at your own risk
          and MTT expects that you will use appropriate security measures to
          protect your information.
        </p>

        <p className="privacy_heading">
          23. Collection of Information from Children Broad
        </p>
        <p className="privacy_details">
          MTT recognizes the importance of protecting the privacy and safety of
          children. Our website and services are directed towards the general
          audience and are not directed towards children. We do not knowingly
          collect information about children under the age of 13 or minors
          otherwise defined in local law or regulation without verifiable
          parental consent. If we learn that someone under 13 has provided
          Personal Information through one of our websites, we will use
          reasonable efforts to remove that information from our databases.
        </p>

        <p className="privacy_heading">
          24. International Transfer of Personal Information
        </p>
        <p className="privacy_details">
          MTT may share customer information within our family of companies for
          a variety of purposes such as to provide you with the latest
          information about our products and services and offer you our latest
          promotions. This may involve the transfer of personal data to other
          MTT companies in different locations outside your home country and
          around the world.
        </p>

        <p className="privacy_heading">25. Questions/Updates to Policy</p>
        <span className="privacy_subheading">
          Inquiry, Complaint, and Dispute Process
        </span>
        <p className="privacy_details">
          Customers may contact MTT with any questions, concerns, or inquiries
          about this Privacy Policy or company data. If you would like to make a
          formal complaint, please contact cory@moontowertickets.com. All
          complaints will be evaluated and a reply will be sent as soon as
          appropriate. In some instances, it may be necessary for MTT to cease
          services to you to satisfy your request.
        </p>
        <p className="privacy_subheading">Changes to the Data Privacy Policy</p>
        <span className="privacy_details">
          MTT may review and update this Privacy Policy periodically without any
          prior notice. MTT will post a notice to its website to inform you of
          any changes to our Privacy Policy and indicate when it was most
          recently updated. In the case of material changes that may adversely
          affect you, MTT may provide a more direct way of notifying you of
          changes to this Privacy Policy.
        </span>

        <p className="privacy_heading">
          26. Applicable Regulations—California Privacy Rights; GDPR Rights
        </p>
        <p className="privacy_details">
          California Civil Code Section 1798.83 permits users of our website who
          are California residents to request certain information regarding our
          disclosure of personal information to third parties for their direct
          marketing purposes. To make such a request, please send an email to
          cory@moontowertickets.com.
          <br />
          <span>Pursuant to GDPR, You can:</span>
        </p>
          <ul>
            <li>access and obtain a copy of your data on request;</li>
            <li>require MTT to change incorrect or incomplete data;</li>
            <li>
              require MTT to delete or stop processing your data, for example
              where the data is no longer necessary for the purposes of
              processing;
            </li>
            <li>
              object to the processing of your data where MTT is relying on its
              legitimate interests as the legal ground for processing; and
            </li>
            <li>
              request the transfer of your personal data to a third party.
            </li>
          </ul>
          <br/>
        <p className="privacy_heading">
          If you would like to exercise any of these rights, please contact
          cory@moontowertickets.com. If you believe that MTT has not complied
          with your data protection rights, you can complain to the Information
          Commissioner.
        </p>
      </div>
    </div>
  );
};
