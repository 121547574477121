import React from "react";


const EventNotFound = (props) => {
    return(
        <div className="pageDiv">
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv2">
          <h1>EVENT NOT FOUND</h1>
        </div>
      </div>

      </div>
    )
}

export default EventNotFound;