import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { SPECIAL } from '../../../constants/Events';

const formatRange = (pricesArray, price) => {
  const arr = pricesArray;
  arr.sort((a, b) => {
    const priceA = parseFloat(a.regular_price);
    const priceB = parseFloat(b.regular_price);
    return priceA - priceB;
  });
  if (arr.length === 0)
    return `$${price}`;
  else if (arr.length === 1)
    return `$${arr[0]?.regular_price}`
  else
    return `$${arr[0]?.regular_price} - $${arr[arr.length - 1]?.regular_price}`
}

const Eventblock = ({ eventDate, eventTitle, type, categories, price, eventImg, eventLink, eventBtnText }) => {
 return (
    <div className="event-block" style={{ display: 'grid', gridTemplateRows: '1fr auto', height: '100%' }}>
      <div style={{ gridArea: 'content-area' }}>
        <h3 style={{ marginBottom: 'auto' }}>{eventTitle}</h3>
       <h6>{eventDate}</h6>
       <p className='price-range-event-block'>{type === SPECIAL.free ? "FREE" : formatRange(categories, price)}</p>
      </div>
      <div className="event-img" style={{ gridArea: 'bottom-area', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "100px" }}>
        <img src={eventImg} alt="" style={{ width: '100%' }} />
      </div>
      <div style={{ gridArea: 'bottom-area', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '500px' }}>
        <Link to={eventLink} className="btn-second">
          <span>{eventBtnText}</span> <FontAwesomeIcon icon={faArrowRight}/>
        </Link>
      </div>
    </div>
 );
};

export default Eventblock;



// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import moment from "moment";


// const Eventblock = ({ eventDate, eventTitle, eventText, eventImg, eventLink, eventBtnText }) => {

//   return (
//     <div className="event-block" >
//         <h6>{eventDate}</h6>
//         {/* <div className="item_card_img_date">
//              <p className="date_number">{eventDate.split("/")[1]}</p>
//              <p className="date_month">{moment(eventDate).format('lll').split(" ")[0]}</p>
//             </div> */}

//         <h3>{eventTitle }</h3>
//         {/* <div className="item_card_event_description" dangerouslySetInnerHTML={{
//               __html:eventText ? eventText.slice(0,30) + "...." : ""
//             }}></div> */}

//         <div className="event-img" >
//             <img src={eventImg} alt="" />
//         </div>

//         <Link to={eventLink} className="btn-second"><span>{eventBtnText}</span> <FontAwesomeIcon icon={faArrowRight}/></Link>
//     </div>
//   )
// }

// export default Eventblock
