export const LOCALSTORAGE_ENUMS = {
    token: "token",
    userData: "user",
    userID: "userId",
    remember: "remember",
    guestMode: "inGuestMode",
    strictGuestMode: "inStrictGuestMode",
    checkout: "checkout",
    email: "email",
    password: "password"
}