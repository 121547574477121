import React from "react";
import Marquee from "react-fast-marquee";
import './foot.css'
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";

const Footer = ({
  marqueecontent,
  link1,
  link2,
  link3,
  link4,
  link5,
  link6,
  link7,
  link8,
  link9,
  copyright,
}) => {


  const history = useHistory();

  const handleClickSection = (e, value) => {
    e.preventDefault();
    const element = document.getElementById(value);

    if (window.location.pathname === "/") {
      if (element) {
        const scrollOffset = 90;

        setTimeout(() => {
          window.scrollTo({
            top: element.offsetTop - scrollOffset,
            behavior: "smooth",
          });
        }, 100);
      }
    } else {
      history.push(`/?id=${value}`);
    }
  };



  return (
    <>
      <footer>
        <div className="footer-lines">
          <div id="carouselTicker" className="carouselTicker">
            <ul className="carouselTicker__list">
              <li className="carouselTicker__item">
                <Marquee speed={200}>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                  <h2>{marqueecontent}&nbsp;</h2>
                </Marquee>
              </li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="quicklink-row">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className="social-bx">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/MoonTowerTickets"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/moontowertickets/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9 col-sm-12">
                <div className="quicklinks">
                  <ul>
                    <li>
                      <Link to="/">{link1}</Link>
                    </li>
                    <li>
                      <Link to="/" onClick={(e) => handleClickSection(e, "section1")}>{link2}</Link>
                    </li>
                    <li>
                      <Link to="/shows">{link3}</Link>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_ADMIN_PANEL}>{link4}</a>
                    </li>
                    <li>
                      <a
                        className="btn-nav"
                        href={process.env.REACT_APP_ADMIN_PANEL}
                      >
                        {link5} <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <p>{copyright}</p>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="privacy-links">
                  <ul>
                    <li>
                      <Link to="/privacy-policy">{link6}</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">{link7}</Link>
                    </li>
                    <li>
                      <Link to="/refund-policy">{link8}</Link>
                    </li>
                    <li>
                      <a href="mailto:info@moontowertickets.com">{link9}</a>
                    </li>       
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
