import React from "react";
import { useEffect } from "react";
import "./Policy.css";
export const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pageDiv">
      <div className="backgroundDiv2">
        <div className="firstBackgroundDiv">
          <h1>REFUND POLICY</h1>
        </div>
      </div>
      <div className="privacy_policy_div">
        <p className="privacy_heading">Return and Refund Policy</p>
        <p className="privacy_details">
          All Sales are Final.  All events are rain or shine, unless otherwise noted, but may be cancelled due to dangerous conditions.  All artists, dates, times, and locations are subject to change without notice.  No refunds are issued due to a performer or attraction being removed from a lineup.  If an event is cancelled or postponed and a reschedule date is not announced within 30 days, you may contact us through <a
                  href="mailto:info@moontowerticket.com"
                  style={{
                    color: "#ff7530",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  info@moontowerticket.com 
                  </a>
                  for information on receiving a refund. 
        </p>
        <p className="privacy_details">
          There may be refund limitations set by the Event Organizer, including deadlines for requesting a refund.  Any instructions and limitations will be communicated to you by Moon Tower Entertainment, LLC or the Event Organizer.  As a representative of the Event Organizer, Moon Tower Entertainment, LLC. will help investigate and settle all refund claims.  The decision is determined by the Event Organizer and is considered final.  If granted a refund, you must adhere to the Event Organizer’s instructions and deadlines.  
        </p>
        <p className="privacy_details">
         The Process Fee portion of your purchase will not be refunded.  Printed tickets are not refunded under any circumstance.  Any request for a refund must be made no later than 30 days after the events scheduled date, except as specifically allowed by the Organizer and submitted to 
         <a
                  href="mailto:info@moontowerticket.com"
                  style={{
                    color: "#ff7530",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
         info@moontowerticket.com.
                </a>
          </p>
      </div>
    </div>
  );
};
