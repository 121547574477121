import instance from "../interceptor/AxiosInstance";
import { headers } from "../utils/headers";

export const registerUser = async (email, password, fname, lname) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/signup`,
        {
            method: 'POST',
            headers: headers,
            data: JSON.stringify({
                email,
                password,
                name: `${fname} ${lname}`,
                role: "customer",
                company_name: "test",
                first_name: fname,
                last_name: lname,
            })
        }
    );
    return response;
}

export const fetchVerificationStatus = async (url) => {
    const response = await instance(`${process.env.REACT_APP_BACKEND_API}/auth/verify-account`, {
        method: 'POST',
        headers: headers,
        data: JSON.stringify({
            code: url.pathname.split("/")[2] ? url.pathname.split("/")[2] : ""
        })
    });
    return response;
}

export const guestLoginRequest = async(guestDetails) => {
    const response = await instance(`${process.env.REACT_APP_BACKEND_API}/auth/guest-login`, {
        method: 'POST',
        headers: headers,
        data: JSON.stringify({
            email: guestDetails.email,
            role: "customer",
            first_name: guestDetails.fname,
            last_name: guestDetails.lname,
        })
    });
    return response;
}

export const loginRequest = async (email, password, role=undefined) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/login`,
        {
            method: 'POST',
            headers: headers,
            data: JSON.stringify({
                email: email,
                password: password,
                role
            })
        }
    );
    return response;
}

export const changePasswordRequest = async (values) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/users/change-password`,
        {
            method: "POST",
            headers: headers,
            data: JSON.stringify({
                user_id: window.localStorage.getItem("userId"),
                password: values.cPassword,
            })
        }
    );
    return response;
}

export const newPasswordRequest = async (values) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/users/change-password`,
        {
            method: "POST",
            headers: headers,
            data: JSON.stringify({
                user_id: window.localStorage.getItem("userId"),
                password: values.cPassword,
                newPassword: values.newPassword,
            }),
        }
    );
    return response;
}

export const reportForgetPassword = async (forgetEmail) => {
    const response = await instance(
        `${process.env.REACT_APP_BACKEND_API}/auth/forgot-password`,
        {
            method: 'POST',
            headers: headers,
            data: JSON.stringify({
                email: forgetEmail
            })
        }
    );
    return response;
}

export const DeleteUserAction = async () => {
    const response = await instance(`${process.env.REACT_APP_BACKEND_API}/delete-my-account`, {
        method: 'GET',
        headers: headers
    });
    return response;
}