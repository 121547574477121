import { SPECIAL } from "../constants/Events";
import * as actionTypes from "./actions";
const initialState = {
  shows: [],
  cart_items: [],
  loading: true,
  mobileNavbar: {
    home: true,
    search: false,
    tickets: false,
    login: false,
    savedCards: false,
    profile: false
  }
};

const reducer = (state = initialState, action) => {
  let show = state.shows.find((show) => show.product_id === action.item);
  switch (action.type) {

    case actionTypes.PUSH_SHOWS:
      return {
        ...state,
        loading: true,
        shows: action.item.map(obj => {
          if (obj.category_primary === SPECIAL.seatsio) {
            if (obj.special_categories.find(stock => stock.stock_quantity > 0) !== undefined) {
              const shows = ({
                ...obj,
                purchased_tickets: [],
                seatsio_tickets: [],
                out_of_stock: false,
              })
              return shows;
            }
            else {
              return ({
                ...obj,
                purchased_tickets: [],
                seatsio_tickets: [],
                out_of_stock: true
              })
            }
          }
          else if (obj.category_primary === SPECIAL.special) {
            if (obj.special_categories.find(stock => stock.stock_quantity > 0) !== undefined) {
              const shows = ({
                ...obj,
                purchased_tickets: [],
                out_of_stock: false,
              })
              return shows;
            }
            else {
              return ({
                ...obj,
                purchased_tickets: [],
                out_of_stock: true
              })
            }
          }
          return ({
            ...obj,
            purchased_tickets: obj.ticket_qty === 0 ? 0 : obj.min_quantity,
            ticket_price_web: obj.is_sale ? obj.ticket_sale_price : obj.ticket_price_web,
            out_of_stock: obj.ticket_qty === 0,
          })
        })
      };




    case actionTypes.PUSH_SHOW_BY_ID:
      const actionShow = action.item[0];
      const eventFound = state.shows?.find((show) => show?.product_id === actionShow?.product_id);
      if(eventFound?.product_id) {
        return {
        ...state,
        shows: state.shows.map((show) => {
          if (show.product_id === actionShow.product_id) {
            if (actionShow.category_primary === SPECIAL.seatsio) {
            if (actionShow.special_categories.find(stock => stock.stock_quantity > 0) !== undefined) {
              const shows = ({
                ...actionShow,
                purchased_tickets: [],
                seatsio_tickets: [],
                out_of_stock: false,
              })
              return shows;
            }
            else {
              return ({
                ...actionShow,
                purchased_tickets: [],
                seatsio_tickets: [],
                out_of_stock: true
              })
            }
          }
          else if (actionShow.category_primary === SPECIAL.special) {
            if (actionShow.special_categories.find(stock => stock.stock_quantity > 0) !== undefined) {
              const shows = ({
                ...actionShow,
                purchased_tickets: [],
                out_of_stock: false,
              })
              return shows;
            }
            else {
              return ({
                ...actionShow,
                purchased_tickets: [],
                out_of_stock: true
              })
            }
          }
          return ({
            ...actionShow,
            purchased_tickets: actionShow.ticket_qty === 0 ? 0 : actionShow.min_quantity,
            ticket_price_web: actionShow.is_sale ? actionShow.ticket_sale_price : actionShow.ticket_price_web,
            out_of_stock: actionShow.ticket_qty === 0,
          })
          }
          else return show;
        }),
        loading: false
      };
    } else {
      const existingShows = state?.shows || [];
        const eventToInsert = action.item.map(obj => {
          if (obj.category_primary === SPECIAL.seatsio) {
            if (obj.special_categories.find(stock => stock.stock_quantity > 0) !== undefined) {
              const shows = ({
                ...obj,
                purchased_tickets: [],
                seatsio_tickets: [],
                out_of_stock: false,
              })
              return shows;
            }
            else {
              return ({
                ...obj,
                purchased_tickets: [],
                seatsio_tickets: [],
                out_of_stock: true
              })
            }
          }
          else if (obj.category_primary === SPECIAL.special) {
            if (obj.special_categories.find(stock => stock.stock_quantity > 0) !== undefined) {
              const shows = ({
                ...obj,
                purchased_tickets: [],
                out_of_stock: false,
              })
              return shows;
            }
            else {
              return ({
                ...obj,
                purchased_tickets: [],
                out_of_stock: true
              })
            }
          }
          return ({
            ...obj,
            purchased_tickets: obj.ticket_qty === 0 ? 0 : obj.min_quantity,
            ticket_price_web: obj.is_sale ? obj.ticket_sale_price : obj.ticket_price_web,
            out_of_stock: obj.ticket_qty === 0,
          })
        });
        existingShows.push(eventToInsert[0]);
        return {
          ...state,
          shows: [...existingShows],
          loading: false
        };
    }

    case actionTypes.ADD_ITEMS:
      if (show.purchased_tickets + show.quantity_step > show.max_quantity && (show.purchased_tickets + show.quantity_step) > show.available_quantity)
        return state;
      if (show.purchased_tickets + show.quantity_step <= show.max_quantity && (show.purchased_tickets + show.quantity_step) <= show.available_quantity)
        show.purchased_tickets += show.quantity_step;
      return {
        ...state,
        shows: [...state.shows],
      };

    case actionTypes.REDUCE_ITEMS:
      if (show.purchased_tickets - show.quantity_step < 0)
        return state;
      if (show.purchased_tickets - show.quantity_step >= show.min_quantity)
        show.purchased_tickets -= show.quantity_step;
      return {
        ...state,
        shows: [...state.shows],
      };

    case actionTypes.ADD_ITEMS_CATEGORY:
      const purchasedTickets = show.purchased_tickets.find(item => item.category === action.categoryId);

      if (purchasedTickets.quantity + purchasedTickets.quantity_step > purchasedTickets.max_quantity
        &&
        (purchasedTickets.quantity + purchasedTickets.quantity_step) > purchasedTickets.available_quantity)
        return state;
      if (purchasedTickets.quantity + purchasedTickets.quantity_step <= purchasedTickets.max_quantity
        && (purchasedTickets.quantity + purchasedTickets.quantity_step) <= purchasedTickets.available_quantity) {
        purchasedTickets.quantity += purchasedTickets.quantity_step;
      }
      return {
        ...state,
        shows: [...state.shows],
      };

    case actionTypes.REDUCE_ITEMS_CATEGORY:
      const purchasedTicket = show.purchased_tickets.find(item => item.category === action.categoryId);
      if (!purchasedTicket) {
        return state;
      }
      if (purchasedTicket.quantity - purchasedTicket.quantity_step <= 0) {
        let tickets = show.purchased_tickets.filter(item => item.category !== purchasedTicket.category);
        show.purchased_tickets = tickets;
        return state;
      }
      if (purchasedTicket.quantity - purchasedTicket.quantity_step >= purchasedTicket.min_quantity) {
        purchasedTicket.quantity -= purchasedTicket.quantity_step;
      }
      return {
        ...state,
        shows: [...state.shows],
      };

    case actionTypes.CHANGE_NAVLINKS:
      return {
        ...state,
        mobileNavbar: {
          home: action.payload === 'home',
          search: action.payload === 'search',
          login: action.payload === 'login',
          profile: action.payload === 'profile',
          tickets: action.payload === 'tickets',
          savedCards: action.payload === 'savedCards',
        },
      };

    case actionTypes.CHANGE_CATEGORY:
      let lastPurchased = show.purchased_tickets;
      const exists = show.purchased_tickets.find(spc => spc.category === action.spcId);
      if (exists)
        return state;
      const obj = show.special_categories.find(spc => spc.id === action.spcId);
      show.purchased_tickets = [...lastPurchased,
      {
        quantity: obj.min_quantity,
        name: obj.name,
        ticket_price_web: obj.regular_price,
        max_quantity: obj.max_quantity,
        min_quantity: obj.min_quantity,
        quantity_step: obj.quantity_step,
        category: obj.id,
        available_quantity: obj.stock_quantity
      }]
      return {
        ...state,
        shows: [...state.shows]
      }
    case actionTypes.RESET_SEATSIO_TICKETS:
      show.purchased_tickets = [];
      return {
        ...state,
        shows: [...state.shows]
      }
    case actionTypes.CHANGE_CATEGORY_SEATSIO:
      let previousTickets = show.purchased_tickets;
      const objTickets = show.special_categories.find(spc => Number(spc?.category_key) === action.spcId);
      show.purchased_tickets = [...previousTickets,
      {
        quantity: 1,
        label: [],
        name: objTickets.name, //category.label
        ticket_price_web: objTickets.regular_price, //category.pricing.price
        max_quantity: objTickets.max_quantity,
        min_quantity: objTickets.min_quantity,
        quantity_step: objTickets.quantity_step,
        seatId: action.seatId, //seatId
        category: Number(objTickets.category_key), //category.key
        available_quantity: objTickets.stock_quantity
      }]
      return {
        ...state,
        shows: [...state.shows]
      }
    case actionTypes.DESELECT_CATEGORY_SEATSIO:
      const { purchased_tickets } = show;
      const indexToRemove = purchased_tickets.findIndex(ticket => ticket.seatId === action.seatId);
      const remainingTickets = [
        ...purchased_tickets.slice(0, indexToRemove),
        ...purchased_tickets.slice(indexToRemove + 1)
      ];
      return {
        ...state,
        shows: state.shows.map(showItem => {
          if (showItem.id === show.id) {
            return {
              ...showItem,
              purchased_tickets: remainingTickets
            };
          }
          return showItem;
        })
      };
    case actionTypes.INSERT_SEATSIO_OBJECT:
      show.seatsio_tickets = [...action.seatObj];
      return {
        ...state,
        shows: [...state.shows]
      }
    default:
      return state;
  }
};
export default reducer;
